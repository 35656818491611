import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'view/components/Button';
import Input from 'view/components/Form/Input';
import Box from '@mui/material/Box';
import FormHeader from 'view/components/Form/Header';
import { User } from 'types/users';
import { StyledFormContainer, StyledFormActions } from '../../styled';
import useUser from 'hooks/user';
import { ChangeContentCreatorNameValidationSchema } from 'constraints';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/auth';
import { updateUserData } from 'services/api/user';
import Switch from '@mui/material/Switch';
import { FormControl, FormGroup } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';

type ChangeMessageCensorshipProps = {
  onCancel: () => void;
};

export default function ChangeMessageCensorship({
  onCancel,
}: ChangeMessageCensorshipProps) {
  const { setErrorMessage, setSuccessMessage } = useAuth();
  const { user, setUser } = useUser();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const value = event.target.checked;

      setLoading(true);
      await updateUserData(user._id, { messageCensorship: value });
      setUser({ ...user, messageCensorship: value });
      setSuccessMessage(t('Successfully updated message censorship settings'));
    } catch (err) {
      setErrorMessage(t('Error while updating message censorship settings'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledFormContainer>
      <FormHeader
        title={t('Message censorship')}
        description={t('Turn message censorship on or off')}
      />
      <FormControl component="fieldset" variant="standard">
        <FormGroup>
          <FormControlLabel
            sx={{ justifyContent: 'start', margin: 0, gap: 1 }}
            control={
              <Switch
                checked={!!user?.messageCensorship}
                onChange={handleChange}
                name="gilad"
                disabled={loading}
              />
            }
            labelPlacement="start"
            label={t('Activate')}
          />
        </FormGroup>
      </FormControl>

      <StyledFormActions>
        <Button
          label={t('Back')}
          variant="text"
          color="secondary"
          onClick={onCancel}
        />
      </StyledFormActions>
    </StyledFormContainer>
  );
}
