import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import { nivoTheme } from 'view/theme/nivo';
import { primary } from 'view/theme/colors';
import Typography from '@mui/material/Typography';
import { formatUAHCurrency } from 'tools';
import useTheme from 'hooks/theme';
import { alpha } from '@mui/material/styles';
import { useDateFormatter } from 'hooks/dateFormatter';
import { useTranslation } from 'react-i18next';

type LineChartProps = {
  data: Array<{
    id: string;
    color: string;
    data: Array<{ x: string | number; y: number }>;
  }>;
};

export default function LineChart({ data }: LineChartProps) {
  const { isDarkMode } = useTheme();
  const { t } = useTranslation();
  const formatDate = useDateFormatter();

  return (
    <ResponsiveLine
      data={data}
      theme={nivoTheme(isDarkMode)}
      margin={{ top: 50, right: 50, bottom: 50, left: 70 }}
      xScale={{ type: 'point' }}
      curve="monotoneX"
      yScale={{
        type: 'linear',
        min: 0,
        max: 'auto',
        // stacked: true,
        // reverse: false,
      }}
      colors={[primary]}
      yFormat=" >-.2f"
      enableArea={true}
      areaBaselineValue={0}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: t('Date'),
        legendOffset: 36,
        legendPosition: 'middle',
        format: (value: string) => formatDate(new Date(value), 'dd MMM'),
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: t('Amount'),
        legendOffset: -60,
        legendPosition: 'middle',
      }}
      sliceTooltip={(data) => {
        const [dataItem] = data.slice.points;
        return (
          <>
            <Typography variant="body1">
              {formatUAHCurrency(parseInt(dataItem.data.y.toString()))}
            </Typography>
            <Typography variant="body2">
              {formatDate(new Date(dataItem.data.x), 'dd MMMM')}
            </Typography>
          </>
        );
      }}
      defs={[
        {
          id: 'gradientA',
          type: 'linearGradient',
          colors: [
            { offset: 0, color: primary },
            { offset: 100, color: alpha(primary, 0.5) },
          ],
        },
      ]}
      fill={[{ match: '*', id: 'gradientA' }]}
      lineWidth={2}
      pointSize={10}
      pointColor={{ from: 'color' }}
      enableCrosshair={true}
      enableTouchCrosshair={true}
      crosshairType="x"
      enableSlices="x"
    />
  );
}
