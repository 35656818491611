import { Currency } from 'services/api/types';

export type Goal = {
  _id: string;
  start: number;
  finish: number;
  accumulated: number;
  label: string;
  currency: Currency;
  resetPeriod: GoalResetPeriods;
  active: boolean;
  createdAt: Date;
  contentCreatorId: string;
};

export enum GoalResetPeriods {
  NEVER = 'never',
  DAILY = 'daily',
}
