import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from 'view/components/Button';
import Input from 'view/components/Input';
import FormHeader from 'view/components/Form/Header';
import { StyledFormContainer, StyledFormActions } from '../../styled';
import { LEADER_BOARD_PATH } from 'view/routes';
import useUser from 'hooks/user';
import { useTranslation } from 'react-i18next';
import CopyButton from 'view/components/CopyButton';
import IconButton from '@mui/material/IconButton';
import EyeOffIcon from 'view/components/icons/EyeOff';
import EyeIcon from 'view/components/icons/Eye';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import AddTransferredDonationDialog from 'view/pages/Widgets/components/ViewLeaderBoardURL/components/AddTransferredDonationDialog';
import RemoveTransferredDonationDialog from 'view/pages/Widgets/components/ViewLeaderBoardURL/components/RemoveTransferredDonationDialog';

type ViewOBSURLProps = {
  onCancel: () => void;
};

export default function ViewLeaderBoardURL({ onCancel }: ViewOBSURLProps) {
  const { OBSUrl } = useUser();
  const { t } = useTranslation();
  const url = `${process.env.REACT_APP_UI_URL}/${OBSUrl}${LEADER_BOARD_PATH}`;

  const [showPassword, setShowPassword] = useState(false);
  const [isAddSponsorOpen, setIsAddSponsorOpen] = useState(false);
  const [isRemoveSponsorOpen, setIsRemoveSponsorOpen] = useState(false);

  return (
    <>
      {isAddSponsorOpen && (
        <AddTransferredDonationDialog
          onClose={() => setIsAddSponsorOpen(false)}
          open={isAddSponsorOpen}
        />
      )}
      {isRemoveSponsorOpen && (
        <RemoveTransferredDonationDialog
          onClose={() => setIsRemoveSponsorOpen(false)}
          open={isRemoveSponsorOpen}
        />
      )}
      <StyledFormContainer>
        <FormHeader
          title={t('Leader board')}
          description={t('Add this link to your OBS browser source')}
        />
        <Box display="flex">
          <Input
            sx={{ mr: 2 }}
            fullWidth
            label={t('Keep it secret and never share with anyone')}
            helperText={t(
              'OBS settings for the browser source: width 1920px, height 1080px',
            )}
            endAdornment={
              <IconButton
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <EyeOffIcon /> : <EyeIcon />}
              </IconButton>
            }
            id="OBSurl"
            type={showPassword ? 'text' : 'password'}
            disabled
            value={url}
          />
          <CopyButton edge="end" value={url} />
        </Box>

        <StyledFormActions>
          <Button
            startIcon={<AddRoundedIcon />}
            onClick={() => setIsAddSponsorOpen(true)}
            label={t('Add')}
          />
          <Button
            startIcon={<RemoveRoundedIcon />}
            onClick={() => setIsRemoveSponsorOpen(true)}
            variant="text"
            color="error"
            label={t('Remove')}
          />
          <Button
            label={t('Back')}
            variant="text"
            color="secondary"
            onClick={onCancel}
          />
        </StyledFormActions>
      </StyledFormContainer>
    </>
  );
}
