import Box from '@mui/material/Box';
import { primary } from 'view/theme/colors';
import Typography from '@mui/material/Typography';
import React from 'react';

type SummaryItemProps = {
  value: string | number;
  description: string;
};

export default function SummaryItem({ value, description }: SummaryItemProps) {
  return (
    <Box p={2} sx={{ border: `1px solid ${primary}`, borderRadius: 4 }}>
      <Typography variant="h2">{value}</Typography>
      <Typography variant="body2">{description}</Typography>
    </Box>
  );
}
