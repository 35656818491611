import React from 'react';
import Box from '@mui/material/Box';
import Button from 'view/components/Button';
import Input from 'view/components/Input';
import FormHeader from 'view/components/Form/Header';
import { StyledFormContainer, StyledFormActions } from '../../styled';
import { DONATE_PATH } from 'view/routes';
import useUser from 'hooks/user';
import { useTranslation } from 'react-i18next';
import CopyButton from 'view/components/CopyButton';

type ViewPersonalPageURLProps = {
  onCancel: () => void;
};

export default function ViewPersonalPageURL({
  onCancel,
}: ViewPersonalPageURLProps) {
  const { user } = useUser();
  const { t } = useTranslation();
  const link = `${process.env.REACT_APP_UI_URL}${DONATE_PATH}/${user.contentCreatorName}`;

  return (
    <StyledFormContainer>
      <FormHeader
        title={t('My page')}
        description={t(
          'A page for raising donations. Add this link to your stream description',
        )}
      />
      <Box display="flex">
        <Input
          sx={{ mr: 2 }}
          id="personalPage"
          type="url"
          disabled
          value={link}
        />
        <CopyButton value={link} edge="end" />
      </Box>

      <StyledFormActions>
        <Button
          label={t('Back')}
          variant="text"
          color="secondary"
          onClick={onCancel}
        />
      </StyledFormActions>
    </StyledFormContainer>
  );
}
