import styled from '@emotion/styled';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

export const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 0.5rem;
  }
`;

export const StyledDialogContent = styled(DialogContent)`
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  white-space: break-spaces;
`;
