import { Donation } from 'types/donations';
import { Currency } from 'services/api/types';
import { PaymentMethodTypes } from 'types/users';
import { GoalResetPeriods } from 'types/goals';

export const ANIMATION_OPTIONS: OPTION_SELECT[] = [
  { value: 'none', label: 'None' },
  { value: 'opacity', label: 'Opacity' },
  { value: 'scale', label: 'Scale' },
];

export type OPTION_SELECT = {
  value: string;
  label: string;
};

// type AnimationPreset = {
//   opacity?: number;
//   rotate?: number[];
//   scale?: number;
//   transition?: {
//     duration: number;
//     repeat: number;
//     ease: string;
//   };
// };

// type AnimationType = 'none' | 'rotation' | 'opacity' | 'scale';

export const ENTRY_ANIMATION_PRESETS: Record<string, any> = {
  opacity: { opacity: 0 },
  scale: { scale: 0 },
};

export const MAIN_ANIMATION_PRESETS: Record<string, any> = {
  opacity: 1,
  scale: 1,
};

export const LANGUAGE_OPTIONS: OPTION_SELECT[] = [
  { value: 'uk', label: 'UK' },
  { value: 'en', label: 'EN' },
];

export const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

export const MAX_AMOUNT: { [key in Currency]: number } = {
  [Currency.UAH]: 14900,
  [Currency.USD]: 500,
  [Currency.EUR]: 500,
  [Currency.USDT]: 500,
};

export const BAD_WORDS = [
  'fuck',
  'shit',
  'ass',
  'bitch',
  'bastard',
  'damn',
  'cunt',
  'cock',
  'dick',
  'pussy',
  'whore',
  'slut',
  'faggot',
  'nigger',
  'motherfucker',
  'bullshit',
  'prick',
  'wanker',
  'twat',
  'arsehole',
  'fucker',
  'jerk',
  'douche',
  'spic',
  'retard',
  'coon',
  'chink',
  'gook',
  'kike',
  'хуй',
  'пизда',
  'блядь',
  'хує',
  'хуяк',
  'єбать',
  'єблан',
  'хуесос',
  'курва',
  'довбойоб',
  'гандон',
  'манда',
  'мудак',
  'підар',
  'сука',
  'ублюдок',
  'залупа',
  'шлюха',
  'педераст',
  'йобаний',
  'сцикло',
  'сцикун',
  'дебіл',
  'виродок',
  'козел',
  'тварь',
  'урод',
  'пиздець',
  'нахуй',
  'підор',
  'педріла',
  'педик',
  'нігер',
  'негр',
  'хохол',
  'хач',
  'жид',
  'інцел',
  'симп',
  'незайманий',
];

export const AGREEMENT_HREF =
  'https://firebasestorage.googleapis.com/v0/b/donate-2fd1d.appspot.com/o/%D0%94%D0%9E%D0%93%D0%9E%D0%92%D0%86%D0%A0%202DONATE.docx?alt=media&token=699f286e-c629-498d-8a7a-a1a856d2c4cc';

export const DEFAULT_PICTURE_URL =
  'https://firebasestorage.googleapis.com/v0/b/donate-2fd1d.appspot.com/o/defaultDonationPicture.gif?alt=media&token=8186f0dc-58c4-46a3-8335-98662b8b794e';
export const DEFAULT_SOUND_URL =
  'https://firebasestorage.googleapis.com/v0/b/donate-2fd1d.appspot.com/o/defaultDonationSound.mp3?alt=media&token=16d4850f-c9eb-4016-8be8-b991a4e0b60d';

export const DEFAULT_TTS_EXAMPLE_URL =
  'https://firebasestorage.googleapis.com/v0/b/donate-2fd1d.appspot.com/o/defaultTTSexample.mp3?alt=media&token=de008c63-a139-4e2b-9ab6-e9c473df21fc';
export const ANTON_TTS_EXAMPLE_URL =
  'https://firebasestorage.googleapis.com/v0/b/donate-2fd1d.appspot.com/o/AntonTTSexample.mp3?alt=media&token=3a04c0a4-f7d2-4ebd-a037-2a816cda9f88';
export const DMYTRO_TTS_EXAMPLE_URL =
  'https://firebasestorage.googleapis.com/v0/b/donate-2fd1d.appspot.com/o/DmytroTTSexample.mp3?alt=media&token=9675260f-d697-4236-9212-d1798cac9ab7';
export const TYLER_TTS_EXAMPLE_URL =
  'https://firebasestorage.googleapis.com/v0/b/donate-2fd1d.appspot.com/o/TylerTTSexample.mp3?alt=media&token=295b4f49-71e6-4c66-bc88-122a483483c3';
export const ALEX_TTS_EXAMPLE_URL =
  'https://firebasestorage.googleapis.com/v0/b/donate-2fd1d.appspot.com/o/AlexTTSexample.mp3?alt=media&token=17089ff5-f1a2-417c-8169-60cf545f1821';
export const VIRA_TTS_EXAMPLE_URL =
  'https://firebasestorage.googleapis.com/v0/b/donate-2fd1d.appspot.com/o/ViraTTSexample.mp3?alt=media&token=6d78c597-d8da-49a8-ab33-6fe0499c1f54';
export const OKSANA_TTS_EXAMPLE_URL =
  'https://firebasestorage.googleapis.com/v0/b/donate-2fd1d.appspot.com/o/OksanaTTSexample.mp3?alt=media&token=65bdf158-c75d-4c48-9e51-380444377ca8';
export const CHARLOTTE_TTS_EXAMPLE_URL =
  'https://firebasestorage.googleapis.com/v0/b/donate-2fd1d.appspot.com/o/CharlotteTTSexample.mp3?alt=media&token=bb741f82-8009-4c44-9d76-9e9bbe94dcf0';
export const FIN_TTS_EXAMPLE_URL =
  'https://firebasestorage.googleapis.com/v0/b/donate-2fd1d.appspot.com/o/FinTTSexample.mp3?alt=media&token=d19443fa-9ba1-45e1-ae62-5ac289dc7edf';
export const NICOLE_TTS_EXAMPLE_URL =
  'https://firebasestorage.googleapis.com/v0/b/donate-2fd1d.appspot.com/o/NicoleTTSexample.mp3?alt=media&token=7edfe65f-c49b-4991-9796-3ce256298f74';
export const THOMAS_TTS_EXAMPLE_URL =
  'https://firebasestorage.googleapis.com/v0/b/donate-2fd1d.appspot.com/o/ThomasTTSexample.mp3?alt=media&token=da977add-92a5-42d7-8010-7851c502f6f7';
export const PATRICK_TTS_EXAMPLE_URL =
  'https://firebasestorage.googleapis.com/v0/b/donate-2fd1d.appspot.com/o/PatrickTTSexample.mp3?alt=media&token=c46489b5-57ae-431f-81f8-e5f701d95ee6';

export type VoiceConfig = {
  label: string;
  exampleUrl: string;
  description: string;
  price: { [key in Currency]: number };
};
export const DEFAULT_VOICE_NAME = 'default';
export const VOICE_PRICE = {
  [Currency.UAH]: 9,
  [Currency.USD]: 0.25,
  [Currency.EUR]: 0.23,
  [Currency.USDT]: 0.25,
};
export const VOICES_CONFIG: { [key: string]: VoiceConfig } = {
  [DEFAULT_VOICE_NAME]: {
    price: {
      [Currency.UAH]: 0,
      [Currency.USD]: 0,
      [Currency.EUR]: 0,
      [Currency.USDT]: 0,
    },
    label: 'Default',
    description: '',
    exampleUrl: DEFAULT_TTS_EXAMPLE_URL,
  },
  Png1rsLPwah87cs3JX7l: {
    price: VOICE_PRICE,
    label: 'Tyler',
    description: 'The Tyler Durden',
    exampleUrl: TYLER_TTS_EXAMPLE_URL,
  },
  piTKgcLEGmPE4e6mEKli: {
    price: VOICE_PRICE,
    label: 'Nicole',
    description: 'ASMR',
    exampleUrl: NICOLE_TTS_EXAMPLE_URL,
  },
  D38z5RcWu1voky8WS1ja: {
    price: VOICE_PRICE,
    label: 'Fin',
    description: 'Fairy tale reader',
    exampleUrl: FIN_TTS_EXAMPLE_URL,
  },
  XB0fDUnXU5powFXDhCwa: {
    price: VOICE_PRICE,
    label: 'Charlotte',
    description: 'Mysterious',
    exampleUrl: CHARLOTTE_TTS_EXAMPLE_URL,
  },
  GBv7mTt0atIp3Br8iCZE: {
    price: VOICE_PRICE,
    label: 'Thomas',
    description: 'Male meditative',
    exampleUrl: THOMAS_TTS_EXAMPLE_URL,
  },
  ymDCYd8puC7gYjxIamPt: {
    price: VOICE_PRICE,
    label: 'Oksana',
    description: 'School teacher',
    exampleUrl: OKSANA_TTS_EXAMPLE_URL,
  },
  ODq5zmih8GrVes37Dizd: {
    price: VOICE_PRICE,
    label: 'Patrick',
    description: 'Shouty man',
    exampleUrl: PATRICK_TTS_EXAMPLE_URL,
  },
  nCqaTnIbLdME87OuQaZY: {
    price: VOICE_PRICE,
    label: 'Vira',
    description: 'Little girl',
    exampleUrl: VIRA_TTS_EXAMPLE_URL,
  },
  '9Sj8ugvpK1DmcAXyvi3a': {
    price: VOICE_PRICE,
    label: 'Alex',
    description: 'Deep male voice',
    exampleUrl: ALEX_TTS_EXAMPLE_URL,
  },
  NJGiMgVHtXSA1XYSuNKl: {
    price: VOICE_PRICE,
    label: 'Dmytro',
    description: 'With rhotacism',
    exampleUrl: DMYTRO_TTS_EXAMPLE_URL,
  },
};

export const GOAL_RESET_OPTIONS: OPTION_SELECT[] = [
  { value: GoalResetPeriods.NEVER, label: 'Never' },
  { value: GoalResetPeriods.DAILY, label: 'Daily' },
];

export const EXAMPLE_DONATION: Donation = {
  _id: '123',
  createdAt: new Date(),
  sponsorName: `Ім'я спонсора`,
  amount: 100,
  currency: Currency.UAH,
  paymentMethod: PaymentMethodTypes.WAYFORPAY,
  isPaid: true,
  voice: DEFAULT_VOICE_NAME,
  message:
    'Цей текст згенеровано для тестування системи перетворення тексту в мовлення.',
};
