import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Input from 'view/components/Form/Input';
import Button from 'view/components/Button';
import RedeemIcon from '@mui/icons-material/Redeem';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import { DonateValidationSchema } from 'constraints';
import { StyledFormContainer, StyledPageContainer } from './styled';
import TextArea from 'view/components/TextArea';
import {
  getExchangeRates,
  postMonobankaPaymentIntent,
  postWayforpayPaymentIntent,
} from 'services/api/donations';
import { TERMS_PATH } from 'view/routes';
import Link from 'view/components/Link';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/auth';
import { getDonationConfig } from 'services/api/user';
import {
  ThresholdRecord,
  PaymentMethodTypes,
  User,
  MinimumDonationAmount,
} from 'types/users';
import FormControlLabel from '@mui/material/FormControlLabel';
import AmountBox from 'view/pages/Donate/components/AmountBox';
import PaymentBox from 'view/pages/Donate/components/PaymentBox';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import {
  createHiddenInput,
  extractYouTubeVideoId,
  formatCurrency,
  getAmount,
  getIsVoiceOverAllowed,
  handleDonationThresholdConfig,
  isCurrentThresholdBox,
} from 'tools';
import {
  Currency,
  CurrencyToPaymentMethodMap,
  ExchangeRate,
  WayforpayPaymentIntentResponse,
} from 'services/api/types';
import Avatar from 'view/components/Avatar';
import { SOCIAL_NETWORKS_MEDIA_MAP } from 'view/pages/AccountSettings/components/ChangeSocialNetworks';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid2';
import { DEFAULT_VOICE_NAME, VOICES_CONFIG } from 'view/constants';
import AudioPreview from 'view/components/AudioPreview';
import { green, primary, youtube } from 'view/theme/colors';
import Tooltip from '@mui/material/Tooltip';
import Loader from 'view/components/Loader';
import NotFoundPage from 'view/pages/NotFound';
import Accordion from 'view/pages/AdminUsers/components/Accordion';
import BackgroundAnimation from 'view/components/BackgroundAnimation';
import AddMediaDialog from 'view/pages/Donate/components/AddMediaDialog';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';

type DonateFormData = {
  message: string;
  sponsorName: string;
  amount: number;
  paymentMethod: PaymentMethodTypes | null;
  voice: string;
  currency: Currency | null;
  goalId: string;
  youtubeUrl: string;
};

export type DonatePageDonationConfig = Pick<
  User,
  | 'contentCreatorName'
  | 'description'
  | 'minimumDonationAmount'
  | 'minimumVoiceOverThreshold'
  | 'profilePictureUrl'
  | 'donationThresholdConfig'
  | 'socialNetworks'
  | 'goals'
  | 'isPremium'
  | 'allowMedia'
> & {
  paymentMethods: PaymentMethodTypes[];
};

export default function DonatePage() {
  const { setErrorMessage } = useAuth();
  const { t } = useTranslation();
  const { contentCreatorName = '' } = useParams();

  const [loading, setLoading] = useState(true);
  const [isTermsAccepted, setIsTermsAccepted] = useState<boolean>(true);
  const [isGoalActive, setIsGoalActive] = useState<boolean>(true);

  const [isAddMediaDialogOpen, setIsAddMediaDialogOpen] = useState(false);
  const [exchangeRates, setExchangeRates] = useState<ExchangeRate | null>(null);
  const [donationConfig, setDonationConfig] =
    useState<DonatePageDonationConfig | null>(null);

  const initialFormData = {
    resolver: yupResolver(
      DonateValidationSchema(
        t,
        donationConfig?.minimumDonationAmount as MinimumDonationAmount,
      ),
    ),
    defaultValues: {
      message: '',
      sponsorName: '',
      amount: 0,
      paymentMethod: null,
      voice: DEFAULT_VOICE_NAME,
      currency: null,
      goalId: '',
    },
  };

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<DonateFormData>(initialFormData);
  // const [key, setStore] = useShadow('key', 'value');
  const amount = watch('amount');
  const paymentMethod = watch('paymentMethod');
  const voice = watch('voice');
  const youtubeUrl = watch('youtubeUrl');
  const currency = watch('currency');

  const isVoiceOverAllowed = getIsVoiceOverAllowed(
    amount,
    donationConfig?.minimumVoiceOverThreshold,
    currency,
    exchangeRates,
  );

  const voiceHelperText = () => {
    if (
      !donationConfig?.minimumVoiceOverThreshold ||
      !currency ||
      !exchangeRates
    ) {
      return '';
    }
    if (donationConfig?.minimumVoiceOverThreshold === -1) {
      return t('The user disabled donations voice-over');
    }
    if (donationConfig?.minimumVoiceOverThreshold === 0) {
      return '';
    }

    const amountWithCurrency = getAmount(
      donationConfig.minimumVoiceOverThreshold,
      currency,
      exchangeRates,
    );
    if (!amountWithCurrency) {
      return '';
    }
    return t('The minimum size of a donation with voice over {{threshold}}', {
      threshold: formatCurrency(amountWithCurrency, currency),
    });
  };

  const donationThresholdConfig = handleDonationThresholdConfig(
    donationConfig?.donationThresholdConfig as ThresholdRecord[],
    currency,
    exchangeRates as ExchangeRate,
  );

  useEffect(() => {
    const fetchDonationConfig = async () => {
      if (contentCreatorName) {
        try {
          const config = await getDonationConfig(contentCreatorName);
          const rates = await getExchangeRates();
          const method = config.paymentMethods?.at(0);
          setDonationConfig(config);
          setExchangeRates(rates);

          if (method) {
            const [firstCurrency] = CurrencyToPaymentMethodMap[method];

            setValue(
              'amount',
              config.minimumDonationAmount[firstCurrency] as number,
            );
            setValue('paymentMethod', method);
            setValue('currency', firstCurrency);
          }

          if (config.goals.length) {
            const [firstGoal] = config.goals;
            setValue('goalId', firstGoal._id);
          }
        } catch (err) {
          setErrorMessage(t('Error while loading user data'));
        } finally {
          setLoading(false);
        }
      }
    };

    fetchDonationConfig();
  }, []);

  const onSubmit = async (formData: DonateFormData) => {
    setErrorMessage('');

    switch (formData.paymentMethod) {
      case PaymentMethodTypes.WAYFORPAY:
        return processWayforpayPayment(formData);
      case PaymentMethodTypes.MONOBANKA:
        return processMonobankaPayment(formData);
    }
  };

  const processMonobankaPayment = async (formData: DonateFormData) => {
    const {
      message,
      amount,
      sponsorName,
      voice,
      currency,
      paymentMethod,
      goalId,
      youtubeUrl,
    } = formData;

    if (!currency || !paymentMethod) {
      return setErrorMessage(t('Error while donation'));
    }

    const youtubeId = extractYouTubeVideoId(youtubeUrl);
    const data = {
      message,
      sponsorName,
      contentCreatorName,
      amount,
      paymentMethod,
      voice,
      currency,
      goalId,
      youtubeId,
    };

    try {
      const { paymentUrl } = await postMonobankaPaymentIntent(data);

      window.open(paymentUrl, '_self', 'noopener,noreferrer');
    } catch (err) {
      setErrorMessage(t('Error while donation'));
    }
  };

  const processWayforpayPayment = async (formData: DonateFormData) => {
    const {
      message,
      amount,
      sponsorName,
      voice,
      currency,
      paymentMethod,
      goalId,
      youtubeUrl,
    } = formData;
    if (!currency || !paymentMethod) {
      return setErrorMessage(t('Error while donation'));
    }

    const voicePrice = VOICES_CONFIG[voice].price[currency];
    const youtubeId = extractYouTubeVideoId(youtubeUrl);

    const data = {
      message,
      sponsorName,
      contentCreatorName,
      amount,
      paymentMethod,
      voice,
      currency,
      goalId,
      youtubeId,
      productName: [
        'Інформаційні послуги',
        ...(voicePrice ? ['Преміум озвучка'] : []),
      ],
      productCount: [1, ...(voicePrice ? [1] : [])],
      productPrice: [amount, ...(voicePrice ? [voicePrice] : [])],
    };

    try {
      const response = await postWayforpayPaymentIntent(data);
      const form = document.createElement('form');
      form.method = 'POST';
      form.action = 'https://secure.wayforpay.com/pay';

      const keysMap = Object.keys(response) as Array<
        keyof WayforpayPaymentIntentResponse
      >;

      keysMap.forEach((name) => {
        const value = response[name];

        if (!value) {
          return;
        }

        if (Array.isArray(value)) {
          value.forEach((item) => {
            const input = createHiddenInput(`${name}[]`, item);
            form.appendChild(input);
          });
        } else {
          const input = createHiddenInput(name, value);
          form.appendChild(input);
        }
      });
      document.body.appendChild(form);
      form.submit();
    } catch (err) {
      setErrorMessage(t('Error while donation'));
    }
  };

  const handlePaymentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const method = event.target.value as PaymentMethodTypes;
    const [firstCurrency] = CurrencyToPaymentMethodMap[method];
    setValue('paymentMethod', method);
    const minimumAmount = donationConfig?.minimumDonationAmount[
      firstCurrency
    ] as number;
    setValue('amount', minimumAmount < amount ? amount : minimumAmount);
    setValue('currency', firstCurrency);
  };

  const handleTermsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsTermsAccepted(event.target.checked);
  };

  const handleGoalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (donationConfig?.goals?.length && event.target.checked) {
      const [firstGoal] = donationConfig.goals;
      setValue('goalId', firstGoal._id);
    } else {
      setValue('goalId', '');
    }

    setIsGoalActive(event.target.checked);
  };

  const noUserData = !donationConfig && !loading;

  if (loading) {
    return <Loader height="100vh" />;
  }

  if (noUserData) {
    return (
      <StyledPageContainer mt={{ xs: 8, md: 10 }}>
        <StyledFormContainer>
          <NotFoundPage />
        </StyledFormContainer>
      </StyledPageContainer>
    );
  }

  return (
    <>
      <Helmet>
        <title>{`${donationConfig?.contentCreatorName}`} - 2Donate</title>
      </Helmet>
      <StyledPageContainer mt={{ xs: 8, md: 10 }}>
        <StyledFormContainer>
          <BackgroundAnimation x={200} y={500} />
          {donationConfig?.socialNetworks.length ? (
            <Box display="flex" flexDirection="row-reverse" mb={1}>
              {donationConfig?.socialNetworks.map(({ value, url }) => {
                return (
                  <IconButton
                    size="small"
                    key={value}
                    sx={{ marginRight: 0.5 }}
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {SOCIAL_NETWORKS_MEDIA_MAP[value].icon}
                  </IconButton>
                );
              })}
            </Box>
          ) : null}

          <Box display="flex" alignItems="center" mb={3}>
            <Box mr={2}>
              <Avatar
                size={4}
                src={donationConfig?.profilePictureUrl}
                contentCreatorName={
                  noUserData
                    ? '?'
                    : (donationConfig?.contentCreatorName as string)
                }
              />
            </Box>
            <Box>
              <Box display="flex" gap={1} alignItems="center">
                <Typography variant="h2" fontSize={36} fontWeight={900}>
                  {donationConfig?.contentCreatorName}
                </Typography>
                {donationConfig?.isPremium && (
                  <Tooltip title={t('Verified')} arrow>
                    <VerifiedRoundedIcon color="info" />
                  </Tooltip>
                )}
              </Box>

              <Typography variant="body2">
                {donationConfig?.description ||
                  t('The world will see your message')}
              </Typography>
            </Box>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Input
              name="sponsorName"
              label={t('Your name')}
              type="text"
              disabled={isSubmitting || noUserData}
              error={!!errors.sponsorName?.message}
              helperText={errors.sponsorName?.message}
              control={control}
              mb={2}
            />

            <Box mb={3}>
              <TextArea
                name="message"
                label={t('Your message')}
                type="text"
                control={control}
                rows={2}
                disabled={isSubmitting || noUserData}
                error={!!errors.message?.message}
                helperText={errors.message?.message}
              />
            </Box>
            <Grid container spacing={2}>
              <Grid size={5}>
                <Input
                  name="amount"
                  label={t('How much do you wish to donate?')}
                  error={!!errors.amount?.message}
                  type="number"
                  helperText={errors.amount?.message}
                  disabled={!donationConfig || isSubmitting}
                  control={control}
                  sx={{
                    paddingRight: 0,
                    '.MuiInputBase-root': {
                      paddingRight: 0,
                    },
                    '.MuiNativeSelect-select': {
                      textAlign: 'right',
                      width: '2.5rem',
                      color: primary,
                    },
                  }}
                  endAdornment={
                    <Input
                      select
                      name="currency"
                      type="text"
                      disabled={!currency}
                      fullWidth
                      control={control}
                      SelectProps={{
                        native: true,
                      }}
                      sx={{
                        '.MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                          color: primary,
                        },
                      }}
                    >
                      {paymentMethod ? (
                        CurrencyToPaymentMethodMap[paymentMethod].map(
                          (option) => {
                            return (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            );
                          },
                        )
                      ) : (
                        <option>?</option>
                      )}
                    </Input>
                  }
                  startAdornment={
                    <IconButton
                      onClick={() => setValue('amount', amount + 5)}
                      edge="start"
                    >
                      <AddRoundedIcon />
                    </IconButton>
                  }
                />
              </Grid>
              <Grid size={7}>
                <Input
                  select
                  name="voice"
                  helperText={voiceHelperText()}
                  label={t('Voice')}
                  type="text"
                  disabled={!currency || !isVoiceOverAllowed}
                  fullWidth
                  startAdornment={
                    <AudioPreview
                      soundUrl={VOICES_CONFIG[voice].exampleUrl}
                      disabled={!currency || !isVoiceOverAllowed}
                      title="Voice-over example"
                    />
                  }
                  control={control}
                  SelectProps={{
                    native: true,
                  }}
                >
                  {currency ? (
                    Object.keys(VOICES_CONFIG).map((option) => {
                      const { description, label, price } =
                        VOICES_CONFIG[option];
                      return (
                        <option
                          key={option}
                          value={option}
                          disabled={
                            !donationConfig?.isPremium &&
                            option !== DEFAULT_VOICE_NAME
                          }
                        >
                          {t(label)}
                          {description ? ` - ${t(description)}` : ''} (+
                          {formatCurrency(price[currency], currency)})
                        </option>
                      );
                    })
                  ) : (
                    <option>{t('No data')}</option>
                  )}
                </Input>
              </Grid>
            </Grid>
            {donationConfig?.donationThresholdConfig.length && exchangeRates ? (
              <Accordion
                defaultExpanded={true}
                disableGutters={true}
                square={true}
                summary={
                  <Typography variant="body2">
                    {t('Animation and notification sound')}
                  </Typography>
                }
                children={
                  <Box display="inline-flex" flexWrap="wrap" gap={1} mb={2}>
                    {donationThresholdConfig
                      ?.sort((a, b) => a.threshold - b.threshold)
                      .map((thresholdConfig) => {
                        const uahAmount =
                          currency === Currency.UAH
                            ? amount
                            : exchangeRates[currency as Currency] * amount;
                        const isCurrent = isCurrentThresholdBox(
                          uahAmount,
                          thresholdConfig.threshold,
                          donationThresholdConfig,
                        );
                        const currencyThreshold = getAmount(
                          thresholdConfig.threshold,
                          currency as Currency,
                          exchangeRates,
                        );
                        return (
                          <AmountBox
                            key={thresholdConfig.threshold}
                            amount={currencyThreshold}
                            isCurrent={isCurrent}
                            pictureUrl={thresholdConfig.pictureUrl}
                            soundUrl={thresholdConfig.soundUrl}
                            onClick={() =>
                              setValue('amount', currencyThreshold)
                            }
                          />
                        );
                      })}
                  </Box>
                }
              />
            ) : null}
            {donationConfig?.goals?.length ? (
              <>
                <Typography variant="body2" mb={1}>
                  {t('Select the collection goal')}
                </Typography>
                <Grid container spacing={1} mb={2}>
                  <Grid display="flex" alignItems="center" size={12}>
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isGoalActive}
                            disabled={isSubmitting}
                            onChange={handleGoalChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        }
                        label=""
                      />
                    </Box>
                    <Box width="100%">
                      <Input
                        select
                        name="goalId"
                        label=""
                        type="text"
                        disabled={!isGoalActive}
                        fullWidth
                        control={control}
                        SelectProps={{
                          native: true,
                          size: 'small',
                        }}
                      >
                        {[...donationConfig.goals].map((goal) => (
                          <option key={goal._id} value={goal._id}>
                            {goal.label} |{' '}
                            {t('AccumulatedOption', {
                              accumulated: formatCurrency(
                                goal.accumulated,
                                goal.currency,
                              ),
                              finish: formatCurrency(
                                goal.finish,
                                goal.currency,
                              ),
                            })}
                          </option>
                        ))}
                      </Input>
                    </Box>
                  </Grid>
                </Grid>
              </>
            ) : null}
            <Divider sx={{ mb: 1 }} />
            <Typography variant="body2" mb={1}>
              {`${t(
                donationConfig?.paymentMethods.length
                  ? 'Choose a payment method'
                  : "User hasn't added any payment methods yet",
              )}`}
            </Typography>
            {donationConfig?.paymentMethods.length ? (
              <Stack mb={2}>
                <RadioGroup
                  sx={{ flexDirection: 'column', gap: 1 }}
                  aria-label="payment"
                  name="payment"
                  value={paymentMethod}
                  onChange={handlePaymentChange}
                >
                  {donationConfig?.paymentMethods.map((payment) => {
                    return (
                      <Card
                        sx={{
                          px: 2,
                          display: 'flex',
                          alignItems: 'center',
                          backgroundColor: 'transparent',
                        }}
                      >
                        <CardContent
                          sx={{
                            display: 'flex',
                            flexGrow: 1,
                            p: 1,
                            '&:last-child': {
                              paddingBottom: 1,
                            },
                            '.MuiFormControlLabel-root': {
                              width: '100%',
                            },
                          }}
                        >
                          <PaymentBox key={payment} payment={payment} />
                        </CardContent>
                      </Card>
                    );
                  })}
                </RadioGroup>
              </Stack>
            ) : null}
            {donationConfig?.allowMedia && (
              <Box>
                <Box mb={2}>
                  <Button
                    label={t('YouTube video')}
                    variant="outlined"
                    onClick={() => setIsAddMediaDialogOpen(true)}
                    startIcon={<YouTubeIcon sx={{ color: youtube }} />}
                    endIcon={
                      youtubeUrl ? (
                        <TaskAltRoundedIcon sx={{ color: green }} />
                      ) : (
                        <AddRoundedIcon />
                      )
                    }
                  />
                </Box>

                <Divider sx={{ mb: 1 }} />
                {isAddMediaDialogOpen && (
                  <AddMediaDialog
                    onSave={(value: string) => setValue('youtubeUrl', value)}
                    youtubeUrl={youtubeUrl}
                    open={isAddMediaDialogOpen}
                    onClose={() => setIsAddMediaDialogOpen(false)}
                  />
                )}
              </Box>
            )}

            <Box mb={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isTermsAccepted}
                    disabled={isSubmitting || noUserData}
                    onChange={handleTermsChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={
                  <Typography variant="body2">
                    {t('By clicking «Donate», you agree to')}{' '}
                    <Link to={TERMS_PATH} label={t('Terms of use')} />
                  </Typography>
                }
              />
            </Box>

            <Box>
              <Button
                type="submit"
                label={t('Donate')}
                disabled={!isTermsAccepted || !paymentMethod || !donationConfig}
                withLoader
                loading={isSubmitting}
                startIcon={<RedeemIcon />}
                fullWidth
              />
            </Box>
          </form>
        </StyledFormContainer>
      </StyledPageContainer>
    </>
  );
}
