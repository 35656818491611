import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import BarChart from 'view/components/charts/Bar';
import LineChart from 'view/components/charts/Line';
import Box from '@mui/material/Box';
import Loader from 'view/components/Loader';
import { Statistics } from 'types/statistics';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/auth';
import { getUserStatistics } from 'services/api/admin/user';
import StatisticSummary from 'view/pages/Statistics/components/StatisticSummary';

type StatisticsPageProps = {
  userId: string;
};

export default function StatisticsPage({ userId }: StatisticsPageProps) {
  const { setErrorMessage } = useAuth();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Statistics | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const getData = async () => {
      try {
        const statistics = await getUserStatistics(userId);
        setData(statistics);
      } catch (err) {
        setErrorMessage(t('Error while loading statistics'));
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, []);

  if (loading) {
    return <Loader height="100vh" />;
  }
  if (!data) {
    return <Typography variant="body2">{t('No statistics yet.')}</Typography>;
  }
  return (
    <>
      <StatisticSummary data={data} />

      <Box mb={6}>
        <Typography variant="h2">
          {t('Donations summarized by date')}
        </Typography>
        <Typography variant="body2">
          {t(
            'This chart shows how much amount of donations you earn by the day. Days without donations will have 0 sum.',
          )}
        </Typography>
        <Box height={400}>
          <LineChart data={data.sumDonationsByDate} />
        </Box>
      </Box>

      <Box>
        <Typography variant="h2">{t('Top 5 sponsors')}</Typography>
        <Typography variant="body2">
          {t('This chart shows top 5 sponsors by sum of donations made.')}
        </Typography>
        <Box
          height={400}
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          {data?.top5SponsorsByDonations?.length ? (
            <BarChart data={data.top5SponsorsByDonations} />
          ) : (
            <Typography variant="h5">{t('No data')}</Typography>
          )}
        </Box>
      </Box>
    </>
  );
}
