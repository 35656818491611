import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getDonationConfigFromCipher } from 'services/api/user';
import DonationComponent from 'view/components/DonationComponent';
import Alert from 'view/components/Alert';
import { Donation } from 'types/donations';
import { ThresholdRecord, User } from 'types/users';
import {
  getIsVoiceOverAllowed,
  getMediaSources,
  handleDonationThresholdConfig,
} from 'tools';
import DonationQueue from 'view/pages/DonationAlert/tools/DonationQueue';
import { AnimatePresence } from 'framer-motion';
import { Currency, ExchangeRate } from 'services/api/types';
import { getExchangeRates } from 'services/api/donations';
import useSocket, { Callback } from 'hooks/useSocket';

export type DonationConfig = Pick<
  User,
  | 'donationAnimationFormat'
  | 'minimumVoiceOverThreshold'
  | 'donationThresholdConfig'
  | 'cipherId'
  | 'allowMedia'
  | 'messageCensorship'
>;

export default function DonationAlert() {
  const { cipher } = useParams();
  const [error, setError] = useState('');
  const [donation, setDonation] = useState<Donation | null>(null);
  const [donationConfig, setDonationConfig] = useState<DonationConfig | null>(
    null,
  );
  const { socket } = useSocket(donationConfig?.cipherId || '');

  const [exchangeRates, setExchangeRates] = useState<ExchangeRate | null>(null);

  const donationThresholdConfig = handleDonationThresholdConfig(
    donationConfig?.donationThresholdConfig as ThresholdRecord[],
    Currency.UAH,
    exchangeRates as ExchangeRate,
  );

  const startMedia = (donation: Donation) => {
    socket?.emit('play-media', { donation });
  };

  const donationQueue = new DonationQueue(
    setDonation,
    setError,
    startMedia,
    donationConfig,
  );

  const addDonationToQueue = (donation: Donation) => {
    if (!donationConfig || !exchangeRates) {
      return;
    }

    const isVoiceOverAllowed = getIsVoiceOverAllowed(
      donation?.amount,
      donationConfig?.minimumVoiceOverThreshold,
      donation?.currency,
      exchangeRates,
    );

    const { alertUrl } = getMediaSources(
      donationThresholdConfig,
      donation?.amount,
      donation.currency,
      exchangeRates,
    );

    donationQueue.addDonation({
      ...donation,
      alertUrl,
      isVoiceOverAllowed,
    });
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (cipher) {
        try {
          const config = await getDonationConfigFromCipher(cipher);
          const rates = await getExchangeRates();

          setDonationConfig(config);
          setExchangeRates(rates);
        } catch (err) {
          if (err instanceof Error) {
            setError(err.message);
          }
        }
      }
    };

    if (cipher) {
      fetchUserData();
    }
  }, [cipher]);

  useEffect(() => {
    if (!socket) {
      return;
    }

    function onDonation(donation: Donation, callback: Callback) {
      callback(true);
      addDonationToQueue(donation);
    }

    function onRepeatDonation(donation: Donation, callback: Callback) {
      callback(true);
      addDonationToQueue(donation);
    }

    function onShowTestDonation(donation: Donation, callback: Callback) {
      callback(true);
      addDonationToQueue(donation);
    }

    function onStopDonationQueue() {
      donationQueue.stop();
    }

    socket?.on('donation', onDonation);
    socket?.on('repeat-donation', onRepeatDonation);
    socket?.on('show-test-donation', onShowTestDonation);
    socket?.on('stop-donation-queue', onStopDonationQueue);

    return () => {
      socket?.off('donation', onDonation);
      socket?.off('repeat-donation', onRepeatDonation);
      socket?.off('show-test-donation', onShowTestDonation);
      socket?.off('stop-donation-queue', onStopDonationQueue);
      setDonation(null);
    };
  }, [socket, donationConfig?.cipherId]);

  const { pictureUrl } = getMediaSources(
    donationThresholdConfig,
    donation?.amount,
    donation?.currency,
    exchangeRates,
  );

  return (
    <>
      <Alert open={!!error} type="error" label={error} />
      <AnimatePresence>
        {donation && donationConfig?.donationAnimationFormat && (
          <DonationComponent
            imageSrc={pictureUrl}
            animationFormat={donationConfig.donationAnimationFormat}
            donation={donation}
            censoring={donationConfig.messageCensorship}
          />
        )}
      </AnimatePresence>
    </>
  );
}
