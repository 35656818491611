import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getCipherIdFromCipher } from 'services/api/user';
import Alert from 'view/components/Alert';
import { Donation } from 'types/donations';
import useSocket from 'hooks/useSocket';

export default function Media() {
  const { cipher } = useParams();
  const [error, setError] = useState('');
  const [cipherId, setCipherId] = useState<string>('');
  const { socket } = useSocket(cipherId);

  const [queue, setQueue] = useState<string[]>([]);
  const [currentVideo, setCurrentVideo] = useState<string | null>(null);

  const addToQueue = (youtubeId: string) => {
    setQueue((prev) => {
      if (prev.includes(youtubeId)) {
        return prev;
      }
      return [...prev, youtubeId];
    });

    if (!currentVideo) {
      setCurrentVideo(youtubeId);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (cipher) {
        try {
          const config = await getCipherIdFromCipher(cipher);

          setCipherId(config.cipherId);
        } catch (err) {
          if (err instanceof Error) {
            setError(err.message);
          }
        }
      }
    };

    if (cipher) {
      fetchUserData();
    }
  }, [cipher]);

  useEffect(() => {
    if (!socket) {
      return;
    }

    function onPlayMedia(donation: Donation) {
      if (donation?.youtubeId) {
        addToQueue(donation.youtubeId);
      }
    }

    function onStopMedia() {
      setQueue([]);
      setCurrentVideo(null);
    }

    socket.on('play-media', onPlayMedia);
    socket.on('stop-media-queue', onStopMedia);

    return () => {
      socket.off('play-media', onPlayMedia);
      socket.off('stop-media-queue', onStopMedia);
    };
  }, [socket, cipherId, currentVideo]);

  const playNextVideo = () => {
    setQueue((prevQueue) => {
      const [, ...rest] = prevQueue;
      setCurrentVideo(rest[0] || null);

      return rest;
    });
  };

  useEffect(() => {
    if (!(window as any).YT) {
      const script = document.createElement('script');
      script.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(script);
    }
  }, []);

  useEffect(() => {
    if (currentVideo) {
      const playerContainerId = `player-${currentVideo}`;

      let playerContainer = document.getElementById(playerContainerId);
      if (!playerContainer) {
        playerContainer = document.createElement('div');
        playerContainer.id = playerContainerId;
        document.body.appendChild(playerContainer);
      }

      const player = new (window as any).YT.Player(playerContainerId, {
        videoId: currentVideo,
        playerVars: { autoplay: 1, controls: 0 },
        events: {
          onStateChange: (event: any) => {
            if (event.data === 0) {
              playNextVideo();
            }
          },
        },
      });

      return () => {
        player.destroy();
      };
    }
  }, [currentVideo]);

  return (
    <div style={{ width: '1920px', height: '1080px' }}>
      <Alert open={!!error} type="error" label={error} />
      <div
        id={`player-${currentVideo}`}
        style={{ width: '100%', height: '100%' }}
      ></div>
    </div>
  );
}
