import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'view/components/Button';
import Input from 'view/components/Form/Input';
import FormHeader from 'view/components/Form/Header';
import { User } from 'types/users';
import { StyledFormContainer, StyledFormActions } from '../../styled';
import useUser from 'hooks/user';
import { ChangeContentCreatorDescriptionValidationSchema } from 'constraints';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/auth';
import { updateUserData } from 'services/api/user';

type ChangeAccountDescriptionFormData = {
  description: string;
};

type ChangeAccountDescriptionProps = {
  onCancel: () => void;
  onSaved: ({ description }: Pick<User, 'description'>) => void;
};

export default function ChangeAccountDescription({
  onCancel,
  onSaved,
}: ChangeAccountDescriptionProps) {
  const { setErrorMessage, setSuccessMessage } = useAuth();
  const { user } = useUser();
  const { t } = useTranslation();

  const initialFormData = {
    resolver: yupResolver(ChangeContentCreatorDescriptionValidationSchema(t)),
    defaultValues: { description: user.description },
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isValidating, isDirty },
  } = useForm<ChangeAccountDescriptionFormData>(initialFormData);

  const onSubmit = async (data: ChangeAccountDescriptionFormData) => {
    setErrorMessage('');

    try {
      await updateUserData(user._id, data);
      onSaved(data);
      reset(data);
      setSuccessMessage(t('Successfully updated account description'));
    } catch (err) {
      setErrorMessage(t('Error while updating account description'));
    }
  };

  const submitDisabled = !isDirty || isSubmitting;

  return (
    <StyledFormContainer>
      <FormHeader
        title={t('Account description')}
        description={t('Change your account description')}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          name="description"
          label={t('Account description')}
          type="text"
          error={!!errors.description?.message}
          helperText={errors.description?.message}
          control={control}
          mb={2}
        />
        <StyledFormActions>
          <Button
            type="submit"
            label={t('Save')}
            loading={isSubmitting || isValidating}
            disabled={submitDisabled}
            withLoader
          />
          <Button
            label={t('Back')}
            variant="text"
            color="secondary"
            onClick={onCancel}
          />
        </StyledFormActions>
      </form>
    </StyledFormContainer>
  );
}
