import { MouseEventHandler, ReactNode } from 'react';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { StyledDialog, StyledDialogContent } from './styled';

import { DialogTitle } from '@mui/material';

type DialogProps = {
  open: boolean;
  title: string;
  content: ReactNode;
  actions: ReactNode;
  onClose: MouseEventHandler<HTMLButtonElement>;
  onSubmit?: () => void;
};

export default function Dialog({
  open = false,
  title,
  content,
  actions,
  onClose,
  onSubmit,
}: DialogProps) {
  return (
    <StyledDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 8,
        })}
      >
        <ClearOutlinedIcon />
      </IconButton>
      <form onSubmit={onSubmit}>
        <StyledDialogContent sx={{ pt: 3, pb: 1 }} dividers>
          {content}
        </StyledDialogContent>
        <DialogActions>{actions}</DialogActions>
      </form>
    </StyledDialog>
  );
}
