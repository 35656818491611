import React, { ReactElement } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Invoice, InvoiceDetails } from 'types/invoices';
import { formatCurrency } from 'tools';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import Chip, { ChipOwnProps } from '@mui/material/Chip';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DoneIcon from '@mui/icons-material/Done';
import InvoiceDetailsTable from 'view/pages/Billing/components/InvoiceDetailsTable';
import { createInvoiceUrl, getInvoice } from 'services/api/invoices';
import useAuth from 'hooks/auth';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import Skeleton from '@mui/material/Skeleton';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import Collapse from '@mui/material/Collapse';
import Button from 'view/components/Button';
import { useDateFormatter } from 'hooks/dateFormatter';

type InvoicesTableProps = {
  invoices: Invoice[];
};

export const getInvoiceChip = (
  isPaid: boolean,
): { label: string; icon: ReactElement; color: ChipOwnProps['color'] } => {
  if (isPaid) {
    return {
      label: 'Paid',
      icon: <DoneIcon />,
      color: 'success',
    };
  }
  return {
    label: 'Not paid',
    icon: <ErrorOutlineIcon />,
    color: 'error',
  };
};

export default function InvoicesTable({ invoices }: InvoicesTableProps) {
  const { t } = useTranslation();

  if (!invoices?.length) {
    return (
      <Typography
        sx={{ pl: { xs: 2, sm: 4 }, pt: { xs: 2, sm: 4 } }}
        variant="body2"
      >
        {t('No data')}
      </Typography>
    );
  }

  return (
    <Paper sx={{ boxShadow: 'none', background: 'none' }}>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: 50 }} />
              <TableCell>{t('Date')}</TableCell>
              <TableCell>{t('Status')}</TableCell>
              <TableCell align="right">{t('Amount')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices.map((row) => {
              return <CollapseRow key={row._id} row={row} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

function CollapseRow({ row }: { row: Invoice }) {
  const { setErrorMessage } = useAuth();
  const { t } = useTranslation();
  const formatDate = useDateFormatter();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [invoiceUrlLoading, setInvoiceUrlLoading] = useState(false);
  const [data, setData] = useState<InvoiceDetails | null>(null);

  const getInvoiceData = async (invoiceId: string) => {
    try {
      const invoice = await getInvoice(invoiceId);
      setData(invoice);
    } catch (err) {
      setErrorMessage(t('Error while loading invoice'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      getInvoiceData(row._id);
    }
  }, [open]);

  const onPayClick = async () => {
    try {
      setInvoiceUrlLoading(true);
      const { invoiceUrl } = await createInvoiceUrl(row._id);
      window.open(invoiceUrl, '_blank', 'noopener,noreferrer');
    } catch (err) {
      setErrorMessage(t('Error while loading invoice'));
    } finally {
      setInvoiceUrlLoading(false);
    }
  };

  const chip = getInvoiceChip(row?.isPaid);

  return (
    <>
      <TableRow
        key={row._id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowUpRoundedIcon />
            ) : (
              <KeyboardArrowDownRoundedIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {formatDate(new Date(row.startDate), 'd MMM')} -{' '}
          {formatDate(new Date(row.endDate), 'd MMM, yyyy')}
        </TableCell>
        <TableCell>
          <Chip
            color={chip.color}
            icon={chip.icon}
            variant="filled"
            label={t(chip.label)}
          />
        </TableCell>
        <TableCell align="right">
          {formatCurrency(row.amount, row.currency)}
        </TableCell>
        <TableCell align="center">
          {!row?.isPaid && (
            <Button
              withLoader
              loading={invoiceUrlLoading}
              startIcon={<OpenInNewRoundedIcon />}
              variant="text"
              label={t('Pay')}
              onClick={onPayClick}
            />
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {(() => {
                if (loading) {
                  return (
                    <>
                      <Skeleton
                        animation="wave"
                        variant="rounded"
                        width="100%"
                        height={50}
                        sx={{ mb: 0.5 }}
                      />
                      <Skeleton
                        animation="wave"
                        variant="rounded"
                        width="100%"
                        height={50}
                        sx={{ mb: 0.5 }}
                      />
                      <Skeleton
                        animation="wave"
                        variant="rounded"
                        width="100%"
                        height={50}
                        sx={{ mb: 0.5 }}
                      />
                    </>
                  );
                }
                if (!data) {
                  return (
                    <Typography variant="body2">{t('No data')}</Typography>
                  );
                }
                return <InvoiceDetailsTable data={data} />;
              })()}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
