import React, { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import DonationsList from 'view/pages/DonationBook/components/DonationsList';
import PageHeader from 'view/components/PageHeader';
import Loader from 'view/components/Loader';
import Search from 'view/components/Search';
import { Donation } from 'types/donations';
import { StyledContainer } from './styled';
import { Currency, PaginatedResponse } from 'services/api/types';
import debounce from 'lodash.debounce';
import useUser from 'hooks/user';
import { listDonations, listSearchDonations } from 'services/api/donations';
import { StyledChip } from 'view/components/Chip';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/auth';
import IconButton from '@mui/material/IconButton';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import { LIVE_HISTORY_PATH } from 'view/routes';
import FilterAltOffRoundedIcon from '@mui/icons-material/FilterAltOffRounded';
import { PaymentMethodTypes } from 'types/users';
import { PAYMENT_METHOD_MEDIA_MAP } from 'view/pages/AccountSettings/components/ChangePaymentMethods';
import {
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  FormControl,
  InputLabel,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import Tooltip from '@mui/material/Tooltip';
import Button from 'view/components/Button';
import useSocket from 'hooks/useSocket';

type DonationFilters = {
  currency: Array<string>;
  paymentMethod: Array<string>;
};

export default function DonationsBook() {
  const { setErrorMessage } = useAuth();
  const { user } = useUser();
  const { socket } = useSocket(user?.cipherId);
  const { donationsData, setDonationsData, OBSUrl, isLoading } = useUser();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchResult, setSearchResult] = useState<PaginatedResponse<Donation>>(
    {
      items: [] as Donation[],
    } as PaginatedResponse<Donation>,
  );

  const initialFormData = {
    defaultValues: {
      currency: [],
      paymentMethod: [],
    },
  };

  const {
    control,
    reset,
    getValues,
    setValue,
    trigger,
    watch,
    formState: { isDirty },
  } = useForm<DonationFilters>(initialFormData);
  const currency = watch('currency');
  const paymentMethod = watch('paymentMethod');

  const handleDonation = (donation: Donation) => {
    const dataObject = {
      items: [donation],
      links: donationsData.links,
      meta: {
        ...donationsData.meta,
        totalItems: donationsData.meta.totalItems + 1,
        itemCount: donationsData.meta.itemCount + 1,
      },
    };

    setDonationsData(dataObject);
  };

  useEffect(() => {
    if (!socket) {
      return;
    }
    socket?.on('donation', handleDonation);

    return () => {
      socket?.off('donation', handleDonation);
    };
  }, [socket]);

  const fetchNextDonations = async (nextLink?: string) => {
    if (nextLink) {
      try {
        const donationsData = await listDonations(nextLink);
        setDonationsData(donationsData);
      } catch (err) {
        setErrorMessage(t('Error while loading donations'));
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchDonations = useCallback(
    debounce(async (searchQuery: string) => {
      setLoading(true);

      try {
        const searchResult = await listSearchDonations({
          search: searchQuery,
          filters: getValues(),
        });
        setSearchResult(searchResult);
        setLoading(false);
      } catch (err) {
        setErrorMessage(t('Error while searching for donations'));
        setLoading(false);
      }
    }, 500),
    [],
  );

  const fetchNextSearchDonations = async (nextLink?: string) => {
    if (nextLink) {
      try {
        const searchResult = await listSearchDonations({
          url: nextLink,
          search: searchQuery,
        });
        setSearchResult(({ items }) => ({
          ...searchResult,
          items: [...items, ...searchResult.items],
        }));
      } catch (err) {
        setErrorMessage(t('Error while loading donations'));
      }
    }
  };

  useEffect(() => {
    if (searchQuery || paymentMethod.length || currency.length) {
      setLoading(true);
      searchDonations(searchQuery);
    }
  }, [searchQuery, paymentMethod, currency, searchDonations]);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchQuery(e.target.value);

  const onClearSearch = () => setSearchQuery('');

  const openWidget = () => {
    const url = `${process.env.REACT_APP_UI_URL}/${OBSUrl}${LIVE_HISTORY_PATH}`;
    const windowFeatures =
      'width=500,height=1080,resizable=no,scrollbars=no,toolbar=no,menubar=no,location=no,status=no';

    window.open(url, '_blank', windowFeatures);
  };

  const getSummaryNumber = () => {
    if (loading) {
      return '?';
    }
    if (searchQuery || isDirty) {
      return searchResult?.meta?.totalItems;
    }
    return donationsData?.meta?.totalItems;
  };

  const Filters = (
    <Box display="flex" alignItems="center" flexWrap="wrap" gap={2}>
      {isDirty && (
        <Tooltip title={t('Clear')}>
          <IconButton onClick={() => reset()}>
            <FilterAltOffRoundedIcon />
          </IconButton>
        </Tooltip>
      )}

      <Box width="12rem">
        <FormControl fullWidth>
          <InputLabel>{t('Currency')}</InputLabel>
          <Controller
            name="currency"
            control={control}
            defaultValue={[]}
            render={({ field }) => (
              <Select
                {...field}
                multiple
                label={t('Currency')}
                value={field.value || []}
                renderValue={(selected) => selected.join(', ')}
                onChange={(event) => {
                  const value = event.target.value as Array<string>;
                  setValue('currency', value, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                  trigger('currency');
                }}
              >
                {Object.values(Currency).map((option) => (
                  <MenuItem key={option} value={option}>
                    <Checkbox checked={field.value.includes(option)} />
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      </Box>
      <Box width="12rem">
        <FormControl fullWidth>
          <InputLabel>{t('Payment method')}</InputLabel>
          <Controller
            name="paymentMethod"
            control={control}
            defaultValue={[]}
            render={({ field }) => (
              <Select
                {...field}
                multiple
                label={t('Payment method')}
                value={field.value || []}
                renderValue={(selected) =>
                  selected
                    .map(
                      (item) =>
                        PAYMENT_METHOD_MEDIA_MAP[item as PaymentMethodTypes]
                          .shortLabel,
                    )
                    .join(', ')
                }
                onChange={(event) => {
                  const value = event.target.value as Array<string>;
                  setValue('paymentMethod', value, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                  trigger('paymentMethod');
                }}
              >
                {Object.values(PaymentMethodTypes).map((option) => (
                  <MenuItem key={option} value={option}>
                    <Checkbox checked={field.value.includes(option)} />
                    <ListItemText
                      primary={PAYMENT_METHOD_MEDIA_MAP[option].shortLabel}
                    />
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      </Box>
      <Box sx={{ width: '12rem' }}>
        <Search
          name="donations-search"
          value={searchQuery}
          label={t('Search')}
          onChange={onSearchChange}
          onClear={onClearSearch}
        />
      </Box>
    </Box>
  );

  return (
    <StyledContainer component="main">
      <PageHeader
        title={t('Donations')}
        endAdornment={
          <Box display="flex" alignItems="center" gap={1}>
            <StyledChip label={getSummaryNumber()} size="small" />
            <IconButton onClick={openWidget}>
              <OpenInNewRoundedIcon />
            </IconButton>
          </Box>
        }
        action={
          <>
            <Box sx={{ display: { xs: 'flex', sm: 'flex', md: 'none' } }}>
              <Button
                startIcon={<TuneRoundedIcon />}
                label={t('Filters')}
                onClick={() => setIsFiltersOpen(!isFiltersOpen)}
              />
            </Box>
            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>
              {Filters}
            </Box>
          </>
        }
      />
      <Box
        sx={{
          display: {
            xs: isFiltersOpen ? 'flex' : 'none',
            sm: isFiltersOpen ? 'flex' : 'none',
            md: 'none',
          },
        }}
        mb={4}
      >
        {Filters}
      </Box>
      {(() => {
        if (loading || isLoading) {
          return <Loader justify="flex-start" />;
        }
        if (searchQuery || paymentMethod.length || currency.length) {
          return (
            <DonationsList
              donations={searchResult?.items}
              fetchNextDonations={() =>
                fetchNextSearchDonations(searchResult?.links?.next)
              }
              allDonationsLoaded={!!searchResult?.links?.next}
            />
          );
        }
        return (
          <DonationsList
            donations={donationsData?.items}
            fetchNextDonations={() =>
              fetchNextDonations(donationsData?.links?.next)
            }
            allDonationsLoaded={!!donationsData?.links?.next}
          />
        );
      })()}
    </StyledContainer>
  );
}
