import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from 'view/components/PageHeader';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ConfirmationDialog from 'view/components/ConfirmationDialog';
import { deleteAccount as deleteAccountRequest } from 'services/api/user';
import BackButton from 'view/components/BackButton';
import Button from 'view/components/Button';
import TrashIcon from 'view/components/icons/Trash';
import UserInfoRow from 'view/components/UserInfoRow';
import { StyledContainer } from './styled';
import ChangeDonationDesign from 'view/pages/AccountSettings/components/ChangeDonationDesign';
import ChangeMinimumDonationAmount from 'view/pages/AccountSettings/components/ChangeMinimumDonationAmount';
import ChangeDonationThresholdConfig from 'view/pages/AccountSettings/components/ChangeDonationThresholdConfig';
import ChangeUsername from 'view/pages/AccountSettings/components/ChangeUsername';
import ChangePaymentMethods from 'view/pages/AccountSettings/components/ChangePaymentMethods';
import useUser from 'hooks/user';
import useAuth from 'hooks/auth';
import { formatCurrency } from 'tools';
import Avatar from 'view/components/Avatar';
import ChangeProfilePicture from 'view/pages/AccountSettings/components/ChangeProfilePicture';
import ChangeSocialNetworks from 'view/pages/AccountSettings/components/ChangeSocialNetworks';
import Chip from '@mui/material/Chip';
import { Currency } from 'services/api/types';
import ChangeMinimumDonationVoiceOver from 'view/pages/AccountSettings/components/ChangeMinimumDonationVoiceOver';
import Badge from '@mui/material/Badge';
import ChangeAccountDescription from 'view/pages/AccountSettings/components/ChangeAccountDescription';
import ChangeMessageCensorship from 'view/pages/AccountSettings/components/ChangeMessageCensorship';
import Grid from '@mui/material/Grid2';
import PortraitRoundedIcon from '@mui/icons-material/PortraitRounded';
import DriveFileRenameOutlineRoundedIcon from '@mui/icons-material/DriveFileRenameOutlineRounded';
import PaymentsRoundedIcon from '@mui/icons-material/PaymentsRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import BrushRoundedIcon from '@mui/icons-material/BrushRounded';
import MicOffRoundedIcon from '@mui/icons-material/MicOffRounded';
import MoneyRoundedIcon from '@mui/icons-material/MoneyRounded';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import Paper from '@mui/material/Paper';

export default function AccountSettings() {
  const { signOut, setErrorMessage } = useAuth();
  const { user, setUser } = useUser();

  const [loading, setLoading] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [fieldToEdit, setFieldToEdit] = useState<string | null>(null);
  const { t } = useTranslation();

  const deleteAccount = async () => {
    setLoading(true);
    try {
      await deleteAccountRequest();
      signOut();
    } catch (err) {
      setErrorMessage(t('Error while deleting account'));
    } finally {
      setLoading(false);
    }
  };

  const clearFieldToEdit = () => setFieldToEdit(null);

  const renderEditForm = (fieldToEdit: string) => {
    if (fieldToEdit === 'profilePicture') {
      return <ChangeProfilePicture onCancel={clearFieldToEdit} />;
    }
    if (fieldToEdit === 'username') {
      return (
        <ChangeUsername
          onCancel={clearFieldToEdit}
          onSaved={({ contentCreatorName }) => {
            setUser({ ...user, contentCreatorName });
          }}
        />
      );
    }
    if (fieldToEdit === 'socialNetworks') {
      return (
        <ChangeSocialNetworks
          onCancel={clearFieldToEdit}
          onSaved={({ socialNetworks }) => {
            setUser({ ...user, socialNetworks });
          }}
        />
      );
    }
    if (fieldToEdit === 'donationDesign') {
      return (
        <ChangeDonationDesign
          onCancel={clearFieldToEdit}
          onSaved={(user) => setUser(user)}
        />
      );
    }

    if (fieldToEdit === 'paymentMethods') {
      return <ChangePaymentMethods onCancel={clearFieldToEdit} />;
    }

    if (fieldToEdit === 'accountDescription') {
      return (
        <ChangeAccountDescription
          onCancel={clearFieldToEdit}
          onSaved={({ description }) => {
            setUser({ ...user, description });
          }}
        />
      );
    }

    if (fieldToEdit === 'minimumDonationAmount') {
      return (
        <ChangeMinimumDonationAmount
          onCancel={clearFieldToEdit}
          onSaved={({ minimumDonationAmount }) => {
            setUser({ ...user, minimumDonationAmount });
          }}
        />
      );
    }

    if (fieldToEdit === 'minimumDonationVoiceOver') {
      return (
        <ChangeMinimumDonationVoiceOver
          onCancel={clearFieldToEdit}
          onSaved={({ minimumVoiceOverThreshold }) => {
            setUser({ ...user, minimumVoiceOverThreshold });
          }}
        />
      );
    }

    if (fieldToEdit === 'donationThresholdConfig') {
      return <ChangeDonationThresholdConfig onCancel={clearFieldToEdit} />;
    }

    if (fieldToEdit === 'messageCensorship') {
      return <ChangeMessageCensorship onCancel={clearFieldToEdit} />;
    }

    return null;
  };

  const infoRows = [
    {
      label: t('Profile picture'),
      type: 'profilePicture',
      icon: <PortraitRoundedIcon />,
      value: (
        <Avatar
          src={user.profilePictureUrl}
          contentCreatorName={user.contentCreatorName}
        />
      ),
    },
    {
      label: t('Username'),
      icon: <DriveFileRenameOutlineRoundedIcon />,
      type: 'username',
      value: user.contentCreatorName,
    },
    {
      label: (
        <Badge
          badgeContent={
            Object.keys(user?.paymentMethods || {}).length === 0 ? 1 : 0
          }
          variant="dot"
          color="primary"
        >
          {t('Payment methods')}
        </Badge>
      ),
      icon: <PaymentsRoundedIcon />,
      type: 'paymentMethods',
      value: t('Customize donation payment methods for your audience'),
    },
    {
      label: t('Account description'),
      icon: <InfoRoundedIcon />,
      type: 'accountDescription',
      value: user.description || t('Change your account description'),
    },
    {
      label: t('Social networks'),
      icon: <FacebookRoundedIcon />,
      type: 'socialNetworks',
      value: t('Links to social networks are displayed on the donation page'),
    },
    {
      label: t('Donation animation'),
      icon: <BrushRoundedIcon />,
      type: 'donationDesign',
      value: t(
        'Customize the animation of donation appearance and disappearance',
      ),
    },
    {
      label: t('Donation voice-over'),
      icon: <MicOffRoundedIcon />,
      type: 'minimumDonationVoiceOver',
      value: t(
        'Turn off the voice-overs or set a minimum donation amount with voice-overs',
      ),
    },
    {
      label: t('Minimum donation amount'),
      icon: <MoneyRoundedIcon />,
      type: 'minimumDonationAmount',
      value: (
        <Box display="flex" flexWrap="wrap" alignItems="center" gap={1}>
          {user?.minimumDonationAmount &&
            Object.keys(Currency).map((key) => {
              const currency = key as Currency;

              return (
                <Chip
                  key={currency}
                  label={`${currency} ${formatCurrency(
                    user.minimumDonationAmount[currency] as number,
                    currency,
                  )}`}
                  variant="outlined"
                />
              );
            })}
        </Box>
      ),
    },
    {
      label: t('Donation threshold config'),
      icon: <TrendingUpRoundedIcon />,
      type: 'donationThresholdConfig',
      value: t('Setting up the donation threshold, sound, and images'),
    },
    {
      label: t('Message censorship'),
      icon: <VisibilityOffRoundedIcon />,
      type: 'messageCensorship',
      value: t('Turn message censorship on or off'),
    },
  ];

  return (
    <StyledContainer component="main">
      <PageHeader
        title={t('Settings')}
        startAdornment={
          fieldToEdit && (
            <Box display={{ sm: 'block', md: 'none' }}>
              <BackButton onClick={() => setFieldToEdit('')} />
            </Box>
          )
        }
      />
      <Grid container flexWrap="wrap" spacing={4}>
        <Grid
          size={{ xs: 12, sm: 12, md: 4 }}
          display={{
            xs: fieldToEdit ? 'none' : 'unset',
            sm: fieldToEdit ? 'none' : 'unset',
            md: 'unset',
          }}
        >
          <Paper variant="outlined">
            <List>
              {infoRows.map(({ type, label, value = '', icon }, index) => {
                return (
                  <UserInfoRow
                    selected={fieldToEdit === type}
                    key={type}
                    label={label}
                    value={value}
                    icon={icon}
                    divider={infoRows.length !== index + 1}
                    onClick={() => setFieldToEdit(type)}
                  />
                );
              })}
            </List>
          </Paper>
        </Grid>
        {fieldToEdit && (
          <Grid size={{ xs: 12, sm: 12, md: 8 }}>
            <Paper variant="outlined" sx={{ p: { xs: 2, sm: 4 } }}>
              {renderEditForm(fieldToEdit)}
            </Paper>
          </Grid>
        )}
      </Grid>

      {!fieldToEdit && (
        <Box display="flex" justifyContent="flex-end" mt={6}>
          <Button
            label={t('Delete account')}
            variant="text"
            color="error"
            disabled={loading}
            endIcon={<TrashIcon />}
            onClick={() => setIsDeleteDialogOpen(true)}
          />
        </Box>
      )}
      <ConfirmationDialog
        open={isDeleteDialogOpen}
        loading={loading}
        disabled={loading}
        withLoader
        text={t('Are you sure you want to delete your account?')}
        confirmText={t('Delete')}
        color="error"
        onConfirm={deleteAccount}
        onClose={() => setIsDeleteDialogOpen(false)}
      />
    </StyledContainer>
  );
}
