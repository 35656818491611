import { format, isThisMonth, isToday, isYesterday } from 'date-fns';
import {
  PaymentMethodEntry,
  PaymentMethodTypes,
  ThresholdRecord,
} from 'types/users';
import {
  BAD_WORDS,
  DEFAULT_PICTURE_URL,
  DEFAULT_SOUND_URL,
} from 'view/constants';
import { Currency, ExchangeRate } from 'services/api/types';

export function handleEventDate(createdAt: Date) {
  if (isToday(createdAt)) {
    return format(createdAt, 'h:mm aa');
  }
  if (isThisMonth(createdAt)) {
    return format(createdAt, 'MMM d, h:mm aa');
  }
  return format(createdAt, 'MMM, Y');
}

export function handleCardText(card: string) {
  if (!card) {
    return '-';
  }
  const start = card.substring(0, 4);
  const end = card.substring(12);

  return `${start} **** **** ${end}`;
}

interface HasCreatedAt {
  createdAt: string | number | Date;
}

interface GroupedItems<T> {
  [date: string]: T[];
}

export function groupItemsByDate<T extends HasCreatedAt>(
  items: T[],
): GroupedItems<T> {
  return items.reduce((acc, item) => {
    const date = new Date(item.createdAt);
    if (isToday(date)) {
      (acc['today'] ||= []).push(item);
      return acc;
    }
    if (isYesterday(date)) {
      (acc['yesterday'] ||= []).push(item);
      return acc;
    }
    const formattedDate = format(date, 'MMMM d, yyyy');
    (acc[formattedDate] ||= []).push(item);
    return acc;
  }, {} as GroupedItems<T>);
}

export function sortDonationsByDate(donations: string[]) {
  return [
    ...donations.filter((date) => date === 'today'),
    ...donations.filter((date) => date === 'yesterday'),
    ...donations.filter((date) => date !== 'today' && date !== 'yesterday'),
  ];
}

export function roundTo2Decimals(value: number) {
  return Math.round((value + Number.EPSILON) * 100) / 100;
}

export function formatCurrency(value: number, currency: Currency) {
  switch (currency) {
    case Currency.UAH:
      return formatUAHCurrency(value);
    case Currency.EUR:
      return formatEURCurrency(value);
    case Currency.USD:
      return formatUSDCurrency(value);
    case Currency.USDT:
      return formatUSDTCurrency(value);
    default:
      return formatUAHCurrency(value);
  }
}

export function formatUSDCurrency(value: number) {
  return new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    currencyDisplay: 'narrowSymbol',
  }).format(value);
}

export function formatEURCurrency(value: number) {
  return new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    currencyDisplay: 'narrowSymbol',
  }).format(value);
}

export function formatUSDTCurrency(value: number) {
  const number = new Intl.NumberFormat('fr-FR', {
    style: 'decimal',
  }).format(value);
  return `${number} ${Currency.USDT}`;
}

export function formatUAHCurrency(value: number) {
  return new Intl.NumberFormat('uk-UA', {
    style: 'currency',
    currency: 'UAH',
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    currencyDisplay: 'narrowSymbol',
  }).format(value);
}

export function copyToClipboard(value: string) {
  navigator.clipboard.writeText(value);
}

export function truncateMiddle(
  fullStr: string,
  maxLength: number = 20,
  separator: string = '...',
): string {
  if (fullStr.length <= maxLength) return fullStr;

  const sepLen = separator.length;

  const charsToShow = maxLength - sepLen;

  const frontChars = Math.ceil(charsToShow / 2);
  const backChars = Math.floor(charsToShow / 2);

  return (
    fullStr.slice(0, frontChars) +
    separator +
    fullStr.slice(fullStr.length - backChars)
  );
}

export function isCurrentThresholdBox(
  amount: number,
  threshold: number,
  donationThresholdConfig: ThresholdRecord[],
): boolean {
  if (donationThresholdConfig.length === 1) {
    return true;
  }
  const sortedConfig = [...donationThresholdConfig].sort(
    (a, b) => b.threshold - a.threshold,
  );

  const config = sortedConfig?.find((item, index) => {
    const isLastIndex = index === sortedConfig.length - 1;

    return (
      parseInt(amount.toString(), 10) >=
        parseInt(item?.threshold.toString(), 10) || isLastIndex
    );
  });

  return threshold.toString() === config?.threshold.toString();
}

export function getMediaSources(
  donationThresholdConfig: ThresholdRecord[],
  amount: number | undefined,
  currency: Currency | undefined,
  exchangeRates: ExchangeRate | null,
): { alertUrl: string; pictureUrl: string } {
  if (
    !donationThresholdConfig?.length ||
    !amount ||
    !currency ||
    !exchangeRates
  ) {
    return {
      pictureUrl: DEFAULT_PICTURE_URL,
      alertUrl: DEFAULT_SOUND_URL,
    };
  }

  if (donationThresholdConfig.length === 1) {
    const [firstItem] = donationThresholdConfig;
    return {
      pictureUrl: firstItem.pictureUrl,
      alertUrl: firstItem.soundUrl,
    };
  }

  const sortedConfig = [...donationThresholdConfig].sort(
    (a, b) => b.threshold - a.threshold,
  );

  const config = sortedConfig?.find((item, index) => {
    const isLastIndex = index === sortedConfig.length - 1;

    return (
      amount >= getAmount(item?.threshold, currency, exchangeRates) ||
      isLastIndex
    );
  });

  return {
    pictureUrl: config?.pictureUrl || DEFAULT_PICTURE_URL,
    alertUrl: config?.soundUrl || DEFAULT_SOUND_URL,
  };
}

export function getAmount(
  value: number,
  currency: Currency,
  exchangeRates: ExchangeRate,
) {
  if (currency === Currency.UAH) {
    return value;
  }

  return roundTo2Decimals(value / exchangeRates[currency]);
}

export function handleDonationThresholdConfig(
  donationThresholdConfig: ThresholdRecord[],
  currency: Currency | null,
  exchangeRates: ExchangeRate,
): ThresholdRecord[] {
  if (!currency) {
    return [] as ThresholdRecord[];
  }

  if (currency === Currency.UAH) {
    return donationThresholdConfig;
  }

  return donationThresholdConfig?.map((thresholdConfig) => {
    return {
      ...thresholdConfig,
      amount: getAmount(thresholdConfig.threshold, currency, exchangeRates),
    };
  });
}

export function createHiddenInput(
  name: string | number,
  value: string | number,
) {
  const input = document.createElement('input');
  input.type = 'hidden';
  input.name = name.toString();
  input.value = value.toString();
  return input;
}

export async function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getIsVoiceOverAllowed(
  amount: number,
  minimumVoiceOverThreshold: number | undefined,
  currency: Currency | null,
  exchangeRates: ExchangeRate | null,
) {
  if (!amount || !minimumVoiceOverThreshold || !currency || !exchangeRates) {
    return true;
  }

  const threshold = getAmount(
    minimumVoiceOverThreshold,
    currency,
    exchangeRates,
  );

  return (
    !minimumVoiceOverThreshold || (threshold !== -1 && amount >= threshold)
  );
}

export function extractYouTubeVideoId(url: string) {
  const regex = /(?:youtube\.com\/(?:watch\?v=|embed\/)|youtu\.be\/)([^\s&]+)/;
  const match = url?.match(regex);

  return match ? match[1] : null;
}

export function isWhitepay(
  method: PaymentMethodEntry,
): method is Extract<
  PaymentMethodEntry,
  { name: PaymentMethodTypes.WHITEPAY }
> {
  return method.name === PaymentMethodTypes.WHITEPAY;
}

export function isMonobanka(
  method: PaymentMethodEntry,
): method is Extract<
  PaymentMethodEntry,
  { name: PaymentMethodTypes.MONOBANKA }
> {
  return method.name === PaymentMethodTypes.MONOBANKA;
}

export function isWayforpay(
  method: PaymentMethodEntry,
): method is Extract<
  PaymentMethodEntry,
  { name: PaymentMethodTypes.WAYFORPAY }
> {
  return method.name === PaymentMethodTypes.WAYFORPAY;
}

export function censorText(text: string): string {
  const regex = new RegExp(
    BAD_WORDS.map((word) => `(${word})+`).join('|'),
    'gi',
  );

  return text.replace(regex, (match) => {
    if (match.length <= 2) {
      return '*'.repeat(match.length);
    }

    const [firstChar] = match;
    const lastChar = match[match.length - 1];
    const censoredPart = '*'.repeat(match.length - 2);

    return firstChar + censoredPart + lastChar;
  });
}
