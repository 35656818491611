import React, { ReactElement } from 'react';
import { Controller, Control } from 'react-hook-form';
import IconButton from '@mui/material/IconButton';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Typography from '@mui/material/Typography';

type FormInputProps = {
  name: string;
  id: string;
  error?: boolean;
  helperText?: ReactElement | string;
  control: Control<any, any>;
  accept?: string;
  icon?: ReactElement;
};

export default function FormFileUpload({
  name,
  error,
  helperText,
  control,
  accept,
  id,
  icon = <UploadFileIcon />,
}: FormInputProps) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <>
            {/*{!!field?.value?.name && truncateMiddle(field.value.name)}*/}

            <input
              type="file"
              accept={accept}
              hidden
              id={id}
              onChange={(e) => {
                const file = e.target.files?.[0] || null;
                if (!file) return;

                field.onChange(file);
                e.target.value = '';
              }}
            />
            <label htmlFor={id}>
              <IconButton component="span">{icon}</IconButton>
            </label>
            {!!error && (
              <Typography color="error" variant="body2">
                {helperText}
              </Typography>
            )}
          </>
        );
      }}
    />
  );
}
