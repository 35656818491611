import * as yup from 'yup';
import type { TFunction } from 'i18next';
import { formatCurrency } from 'tools';
import { checkIsUserNameExist } from 'services/api/user';
import { Currency } from 'services/api/types';
import { MAX_AMOUNT } from 'view/constants';
import { ThresholdRecord, MinimumDonationAmount } from 'types/users';

export const DonateValidationSchema = (
  t: TFunction,
  minimumDonationAmount: MinimumDonationAmount,
) => {
  return yup
    .object({
      message: yup
        .string()
        .trim()
        .typeError(t('Must be a string'))
        .max(256, t('Must not be longer than', { value: 256 })),
      sponsorName: yup
        .string()
        .trim()
        .typeError(t('Must be a string'))
        .max(64, t('Must not be longer than', { value: 64 })),
      voice: yup.string(),
      amount: yup
        .number()
        .typeError(t('Must be a number'))
        .required(t('Cannot be empty'))
        .when('currency', (currency: Currency, schema) => {
          const minAmount = minimumDonationAmount[currency] || 1;
          const maxAmount = MAX_AMOUNT[currency] || Infinity;

          return schema
            .min(
              minAmount,
              t('Minimum donation amount is', {
                amount: formatCurrency(minAmount, currency),
              }),
            )
            .max(
              maxAmount,
              t('Maximum donation amount is', {
                amount: formatCurrency(maxAmount, currency),
              }),
            );
        }),
    })
    .required();
};

export const AddMediaValidationSchema = (t: TFunction) =>
  yup.object({
    url: yup
      .string()
      .trim()
      .required(t('Cannot be empty'))
      .matches(
        /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?[\w\?=]*)?/,
        t('Invalid YouTube link'),
      ),
  });

export const AddSponsorValidationSchema = (t: TFunction) =>
  yup.object({
    sponsorName: yup
      .string()
      .trim()
      .typeError(t('Must be a string'))
      .required(t('Cannot be empty'))
      .max(64, t('Must not be longer than', { value: 64 })),
    amount: yup
      .number()
      .typeError(t('Must be a number'))
      .required(t('Cannot be empty'))
      .min(1, t('Must be greater than', { value: 1 })),
  });

export const AddDonationThresholdValidationSchema = (
  t: TFunction,
  existingThresholds: ThresholdRecord[],
) =>
  yup.object({
    threshold: yup
      .number()
      .typeError(t('Must be a number'))
      .required(t('Cannot be empty'))
      .positive(t('Amount must be positive'))
      .test('unique-amounts', t('Must be unique'), function (value) {
        if (
          existingThresholds.some(
            ({ threshold }) => parseInt(threshold.toString(), 10) === value,
          )
        ) {
          return this.createError({
            path: 'threshold',
            message: t('Must be unique'),
          });
        }
        return true;
      }),
    picture: yup
      .mixed()
      .required(t('GIF is required'))
      .test('fileType', t('Only GIF images are allowed'), (value) => {
        return (
          value && (value.type === 'image/gif' || value.type === 'image/webp')
        );
      })
      .test('fileSize', t('Image must be less than 2MB'), (value) => {
        return value && value.size <= 2 * 1024 * 1024; // 2MB
      }),
    sound: yup
      .mixed()
      .required(t('Audio file is required'))
      .test('fileType', t('Only MP3 or WAV files are allowed'), (value) => {
        return (
          value && (value.type === 'audio/mpeg' || value.type === 'audio/wav')
        );
      })
      .test('fileSize', t('Audio must be less than 5MB'), (value) => {
        return value && value.size <= 5 * 1024 * 1024; // 5MB
      }),
  });

export const UpdateDonationThresholdValidationSchema = (
  t: TFunction,
  existingThresholds: ThresholdRecord[],
) =>
  yup.object({
    threshold: yup
      .number()
      .typeError(t('Must be a number'))
      .required(t('Cannot be empty'))
      .positive(t('Amount must be positive'))
      .test('unique-amounts', t('Must be unique'), function (value) {
        if (
          existingThresholds.some(
            ({ threshold }) => parseInt(threshold.toString(), 10) === value,
          )
        ) {
          return this.createError({
            path: 'threshold',
            message: t('Must be unique'),
          });
        }
        return true;
      }),
  });

export const ExampleDonateValidationSchema = (t: TFunction) => {
  return yup
    .object({
      message: yup
        .string()
        .trim()
        .typeError(t('Must be a string'))
        .max(256, t('Must not be longer than', { value: 256 })),
      sponsorName: yup.string().trim(),
      voice: yup.string(),
      amount: yup
        .number()
        .min(1, t('Must be greater than', { value: 1 }))
        .typeError(t('Must be a number'))
        .required(t('Cannot be empty')),
    })
    .required();
};

export const CardWithdrawValidationSchema = (t: TFunction) =>
  yup.object({
    card: yup
      .string()
      .trim()
      .required(t('Cannot be empty'))
      .test('cardLength', t('Must be exactly', { value: 16 }), (item = '') => {
        return item.replace(/ /g, '').toString().length === 16;
      }),
    amount: yup
      .number()
      .typeError(t('Must be a number'))
      .required(t('Cannot be empty'))
      .min(1, t('Must be at least', { value: 1 }))
      .test(
        'minimumAmount',
        t('Must be greater than', { value: 0 }),
        (item = 0) => {
          return item > 0;
        },
      ),
  });

export const IBANWithdrawValidationSchema = (t: TFunction) =>
  yup.object({
    iban: yup
      .string()
      .trim()
      .required(t('Cannot be empty'))
      .length(29, t('Must be exactly', { value: 29 })),
    amount: yup
      .number()
      .typeError(t('Must be a number'))
      .required(t('Cannot be empty'))
      .min(1, t('Must be at least', { value: 1 }))
      .test(
        'minimumAmount',
        t('Must be greater than', { value: 0 }),
        (item = 0) => {
          return item > 0;
        },
      ),
    okpo: yup.string().trim().required(t('Cannot be empty')),
    accountName: yup.string().trim().required(t('Cannot be empty')),
  });

export const WhitepayWithdrawValidationSchema = (t: TFunction) =>
  yup.object({
    amount: yup
      .number()
      .typeError(t('Must be a number'))
      .required(t('Cannot be empty'))
      .min(1, t('Must be at least', { value: 1 }))
      .test(
        'minimumAmount',
        t('Must be greater than', { value: 0 }),
        (item = 0) => {
          return item > 0;
        },
      ),
    wallet: yup.string().trim().required(t('Cannot be empty')),
  });

export const ChangeDonationAnimationFormatValidationSchema = yup
  .object({
    start: yup.string().required(),
    end: yup.string().required(),
    letters: yup.string().required(),
  })
  .required();

export const ChangeMinimumDonationAmountValidationSchema = (t: TFunction) =>
  yup.object().shape({
    minimumDonationAmount: yup.object().shape(
      Object.keys(Currency).reduce(
        (acc, currency) => ({
          ...acc,
          [currency]: yup
            .number()
            .typeError(t('Must be a number'))
            .required(t('Cannot be empty'))
            .min(
              currency === Currency.USDT ? 5 : 1,
              t('Must be at least', {
                value: currency === Currency.USDT ? 5 : 1,
              }),
            )
            .test(
              'minimumAmount',
              t('Must be greater than', { value: 0 }),
              (item = 0) => item > 0,
            ),
        }),
        {},
      ),
    ),
  });

yup.addMethod(yup.object, 'uniqueProperty', function (propertyName, message) {
  return this.test('unique', message, function (value) {
    if (!value || !value[propertyName]) {
      return true;
    }

    const { path } = this;
    const options = [...this.parent];
    const currentIndex = options.indexOf(value);

    const subOptions = options.slice(0, currentIndex);

    if (
      subOptions.some((option) => option[propertyName] === value[propertyName])
    ) {
      throw this.createError({
        path: `${path}.${propertyName}`,
        message,
      });
    }

    return true;
  });
});

export const ChangeSocialNetworksValidationSchema = (t: TFunction) =>
  yup.object().shape({
    socialNetworks: yup.array().of(
      yup.object().shape({
        url: yup
          .string()
          .trim()
          .required(t('Cannot be empty'))
          .url(t('The link is not valid')),
      }),
    ),
  });

export const ChangeContentCreatorNameValidationSchema = (t: TFunction) =>
  yup.object({
    contentCreatorName: yup
      .string()
      .trim()
      .typeError(t('Must be a string'))
      .required(t('Cannot be empty'))
      .min(3, t('Must be at least', { value: 3 }))
      .max(64, t('Must not be longer than', { value: 64 }))
      .matches(/^[a-zA-Z0-9]+$/, t('Only letters and numbers are allowed'))
      .test(
        'isUnique',
        t('Must be unique'),
        async (contentCreatorName = '') => {
          if (!contentCreatorName) {
            return false;
          }
          try {
            const isExist = await checkIsUserNameExist(contentCreatorName);
            return !isExist;
          } catch (err) {
            return false;
          }
        },
      ),
  });

export const ChangeContentCreatorDescriptionValidationSchema = (t: TFunction) =>
  yup.object({
    description: yup
      .string()
      .trim()
      .typeError(t('Must be a string'))
      .max(64, t('Must not be longer than', { value: 64 })),
  });

export const ChangeMinimumDonationVoiceOverValidationSchema = (t: TFunction) =>
  yup.object({
    minimumVoiceOverThreshold: yup
      .number()
      .typeError(t('Must be a number'))
      .required(t('Cannot be empty'))
      .test(
        'isValidThreshold',
        t('Threshold must be -1, 0, or a positive integer'),
        (value) =>
          value === -1 ||
          value === 0 ||
          (Number.isInteger(value) && (value as number) > 0),
      ),
  });

export const ChangeWhitepayWalletValidationSchema = (t: TFunction) =>
  yup.object({
    wallet: yup.string().trim().required(t('Cannot be empty')),
  });

export const ChangeWayforpayValidationSchema = (t: TFunction) =>
  yup.object({
    merchantAccount: yup.string().trim().required(t('Cannot be empty')),
    merchantSecretKey: yup.string().trim().required(t('Cannot be empty')),
  });

export const ChangeMonobankaValidationSchema = (t: TFunction) =>
  yup.object({
    token: yup.string().trim().required(t('Cannot be empty')),
    jarId: yup.string().trim().required(t('Cannot be empty')),
  });

export const AddInvoiceValidationSchema = (t: TFunction) =>
  yup.object({
    startDate: yup.date().required(t('Cannot be empty')),
    endDate: yup.date().required(t('Cannot be empty')),
  });

export const ChangeGoalsValidationSchema = (t: TFunction) =>
  yup.object().shape({
    goals: yup.array().of(
      yup.object().shape({
        label: yup.string().trim().required(t('Cannot be empty')),
        start: yup
          .number()
          .typeError(t('Must be a number'))
          .required(t('Cannot be empty'))
          .test(
            'is-smaller',
            t('Start must be smaller than Finish'),
            function (value) {
              const { finish } = this.parent;

              if (value !== 0 && !value) {
                return false;
              }

              return value < finish;
            },
          ),
        finish: yup
          .number()
          .typeError(t('Must be a number'))
          .required(t('Cannot be empty'))
          .test(
            'is-greater',
            t('Finish must be greater than Start'),
            function (value) {
              const { start } = this.parent;

              if (!value) {
                return false;
              }

              return value > start;
            },
          ),
      }),
    ),
  });
