import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from 'view/components/PageHeader';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import MuiLink from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import BackButton from 'view/components/BackButton';
import UserInfoRow from 'view/components/UserInfoRow';
import { StyledContainer } from './styled';
import useUser from 'hooks/user';
import CopyButton from 'view/components/CopyButton';
import {
  DONATE_PATH,
  LEADER_BOARD_PATH,
  LIVE_DONATE_PATH,
  LIVE_HISTORY_PATH,
  QR_CODE_DONATE_PATH,
} from 'view/routes';
import ViewOBSURL from 'view/pages/Widgets/components/ViewOBSURL';
import ViewQRCodeOBSURL from 'view/pages/Widgets/components/ViewQRCodeOBSURL';
import ChangeGoals from 'view/pages/Widgets/components/ChangeGoals';
import ViewTestDonation from 'view/pages/AccountSettings/components/ViewTestDonation';
import ViewLiveHistoryURL from 'view/pages/Widgets/components/ViewLiveHistoryURL';
import ViewLeaderBoardURL from 'view/pages/Widgets/components/ViewLeaderBoardURL';
import ChangeMediaSettings from 'view/pages/Widgets/components/ChangeMediaSettings';
import LeaderboardRoundedIcon from '@mui/icons-material/LeaderboardRounded';
import MusicVideoRoundedIcon from '@mui/icons-material/MusicVideoRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import OutlinedFlagRoundedIcon from '@mui/icons-material/OutlinedFlagRounded';
import QrCode2RoundedIcon from '@mui/icons-material/QrCode2Rounded';
import PlaylistPlayRoundedIcon from '@mui/icons-material/PlaylistPlayRounded';
import PersonPinRoundedIcon from '@mui/icons-material/PersonPinRounded';
import DataObjectRoundedIcon from '@mui/icons-material/DataObjectRounded';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import ViewPersonalPageURL from 'view/pages/Widgets/components/ViewPersonalPageURL';

export default function Widgets() {
  const { user, setUser, OBSUrl } = useUser();

  const [fieldToEdit, setFieldToEdit] = useState<string | null>(null);
  const { t } = useTranslation();

  const clearFieldToEdit = () => setFieldToEdit(null);

  const renderEditForm = (fieldToEdit: string) => {
    if (fieldToEdit === 'obsURL') {
      return <ViewOBSURL onCancel={clearFieldToEdit} />;
    }

    if (fieldToEdit === 'liveHistory') {
      return <ViewLiveHistoryURL onCancel={clearFieldToEdit} />;
    }

    if (fieldToEdit === 'donationLink') {
      return <ViewPersonalPageURL onCancel={clearFieldToEdit} />;
    }

    if (fieldToEdit === 'leaderBoard') {
      return <ViewLeaderBoardURL onCancel={clearFieldToEdit} />;
    }

    if (fieldToEdit === 'media') {
      return <ChangeMediaSettings onCancel={clearFieldToEdit} />;
    }

    if (fieldToEdit === 'qrCode') {
      return <ViewQRCodeOBSURL onCancel={clearFieldToEdit} />;
    }

    if (fieldToEdit === 'goal') {
      return (
        <ChangeGoals
          onCancel={clearFieldToEdit}
          onSaved={({ goals }) => {
            setUser({ ...user, goals });
          }}
        />
      );
    }

    if (fieldToEdit === 'viewTestDonation') {
      return <ViewTestDonation onCancel={clearFieldToEdit} />;
    }

    return null;
  };

  const infoRows = [
    {
      label: t('OBS link'),
      icon: <DataObjectRoundedIcon />,
      type: 'obsURL',
      value: (() => {
        const link = `${process.env.REACT_APP_UI_URL}/${OBSUrl}${LIVE_DONATE_PATH}`;
        return (
          <Stack direction="row" alignItems="center">
            <Box mr={2}>••••••••••••••</Box>

            <CopyButton value={link} />
          </Stack>
        );
      })(),
    },
    {
      label: t('My page'),
      icon: <PersonPinRoundedIcon />,
      type: 'donationLink',
      value: (() => {
        const link = `${process.env.REACT_APP_UI_URL}${DONATE_PATH}/${user.contentCreatorName}`;
        return (
          <Stack direction="row" alignItems="center">
            <Box mr={2}>
              <MuiLink
                href={link}
                underline="hover"
                target="_blank"
                rel="noopener noreferrer"
              >
                {link}
              </MuiLink>
              {'  '}
            </Box>

            <CopyButton value={link} />
          </Stack>
        );
      })(),
    },
    {
      label: t('Live history'),
      icon: <PlaylistPlayRoundedIcon />,
      type: 'liveHistory',
      value: (() => {
        const link = `${process.env.REACT_APP_UI_URL}/${OBSUrl}${LIVE_HISTORY_PATH}`;
        return (
          <Stack direction="row" alignItems="center">
            <Box mr={2}>••••••••••••••</Box>

            <CopyButton value={link} />
          </Stack>
        );
      })(),
    },
    {
      label: t('QR code widget for OBS'),
      icon: <QrCode2RoundedIcon />,
      type: 'qrCode',
      value: (() => {
        const link = `${process.env.REACT_APP_UI_URL}${QR_CODE_DONATE_PATH}/${user.contentCreatorName}`;
        return (
          <Stack direction="row" alignItems="center">
            <Box mr={2}>
              <MuiLink
                href={link}
                underline="hover"
                target="_blank"
                rel="noopener noreferrer"
              >
                {link}
              </MuiLink>
              {'  '}
            </Box>

            <CopyButton value={link} />
          </Stack>
        );
      })(),
    },
    {
      label: t('Goal widget for OBS'),
      icon: <OutlinedFlagRoundedIcon />,
      type: 'goal',
      value: t('Add a goal for accumulating donations'),
    },
    {
      label: t('Test donation'),
      icon: <ConstructionRoundedIcon />,
      type: 'viewTestDonation',
      value: t('Send a test donate'),
    },
    {
      label: t('YouTube video'),
      icon: <MusicVideoRoundedIcon />,
      type: 'media',
      value: t('Donations with videos from YouTube'),
    },
    {
      label: t('Leader board'),
      icon: <LeaderboardRoundedIcon />,
      type: 'leaderBoard',
      value: (() => {
        const link = `${process.env.REACT_APP_UI_URL}/${OBSUrl}${LEADER_BOARD_PATH}`;
        return (
          <Stack direction="row" alignItems="center">
            <Box mr={2}>••••••••••••••</Box>

            <CopyButton value={link} />
          </Stack>
        );
      })(),
    },
  ];

  return (
    <StyledContainer component="main">
      <PageHeader
        title={t('Widgets')}
        startAdornment={
          fieldToEdit && (
            <Box display={{ sm: 'block', md: 'none' }}>
              <BackButton onClick={() => setFieldToEdit('')} />
            </Box>
          )
        }
      />
      <Grid container flexWrap="wrap" spacing={4}>
        <Grid
          size={{ xs: 12, sm: 12, md: 4 }}
          display={{
            xs: fieldToEdit ? 'none' : 'unset',
            sm: fieldToEdit ? 'none' : 'unset',
            md: 'unset',
          }}
        >
          <Paper variant="outlined">
            <List>
              {infoRows.map(({ type, label, value = '', icon }, index) => {
                return (
                  <UserInfoRow
                    key={type}
                    icon={icon}
                    label={label}
                    value={value}
                    selected={fieldToEdit === type}
                    divider={infoRows.length !== index + 1}
                    onClick={() => setFieldToEdit(type)}
                  />
                );
              })}
            </List>
          </Paper>
        </Grid>
        {fieldToEdit && (
          <Grid size={{ xs: 12, sm: 12, md: 8 }}>
            <Paper variant="outlined" sx={{ p: { xs: 2, sm: 4 } }}>
              {renderEditForm(fieldToEdit)}
            </Paper>
          </Grid>
        )}
      </Grid>
    </StyledContainer>
  );
}
