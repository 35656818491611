import { useParams } from 'react-router';
import React, { useEffect, useState } from 'react';
import { getCipherIdFromCipher, getLeadersFromCipher } from 'services/api/user';
import Alert from 'view/components/Alert';
import { Currency } from 'services/api/types';
import { Donation } from 'types/donations';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { formatCurrency } from 'tools';
import { Leader } from 'types/statistics';
import Grid from '@mui/material/Grid2';
import { bronze, gold, gray } from 'view/theme/colors';
import BackgroundAnimation from 'view/components/BackgroundAnimation';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import useSocket from 'hooks/useSocket';

export default function LeaderBoardPage() {
  const { cipher } = useParams();
  const [error, setError] = useState('');
  const { t } = useTranslation();

  const [leadersData, setLeadersData] = useState<Array<Leader> | null>(null);
  const [cipherId, setCipherId] = useState<string | ''>('');
  const { socket } = useSocket(cipherId);

  useEffect(() => {
    const fetchUserData = async () => {
      if (cipher) {
        try {
          const config = await getCipherIdFromCipher(cipher);
          const data = await getLeadersFromCipher(cipher);

          setCipherId(config.cipherId);
          setLeadersData(data);
        } catch (err) {
          if (err instanceof Error) {
            setError(err.message);
          }
        }
      }
    };

    if (cipher) {
      fetchUserData();
    }
  }, [cipher]);

  useEffect(() => {
    if (!socket) {
      return;
    }

    function onDonation(donation: Donation) {
      handleDonation(donation);
    }

    socket.on('donation', onDonation);

    return () => {
      socket.off('donation', onDonation);
    };
  }, [socket, cipherId]);

  const handleDonation = (donation: Donation) => {
    setLeadersData((prevState) => {
      if (!prevState) {
        return [
          {
            sponsorName: donation.sponsorName,
            amount: donation.amount,
            count: 1,
          },
        ];
      }

      return prevState.map((item) => {
        if (item.sponsorName === donation.sponsorName) {
          return {
            ...item,
            amount: item.amount + donation.amount,
            count: item.count + 1,
          };
        }
        return item;
      });
    });
  };

  const [first, second, third] = leadersData || [];

  return (
    <Container
      maxWidth={false}
      sx={{
        backgroundColor: 'background.default',
        height: '100vh',
        width: '100vw',
        padding: '8rem 0 0 0',
      }}
    >
      <Alert open={!!error} type="error" label={error} />
      <Grid
        container
        justifyContent="center"
        position="relative"
        sx={{ transform: 'scale(1.25) translate(0, 10%)' }}
      >
        <BackgroundAnimation x={800} y={600} />
        <Grid size={{ xs: 12, sm: 12, md: 8, lg: 6, xl: 4 }}>
          <Typography variant="h1" textAlign="center" mb={4}>
            {t('Leader board')}
          </Typography>

          {leadersData ? (
            <Grid container spacing={1} mb={4}>
              <Grid
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                size={4}
                mt={4}
                pt={1}
                sx={{
                  borderRadius: '0.5rem 0.5rem 0 0',
                  border: `1px solid ${gray}`,
                  borderBottom: `5px solid ${gray}`,
                }}
              >
                <Typography variant="h3">
                  🥈 {second.sponsorName}
                  <br />
                  {formatCurrency(second.amount, Currency.UAH)}
                </Typography>
              </Grid>
              <Grid
                size={4}
                textAlign="center"
                pt={1}
                sx={{
                  border: `1px solid ${gold}`,
                  borderRadius: '0.5rem 0.5rem 0 0',
                  borderBottom: `5px solid ${gold}`,
                }}
              >
                <Typography variant="h2">
                  🥇 {first.sponsorName}
                  <br />
                  {formatCurrency(first.amount, Currency.UAH)}
                </Typography>
              </Grid>
              <Grid
                size={4}
                mt={6}
                pt={1}
                pb={2}
                textAlign="center"
                sx={{
                  border: `1px solid ${bronze}`,
                  borderRadius: '0.5rem 0.5rem 0 0',
                  borderBottom: `5px solid ${bronze}`,
                }}
              >
                <Typography variant="h3">
                  🥉 {third.sponsorName} <br />
                  {formatCurrency(third.amount, Currency.UAH)}
                </Typography>
              </Grid>
            </Grid>
          ) : null}

          {leadersData ? (
            <TableContainer
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Table sx={{ maxWidth: 400 }}>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: 30 }} align="center">
                      {t('Rank')}
                    </TableCell>
                    <TableCell align="center">{t('Sponsor name')}</TableCell>
                    <TableCell align="center">{t('Amount')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {leadersData?.map((row, index) => {
                    const rank = index + 1;

                    return (
                      <TableRow key={row.sponsorName}>
                        <TableCell align="center">{rank}</TableCell>
                        <TableCell align="center">{row.sponsorName}</TableCell>
                        <TableCell align="center">
                          {formatCurrency(row.amount, Currency.UAH)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Typography variant="body2">{t('No data')}</Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}
