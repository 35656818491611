import useTheme from 'hooks/theme';
import { black20, black71, primary } from 'view/theme/colors';
import Stack from '@mui/material/Stack';
import Button from 'view/components/Button';
import PicturePreview from 'view/components/PicturePreview';
import AudioPreview from 'view/components/AudioPreview';
import React from 'react';

type AmountBoxProps = {
  amount: number;
  isCurrent: boolean;
  pictureUrl: string;
  soundUrl: string;
  onClick: () => void;
};

function AmountBox({
  amount,
  isCurrent,
  pictureUrl,
  soundUrl,
  onClick,
}: AmountBoxProps) {
  const { isDarkMode } = useTheme();

  const borderColor = isDarkMode ? black71 : black20;

  return (
    <Stack
      direction="row"
      alignItems="center"
      border={`1px solid ${isCurrent ? primary : borderColor}`}
      borderRadius={3}
      gap={0.25}
    >
      <Button
        label={(Math.round(amount * 100) / 100).toString()}
        onClick={onClick}
        variant="text"
        color="primary"
      />
      <PicturePreview pictureUrl={pictureUrl} />
      <AudioPreview soundUrl={soundUrl} title="Notification sound" />
    </Stack>
  );
}

export default AmountBox;
