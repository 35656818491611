import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddDonationThresholdValidationSchema } from 'constraints';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Dialog from 'view/components/Dialog';
import Button from 'view/components/Button';
import Input from 'view/components/Form/Input';
import Typography from '@mui/material/Typography';
import { addTransferredDonation } from 'services/api/donations';
import AudioFileRoundedIcon from '@mui/icons-material/AudioFileRounded';
import ImageRoundedIcon from '@mui/icons-material/ImageRounded';
import useAuth from 'hooks/auth';
import FormFileUpload from 'view/components/Form/FileUpload';
import PicturePreview from 'view/components/PicturePreview';
import AudioPreview from 'view/components/AudioPreview';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import IconButton from '@mui/material/IconButton';
import { addDonationThreshold } from 'services/api/user';
import useUser from 'hooks/user';
import { Currency, CurrencyLabelMap } from 'services/api/types';

export type AddDonationThresholdDialogFormData = {
  threshold: number;
  picture: File | null;
  sound: File | null;
};

type AddTransferredDonationDialogProps = {
  open: boolean;
  onClose: () => void;
};

export default function AddDonationThresholdDialog({
  open,
  onClose,
}: AddTransferredDonationDialogProps) {
  const { setErrorMessage, setSuccessMessage } = useAuth();
  const { user, setUser } = useUser();
  const { t } = useTranslation();

  const initialFormData = {
    resolver: yupResolver(
      AddDonationThresholdValidationSchema(t, user.donationThresholdConfig),
    ),
    defaultValues: {
      threshold:
        Math.max(
          ...user.donationThresholdConfig.map((config) => config.threshold),
        ) + 10,
      picture: null,
      sound: null,
    },
  };

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<AddDonationThresholdDialogFormData>(initialFormData);
  const picture = watch('picture');
  const sound = watch('sound');
  const threshold = watch('threshold');

  const onSubmit = async (data: AddDonationThresholdDialogFormData) => {
    if (!data || !data.picture || !data.sound) {
      setErrorMessage(t('Error while adding threshold'));
      return;
    }

    const formData = new FormData();
    formData.append('threshold', data.threshold.toString());
    formData.append('picture', data.picture);
    formData.append('sound', data.sound);

    try {
      const { pictureUrl, soundUrl, _id } = await addDonationThreshold(
        formData,
      );
      setUser({
        ...user,
        donationThresholdConfig: [
          ...user.donationThresholdConfig,
          { _id, threshold, soundUrl, pictureUrl },
        ],
      });
      onClose();
      setSuccessMessage(t('Successfully added a threshold'));
    } catch (err) {
      setErrorMessage(t('Error while adding threshold'));
    }
  };

  const Content = (
    <form>
      <Box display="flex" alignItems="center" gap={4} mb={2}>
        <Box width="8rem">
          <Input
            name="threshold"
            label={t('Threshold')}
            type="number"
            error={!!errors.threshold}
            helperText={errors.threshold?.message}
            control={control}
            startAdornment={<>{CurrencyLabelMap[Currency.UAH]}</>}
            endAdornment={
              <IconButton
                onClick={() => setValue('threshold', threshold + 5)}
                edge="end"
              >
                <AddRoundedIcon />
              </IconButton>
            }
          />
        </Box>

        <Box display="flex" alignItems="center" gap={1}>
          {picture && (
            <PicturePreview pictureUrl={URL.createObjectURL(picture)} />
          )}
          <FormFileUpload
            error={!!errors.picture}
            helperText={errors.picture?.message}
            icon={<ImageRoundedIcon />}
            control={control}
            id="picture"
            name="picture"
            accept="image/gif, image/webp"
          />
        </Box>

        <Box display="flex" alignItems="center" gap={1}>
          {sound && (
            <AudioPreview
              soundUrl={URL.createObjectURL(sound)}
              title="Notification sound"
            />
          )}
          <FormFileUpload
            error={!!errors.sound}
            helperText={errors.sound?.message}
            icon={<AudioFileRoundedIcon />}
            control={control}
            id="sound"
            name="sound"
            accept="audio/mpeg, audio/wav"
          />
        </Box>
      </Box>
    </form>
  );

  const Actions = (
    <>
      <Button
        label={t('Cancel')}
        variant="text"
        color="secondary"
        disabled={isSubmitting}
        onClick={onClose}
      />
      <Button
        label={t('Save')}
        withLoader
        loading={isSubmitting}
        disabled={!isDirty || isSubmitting}
        onClick={() => handleSubmit(onSubmit)()}
      />
    </>
  );

  return (
    <Dialog
      open={open}
      title={t('Add a new threshold')}
      content={Content}
      onClose={onClose}
      actions={Actions}
    />
  );
}
