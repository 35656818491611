import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from 'view/components/Dialog';
import Button from 'view/components/Button';
import Typography from '@mui/material/Typography';
import { Currency } from 'services/api/types';
import {
  deleteTransferredDonation,
  getTransferredDonations,
} from 'services/api/donations';
import useAuth from 'hooks/auth';
import { Donation } from 'types/donations';
import IconButton from '@mui/material/IconButton';
import TrashIcon from 'view/components/icons/Trash';
import { red } from 'view/theme/colors';
import Tooltip from '@mui/material/Tooltip';
import { formatCurrency } from 'tools';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';

type RemoveTransferredDonationDialogProps = {
  open: boolean;
  onClose: () => void;
};

export default function RemoveTransferredDonationDialog({
  open,
  onClose,
}: RemoveTransferredDonationDialogProps) {
  const { setErrorMessage, setSuccessMessage } = useAuth();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [sponsorsData, setSponsorsData] = useState<Array<Donation> | null>(
    null,
  );

  useEffect(() => {
    const loadDonations = async () => {
      try {
        setIsLoading(true);
        const data = await getTransferredDonations();
        setSponsorsData(data);
      } catch (err) {
        setErrorMessage(t('Error while loading donations'));
      } finally {
        setIsLoading(false);
      }
    };

    loadDonations();
  }, []);

  const onDeleteClick = async (id: string) => {
    try {
      setIsLoading(true);
      await deleteTransferredDonation(id);

      if (sponsorsData?.length) {
        const filteredSponsors = sponsorsData?.filter(
          (item) => item._id !== id,
        );
        setSponsorsData(filteredSponsors);
      }

      setSuccessMessage(t('Successfully deleted sponsor data'));
    } catch (err) {
      setErrorMessage(t('Error while deleting sponsor data'));
    } finally {
      setIsLoading(false);
    }
  };

  const hasData = !!(sponsorsData && sponsorsData.length);

  const Content = (
    <>
      {hasData ? (
        <>
          <Typography variant="body2" mb={2}>
            {t('Only those you have added are displayed')}
          </Typography>
          <TableContainer
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{t('Sponsor name')}</TableCell>
                  <TableCell>{t('Amount')}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {sponsorsData?.map((row, index) => {
                  return (
                    <TableRow key={row._id}>
                      <TableCell>{row.sponsorName}</TableCell>
                      <TableCell>
                        {formatCurrency(row.amount, Currency.UAH)}
                      </TableCell>
                      <TableCell align="right" sx={{ width: 50 }}>
                        <Tooltip arrow title={t('Remove')}>
                          <IconButton
                            color="error"
                            onClick={() => onDeleteClick(row._id)}
                          >
                            <TrashIcon color={red} />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <Typography variant="body2" mb={2}>
          {t('No data')}
        </Typography>
      )}
    </>
  );

  const Actions = (
    <>
      <Button
        label={t('Back')}
        variant="text"
        color="secondary"
        withLoader
        loading={isLoading}
        disabled={isLoading}
        onClick={onClose}
      />
    </>
  );

  return (
    <Dialog
      open={open}
      title={t('Remove a sponsor')}
      content={Content}
      onClose={onClose}
      actions={Actions}
    />
  );
}
