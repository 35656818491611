import React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import ActionMenu, { MenuItemType } from 'view/components/ActionMenu';
import Loader from 'view/components/Loader';
import TablePagination from 'view/components/Table/components/TablePagination';
import TableHeader, {
  Order,
} from 'view/components/Table/components/TableHeader';
import { headCells } from './helpers';
import { PaginatedResponse } from 'services/api/types';
import TrashIcon from 'view/components/icons/Trash';
import { Withdrawal } from 'types/withdrawal';
import { formatCurrency } from 'tools';
import format from 'date-fns/format';
import CopyButton from 'view/components/CopyButton';
import Chip from '@mui/material/Chip';
import { Invoice } from 'types/invoices';
import { getInvoiceChip } from 'view/pages/Billing/components/InvoicesTable';
import { useTranslation } from 'react-i18next';

type InvoicesTableProps = {
  setSorting: (sorting: [string, Order]) => void;
  onRemoveInvoice: (invoice: Invoice) => void;
  onPageChange: (_event: unknown, newPage: number) => void;
  loading: boolean;
  data: PaginatedResponse<Invoice>;
  page: number;
  sorting: [string, Order];
};

export default function InvoicesTable({
  loading,
  data,
  page,
  sorting,
  onPageChange,
  setSorting,
  onRemoveInvoice,
}: InvoicesTableProps) {
  const { t } = useTranslation();
  const rowsPerPage = 8;
  const orderBy = sorting[0];
  const order = sorting[1];

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof Withdrawal,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setSorting([property, isAsc ? 'desc' : 'asc']);
  };

  if (!data.items.length) {
    return <Typography variant="body2">No invoices found.</Typography>;
  }

  return (
    <Paper sx={{ boxShadow: 'none', background: 'none' }}>
      <TableContainer>
        <Table aria-labelledby="tableTitle">
          <TableHeader
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={data.meta.totalItems}
          />
          {loading ? (
            <TableRow
              sx={{
                'th,td': { borderBottom: 'none' },
                height: 73 * rowsPerPage,
              }}
            >
              <TableCell colSpan={6}>
                <Loader />
              </TableCell>
            </TableRow>
          ) : (
            <TableBody>
              {data.items.map((row: Invoice, index: number) => {
                const menuItems: MenuItemType[] = [
                  {
                    label: 'Remove',
                    icon: <TrashIcon />,
                    onClick: () => onRemoveInvoice(row),
                    className: 'error',
                  },
                ];
                const chip = getInvoiceChip(row?.isPaid);
                return (
                  <TableRow
                    sx={
                      index + 1 === data.items.length
                        ? {
                            'th,td': { borderBottom: 'none' },
                          }
                        : undefined
                    }
                    role="checkbox"
                    tabIndex={-1}
                    key={row._id}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      padding="none"
                      sx={{ padding: '1rem 0' }}
                    >
                      {format(new Date(row.startDate), 'd MMM')} -{' '}
                      {format(new Date(row.endDate), 'd MMM, yyyy')}
                    </TableCell>
                    <TableCell align="left">
                      <Chip
                        variant="outlined"
                        label={formatCurrency(row.amount, row.currency)}
                      />
                    </TableCell>
                    <TableCell>
                      <Chip
                        color={chip.color}
                        icon={chip.icon}
                        variant="filled"
                        label={t(chip.label)}
                      />
                    </TableCell>
                    <TableCell>
                      <Box display="flex" sx={{ alignItems: 'center' }}>
                        <Box display="flex" alignItems="center" gap={1}>
                          {row._id}
                          <CopyButton value={row._id} />
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell align="right" sx={{ paddingRight: 0 }}>
                      <ActionMenu
                        small
                        buttonIcon={<MoreVertRoundedIcon />}
                        menuItems={menuItems}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        page={page}
        totalItems={data.meta.totalItems}
        totalPages={data.meta.totalPages}
        onPageChange={onPageChange}
        rowsPerPage={data.meta.itemsPerPage}
      />
    </Paper>
  );
}
