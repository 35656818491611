import { useParams } from 'react-router';
import React, { useEffect, useState } from 'react';
import {
  getCipherIdFromCipher,
  getDonationConfigFromCipher,
  getDonationsFromCipher,
} from 'services/api/user';
import Alert from 'view/components/Alert';
import { PaginatedResponse } from 'services/api/types';
import { Donation } from 'types/donations';
import Box from '@mui/material/Box';
import Loader from 'view/components/Loader';
import Typography from '@mui/material/Typography';
import InfiniteScroll from 'react-infinite-scroll-component';
import DonationCard from 'view/pages/LiveHistory/components/DonationCard';
import { useTranslation } from 'react-i18next';
import Button from 'view/components/Button';
import { groupItemsByDate, sortDonationsByDate } from 'tools';
import { useDateFormatter } from 'hooks/dateFormatter';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseIcon from '@mui/icons-material/Close';
import { DonationConfig } from 'view/pages/DonationAlert';
import useSocket from 'hooks/useSocket';

export default function LiveHistory() {
  const { cipher } = useParams();
  const [error, setError] = useState('');

  const { t } = useTranslation();
  const formatDate = useDateFormatter();

  const [donationsData, setDonationsData] =
    useState<PaginatedResponse<Donation> | null>(null);
  const [donationConfig, setDonationConfig] = useState<DonationConfig | null>(
    null,
  );
  const [cipherId, setCipherId] = useState<string>('');
  const { socket } = useSocket(cipherId);

  useEffect(() => {
    const fetchUserData = async () => {
      if (cipher) {
        try {
          const cipherData = await getCipherIdFromCipher(cipher);
          const config = await getDonationConfigFromCipher(cipher);
          const donates = await getDonationsFromCipher(cipher);

          setCipherId(cipherData.cipherId);
          setDonationConfig(config);
          setDonationsData(donates);
        } catch (err) {
          if (err instanceof Error) {
            setError(err.message);
          }
        }
      }
    };

    if (cipher) {
      fetchUserData();
    }
  }, [cipher]);

  useEffect(() => {
    if (!socket) {
      return;
    }

    function onDonation(donation: Donation) {
      handleDonation(donation);
    }

    socket.on('donation', onDonation);

    return () => {
      socket.off('donation', onDonation);
    };
  }, [socket, cipherId]);

  const fetchNextDonations = async (nextLink?: string) => {
    if (nextLink && cipher) {
      try {
        const data = await getDonationsFromCipher(cipher, nextLink);
        setDonationsData((prevState) => {
          if (!prevState) {
            return prevState;
          }

          return {
            ...prevState,
            items: [...data.items, ...prevState.items],
            links: { ...prevState.links, ...data.links },
            meta: { ...prevState.meta, ...data.meta },
          };
        });
      } catch (err) {
        setError(t('Error while loading donations'));
      }
    }
  };

  const handleDonation = (donation: Donation) => {
    setDonationsData((prevState) => {
      if (!prevState) {
        return prevState;
      }

      return {
        ...prevState,
        items: [donation, ...prevState.items],
        meta: {
          ...prevState.meta,
          totalItems: prevState.meta.totalItems + 1,
          itemCount: prevState.meta.itemCount + 1,
        },
      };
    });
  };

  const onStopClick = () => {
    if (socket) {
      socket.emit('stop-donation-queue');
    }
  };

  const onStopMediaClick = () => {
    if (socket) {
      socket.emit('stop-media-queue');
    }
  };

  const onReloadClick = () => {
    window.location.reload();
  };

  const onPlayClick = (donation: Donation) => {
    if (socket) {
      socket.emit('repeat-donation', { donation });
    }
  };

  const handleSummaryName = (date: string) => {
    if (['today', 'yesterday'].includes(date)) {
      return t(date);
    }
    return formatDate(new Date(date), 'd MMMM');
  };

  const donationsGroupedByDate = groupItemsByDate(donationsData?.items || []);

  if (error) {
    return <Alert open={!!error} type="error" label={error} />;
  }

  return (
    <Box>
      <Box mb={2} display="flex" gap={0.25}>
        <Box width="30%">
          <Button
            sx={{ borderRadius: 0 }}
            label={t('Refresh')}
            onClick={onReloadClick}
            fullWidth
            startIcon={<RefreshIcon />}
            color="secondary"
          />
        </Box>

        {donationConfig?.allowMedia && (
          <Button
            sx={{ borderRadius: 0 }}
            label={t('Stop media')}
            color="secondary"
            startIcon={<CloseIcon />}
            onClick={onStopMediaClick}
            fullWidth
          />
        )}

        <Button
          sx={{ borderRadius: 0 }}
          label={t('Stop queue')}
          startIcon={<CloseIcon />}
          onClick={onStopClick}
          fullWidth
        />
      </Box>
      <InfiniteScroll
        dataLength={donationsData?.items.length || 0}
        next={() => fetchNextDonations(donationsData?.links?.next)}
        hasMore={!!donationsData?.links?.next}
        loader={
          <Box my={2}>
            <Loader />
          </Box>
        }
        style={{ overflow: 'visible' }}
      >
        {!donationsData?.items?.length ? (
          <Box display="flex" justifyContent="center">
            <Typography variant="body2">{t('No donations yet.')}</Typography>
          </Box>
        ) : (
          <Box>
            {sortDonationsByDate(Object.keys(donationsGroupedByDate))
              .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
              .map((dateName: string) => (
                <Box mb={2} key={dateName}>
                  <Typography variant="subtitle1" px={2} mb={0.5}>
                    {handleSummaryName(dateName)}
                  </Typography>
                  {donationsGroupedByDate[dateName]
                    .sort(
                      (a, b) =>
                        new Date(b.createdAt).getTime() -
                        new Date(a.createdAt).getTime(),
                    )
                    .map((donation) => (
                      <DonationCard
                        key={donation._id}
                        donation={donation}
                        onClick={() => onPlayClick(donation)}
                      />
                    ))}
                </Box>
              ))}
          </Box>
        )}
      </InfiniteScroll>
    </Box>
  );
}
