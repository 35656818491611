import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ChangeMonobankaValidationSchema } from 'constraints';
import { useTranslation } from 'react-i18next';
import useUser from 'hooks/user';
import Box from '@mui/material/Box';
import PasswordInput from 'view/components/Form/PasswordInput';
import MuiLink from '@mui/material/Link';
import Dialog from 'view/components/Dialog';
import Button from 'view/components/Button';
import { getMonobankClientInfo, setMonobankWebhook } from 'services/api/user';
import useAuth from 'hooks/auth';
import debounce from 'lodash.debounce';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import { formatCurrency, isMonobanka } from 'tools';
import { Currency } from 'services/api/types';
import Skeleton from '@mui/material/Skeleton';
import RefreshIcon from '@mui/icons-material/Refresh';

export type MonobankaFormData = {
  token: string;
  jarId: string;
};

type MonobankaDialogProps = {
  open: boolean;
  onSaved: (data: MonobankaFormData) => void;
  onClose: () => void;
};

export type MonobankClientInfo = {
  name: string;
  webHookUrl: string;
  jars: Array<{
    balance: number;
    currencyCode: number;
    description: string;
    goal: number;
    id: string;
    sendId: string;
    title: string;
  }>;
};

export default function MonobankaDialog({
  open,
  onSaved,
  onClose,
}: MonobankaDialogProps) {
  const { setErrorMessage } = useAuth();
  const { user } = useUser();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [clientInfo, setClientInfo] = useState<MonobankClientInfo | null>(null);

  const data = user.paymentMethods.find(isMonobanka);

  const tokenData = data?.token;

  const initialFormData = {
    resolver: yupResolver(ChangeMonobankaValidationSchema(t)),
    defaultValues: {
      token: tokenData || '',
      jarId: data?.jarId || '',
    },
  };

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<MonobankaFormData>(initialFormData);
  const token = watch('token');
  const jarId = watch('jarId');

  const getClientInfo = useCallback(
    debounce(async (token: string) => {
      setLoading(true);

      try {
        const response = await getMonobankClientInfo(token);
        if (!data?.jarId) {
          const [jar] = response.jars;
          setValue('jarId', jar.id);
        }

        setClientInfo(response);
        setLoading(false);
      } catch (err) {
        setErrorMessage(t('Error while loading data'));
      } finally {
        setLoading(false);
      }
    }, 500),
    [],
  );

  useEffect(() => {
    if (token) {
      getClientInfo(token);
    }
  }, [token, getClientInfo]);

  const onSubmit = async (data: MonobankaFormData) => {
    setLoading(true);
    const jar = clientInfo?.jars.find((jar) => jar.id === data.jarId);
    const completeData = { ...data, jarTitle: jar?.title, sendId: jar?.sendId };

    try {
      await setMonobankWebhook(data.token);
    } catch (err) {
      setErrorMessage(t('Error while saving data'));
      return;
    } finally {
      setLoading(false);
    }
    onSaved(completeData);
  };

  const onRefreshClick = () => {
    setClientInfo(null);
    setLoading(true);
    getClientInfo(token);
  };

  const handleJarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setValue('jarId', value);
  };

  const Content = (
    <>
      <form>
        <Box mb={3} display="flex" alignItems="center" gap={0.5}>
          <OpenInNewRoundedIcon fontSize="small" color="info" />

          <MuiLink
            color="info"
            href="https://api.monobank.ua/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('Monobank token')}
          </MuiLink>
        </Box>

        <Box mb={2}>
          <PasswordInput
            name="token"
            label={t('Token')}
            disabled={loading}
            error={!!errors.token?.message}
            helperText={errors.token?.message}
            control={control}
          />
        </Box>
        {!clientInfo && loading && (
          <>
            <Skeleton
              animation="wave"
              variant="rounded"
              width="100%"
              height={10}
              sx={{ mb: 0.5 }}
            />
            <Skeleton
              animation="wave"
              variant="rounded"
              width="100%"
              height={40}
              sx={{ mb: 0.5 }}
            />
          </>
        )}
        {clientInfo && (
          <Box>
            <Typography variant="body2" mb={1}>
              {t('Choose a jar for', { name: clientInfo.name })}
            </Typography>
            {clientInfo.jars.length
              ? clientInfo.jars.map((jar, index) => {
                  return (
                    <RadioGroup
                      sx={{ flexDirection: 'column', gap: 1 }}
                      aria-label="jarId"
                      name="jarId"
                      value={jarId}
                      onChange={handleJarChange}
                    >
                      <FormControlLabel
                        key={jar.id}
                        value={jar.id}
                        control={
                          <Radio inputProps={{ 'aria-label': 'controlled' }} />
                        }
                        label={
                          <Typography mr={1.5}>
                            {jar.title} |{' '}
                            {t('AccumulatedOption', {
                              accumulated: formatCurrency(
                                jar.balance / 100,
                                Currency.UAH,
                              ),
                              finish: formatCurrency(
                                jar.goal / 100,
                                Currency.UAH,
                              ),
                            })}
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  );
                })
              : t('No data')}
          </Box>
        )}
      </form>
    </>
  );

  const Actions = (
    <>
      <Button
        label={t('Refresh')}
        onClick={onRefreshClick}
        startIcon={<RefreshIcon />}
        disabled={loading || isSubmitting}
        variant="text"
      />
      <Button
        label={t('Cancel')}
        variant="text"
        color="secondary"
        disabled={loading || isSubmitting}
        onClick={onClose}
      />
      <Button
        label={t('Save')}
        withLoader
        loading={isSubmitting}
        disabled={loading || !isDirty || !jarId || isSubmitting}
        onClick={() => handleSubmit(onSubmit)()}
      />
    </>
  );

  return (
    <Dialog
      open={open}
      title={t('Enter monobank token')}
      content={Content}
      onClose={onClose}
      actions={Actions}
    />
  );
}
