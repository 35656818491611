import Divider from '@mui/material/Divider';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { MouseEventHandler, ReactElement } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { lightPrimary } from 'view/theme/colors';

type UserInfoRowProps = {
  value: string | ReactElement | null;
  label: string | ReactElement;
  divider: boolean;
  selected?: boolean;
  icon: ReactElement;
  notEditable?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export default function UserInfoRow({
  label,
  icon,
  value,
  selected,
  notEditable,
  divider = false,
  onClick,
}: UserInfoRowProps) {
  return (
    <>
      <ListItem
        disablePadding
        sx={{ background: selected ? lightPrimary : 'unset' }}
      >
        <ListItemButton onClick={onClick}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={label} />
          {!notEditable && <KeyboardArrowRightRoundedIcon />}
        </ListItemButton>
      </ListItem>
      {divider && <Divider />}
    </>
  );
}
