import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Donation } from 'types/donations';
import { censorText, formatCurrency } from 'tools';
import { StyledDonationCard, StyledDonationMessageBar } from './styled';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import { DEFAULT_VOICE_NAME, VOICES_CONFIG } from 'view/constants';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import VoiceMagicIcon from 'view/components/icons/VoiceMagic';
import OndemandVideoRoundedIcon from '@mui/icons-material/OndemandVideoRounded';
import IconButton from '@mui/material/IconButton';
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';
import HistoryToggleOffRoundedIcon from '@mui/icons-material/HistoryToggleOffRounded';
import { PaymentMethodTypes } from 'types/users';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MuiLink from '@mui/material/Link';
import useUser from 'hooks/user';

type DonationCardProps = {
  donation: Donation;
  onClick: () => void;
};

export default function DonationCard({ donation, onClick }: DonationCardProps) {
  const { user } = useUser();
  const [elevated, setElevated] = useState(false);
  const [isLinkDialogOpen, setIsLinkDialogOpen] = useState(false);

  const handleTooltipClose = () => {
    setIsLinkDialogOpen(false);
  };

  const handleTooltipOpen = () => {
    setIsLinkDialogOpen(true);
  };
  const handleMouseEnter = !elevated ? () => setElevated(true) : undefined;
  const handleMouseLeave = () => setElevated(false);
  const link = `https://www.youtube.com/watch?v=${donation.youtubeId}`;

  const { t } = useTranslation();

  const userName = donation.sponsorName ? donation.sponsorName : 'Анонім';
  const filteredUserName = user.messageCensorship
    ? censorText(userName)
    : userName;

  return (
    <StyledDonationCard
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <StyledDonationMessageBar>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body1">
            {filteredUserName} -{' '}
            {formatCurrency(donation.amount, donation.currency)}
          </Typography>
          <Box display="flex" alignItems="center" gap={2}>
            {donation?.youtubeId && (
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                  <Tooltip
                    onClose={handleTooltipClose}
                    open={isLinkDialogOpen}
                    arrow
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                      <MuiLink
                        href={link}
                        underline="hover"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {link}
                      </MuiLink>
                    }
                    slotProps={{
                      popper: {
                        disablePortal: true,
                      },
                    }}
                  >
                    <IconButton size="small" onClick={handleTooltipOpen}>
                      <OndemandVideoRoundedIcon sx={{ fontSize: 18 }} />
                    </IconButton>
                  </Tooltip>
                </div>
              </ClickAwayListener>
            )}
            {donation.voice !== DEFAULT_VOICE_NAME && (
              <Tooltip title={t(VOICES_CONFIG[donation.voice].label)}>
                <Box display="flex" alignItems="center">
                  <VoiceMagicIcon fontSize={18} />
                </Box>
              </Tooltip>
            )}
            {donation.paymentMethod === ('default' as PaymentMethodTypes) && (
              <Tooltip title={t('Added manually')}>
                <Box display="flex" alignItems="center">
                  <HistoryToggleOffRoundedIcon sx={{ fontSize: 18 }} />
                </Box>
              </Tooltip>
            )}

            <Typography variant="body2" mr={2}>
              {format(new Date(donation.createdAt), 'HH:mm')}
            </Typography>
          </Box>
        </Box>
        {donation.message && (
          <Typography variant="body2">
            {user.messageCensorship
              ? censorText(donation.message)
              : donation.message}
          </Typography>
        )}
      </StyledDonationMessageBar>
      {elevated && (
        <Tooltip title={t('Repeat')}>
          <IconButton onClick={onClick}>
            <ReplayRoundedIcon />
          </IconButton>
        </Tooltip>
      )}
    </StyledDonationCard>
  );
}
