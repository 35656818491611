import React, { useState } from 'react';
import Input from 'view/components/Input';
import { useTranslation } from 'react-i18next';
import useUser from 'hooks/user';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import EyeOffIcon from 'view/components/icons/EyeOff';
import EyeIcon from 'view/components/icons/Eye';
import { isWayforpay } from 'tools';
import Typography from '@mui/material/Typography';
import { PAYMENT_METHOD_MEDIA_MAP } from 'view/pages/AccountSettings/components/ChangePaymentMethods/index';
import { PaymentMethodTypes } from 'types/users';

export default function WayforpayDataRow() {
  const { user } = useUser();
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const data = user.paymentMethods.find(isWayforpay);

  if (!data) {
    return null;
  }

  return (
    <Box>
      <Box display="flex" alignItems="center" gap={2} mb={3}>
        <Typography>
          {PAYMENT_METHOD_MEDIA_MAP[PaymentMethodTypes.WAYFORPAY].shortLabel}
        </Typography>
        {PAYMENT_METHOD_MEDIA_MAP[PaymentMethodTypes.WAYFORPAY].icon}
      </Box>
      <Box display="flex" alignItems="center" gap={2} flexWrap="wrap">
        <Box width="12rem">
          <Input
            label={t('Merchant account')}
            disabled
            type="text"
            value={data?.merchantAccount}
          />
        </Box>
        <Box width="12rem">
          <Input
            label={t('Merchant secret key')}
            disabled
            type={showPassword ? 'text' : 'password'}
            value={data?.merchantSecretKey}
            endAdornment={
              <IconButton
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <EyeOffIcon /> : <EyeIcon />}
              </IconButton>
            }
          />
        </Box>
      </Box>
    </Box>
  );
}
