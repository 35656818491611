import React, { useEffect, useState } from 'react';
import PageHeader from 'view/components/PageHeader';
import { StyledContainer } from './styled';
import Typography from '@mui/material/Typography';
import useUser from 'hooks/user';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import PremiumPlan from 'view/components/SubscriptionPlans/components/PremiumPlan';
import Divider from '@mui/material/Divider';
import InvoiceDetailsTable from 'view/pages/Billing/components/InvoiceDetailsTable';
import useAuth from 'hooks/auth';
import { getUnbilledInvoice, listInvoices } from 'services/api/invoices';
import { InvoiceDetails } from 'types/invoices';
import InvoicesTable from 'view/pages/Billing/components/InvoicesTable';
import Button from 'view/components/Button';
import Skeleton from '@mui/material/Skeleton';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import DownloadIcon from '@mui/icons-material/Download';
import Modal from '@mui/material/Modal';
import Link from 'view/components/Link';
import MuiLink from '@mui/material/Link';
import { AGREEMENT_HREF } from 'view/constants';
import Paper from '@mui/material/Paper';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  p: 4,
};

export default function BillingPage() {
  const { setErrorMessage } = useAuth();
  const { user, invoicesData, setInvoicesData } = useUser();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<InvoiceDetails | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeHandler = () => setIsModalOpen(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const bill = await getUnbilledInvoice();
        setData(bill);
      } catch (err) {
        setErrorMessage(t('Error while loading unbilled invoice'));
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, []);

  const fetchNextInvoices = async (nextLink?: string) => {
    if (nextLink) {
      try {
        const invoicesData = await listInvoices(nextLink);
        setInvoicesData(invoicesData);
      } catch (err) {
        setErrorMessage(t('Error while loading more invoices'));
      }
    }
  };

  return (
    <StyledContainer component="main">
      <PageHeader title={t('Billing')} />
      {!user.isPremium && (
        <>
          <Box mb={4}>
            <PremiumPlan onActivateClick={() => setIsModalOpen(true)} />
          </Box>
          <Divider sx={{ marginBottom: 4 }} />
        </>
      )}

      <Paper variant="outlined" sx={{ mb: 4 }}>
        <Box sx={{ pl: { xs: 2, sm: 4 }, pt: { xs: 2, sm: 4 } }}>
          <Typography variant="h3">{t('Invoice history')}</Typography>
          <Typography variant="body2" mb={4}>
            {t('View or download your past invoices')}
          </Typography>
        </Box>

        <Box mb={2}>
          <InvoicesTable invoices={invoicesData?.items} />
        </Box>
        {!!invoicesData?.links?.next && (
          <Button
            variant="outlined"
            color="secondary"
            label={t('Load more')}
            onClick={() => fetchNextInvoices(invoicesData?.links?.next)}
          />
        )}
      </Paper>

      <Paper variant="outlined" sx={{ mb: 4 }}>
        <Box sx={{ pl: { xs: 2, sm: 4 }, pt: { xs: 2, sm: 4 } }}>
          <Typography variant="h3">{t('Unbilled invoice')}</Typography>
          <Typography variant="body2" mb={2}>
            {t('Amounts displayed have been accrued within the month to date')}
          </Typography>
        </Box>

        {(() => {
          if (loading) {
            return (
              <>
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  width="100%"
                  height={50}
                  sx={{ mb: 0.5 }}
                />
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  width="100%"
                  height={50}
                  sx={{ mb: 0.5 }}
                />
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  width="100%"
                  height={50}
                  sx={{ mb: 0.5 }}
                />
              </>
            );
          }
          if (!data) {
            return <Typography variant="body2">{t('No data')}</Typography>;
          }
          return <InvoiceDetailsTable data={data} />;
        })()}
      </Paper>

      <Modal open={isModalOpen} onClose={closeHandler}>
        <Box sx={style}>
          <Typography variant="h2" mb={2}>
            {t('Unique Ukrainian voiceover')}
          </Typography>
          <Typography>
            Для активації додаткових голосів української озвучки необхідно
            укласти договір про співпрацю та надати необхідні документи:
          </Typography>
          <Box mb={1}>
            <List dense>
              <ListItem>Паспорт громадянина України, або ID-картка</ListItem>
              <ListItem>
                Реєстраційний номер облікової картки платника податків (ІПН)
              </ListItem>
              <ListItem>Витяг з ЄДР про реєстрацію ФОП</ListItem>
            </List>
          </Box>
          <Divider sx={{ mb: 2 }} />
          <Typography mb={2}>
            Після заповнення договору підпишіть його через цей{' '}
            <MuiLink
              href="https://www.ilovepdf.com/sign-pdf"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ textDecoration: 'none' }}
            >
              сервіс
            </MuiLink>{' '}
            та надішліть його з копіями документів на пошту або у телеграм.
          </Typography>
          <Typography mb={2}>
            Якщо вам потрібна допомога, будь ласка, зверніться до нас зручним
            для вас способом:
          </Typography>

          <Box mb={1}>
            <Link
              to="mailto:2donate.me@gmail.com"
              label="2donate.me@gmail.com"
            />
          </Box>

          <Box mb={4}>
            <MuiLink
              href="https://t.me/+WBmBrEmZrotiNjdi"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ textDecoration: 'none' }}
            >
              Telegram
            </MuiLink>
          </Box>
          <Box display="flex" gap={2}>
            <MuiLink
              href={AGREEMENT_HREF}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                startIcon={<DownloadIcon />}
                label={t('Download Agreement')}
              />
            </MuiLink>
            <Button
              label={t('Back')}
              color="secondary"
              variant="text"
              onClick={closeHandler}
            />
          </Box>
        </Box>
      </Modal>
    </StyledContainer>
  );
}
