import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from 'view/components/Button';
import Input from 'view/components/Input';
import FormHeader from 'view/components/Form/Header';
import { StyledFormContainer, StyledFormActions } from '../../styled';
import { LIVE_DONATE_PATH } from 'view/routes';
import useUser from 'hooks/user';
import { useTranslation } from 'react-i18next';
import { updateCipherId } from 'services/api/user';
import Typography from '@mui/material/Typography';
import useAuth from 'hooks/auth';
import ConfirmationDialog from 'view/components/ConfirmationDialog';
import CopyButton from 'view/components/CopyButton';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import IconButton from '@mui/material/IconButton';
import EyeOffIcon from 'view/components/icons/EyeOff';
import EyeIcon from 'view/components/icons/Eye';

type ViewOBSURLProps = {
  onCancel: () => void;
};

export default function ViewOBSURL({ onCancel }: ViewOBSURLProps) {
  const { setErrorMessage } = useAuth();
  const { OBSUrl, setUserData } = useUser();
  const { t } = useTranslation();
  const url = `${process.env.REACT_APP_UI_URL}/${OBSUrl}${LIVE_DONATE_PATH}`;

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const onGenerateNewClick = async () => {
    try {
      setIsDialogOpen(false);
      const { url, cipherId } = await updateCipherId();
      setUserData((prevState) => {
        return {
          ...prevState,
          OBSUrl: url,
          user: {
            ...prevState.user,
            cipherId,
          },
        };
      });
    } catch (err) {
      setErrorMessage(t('Error while generating new OBS URL'));
    }
  };

  return (
    <>
      <StyledFormContainer>
        <FormHeader
          title={t('OBS link')}
          description={t('Add this link to your OBS browser source')}
        />
        <Box display="flex" mb={4}>
          <Input
            sx={{ mr: 2 }}
            label={t('Keep it secret and never share with anyone')}
            helperText={t(
              'OBS settings for the browser source: width 600px, height 300px',
            )}
            endAdornment={
              <IconButton
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <EyeOffIcon /> : <EyeIcon />}
              </IconButton>
            }
            id="OBSurl"
            type={showPassword ? 'text' : 'password'}
            disabled
            value={url}
          />
          <CopyButton value={url} edge="end" />
        </Box>
        <Typography variant="body2">
          {t(
            'You can generate a new link. After that, update it and the link to the live story in OBS, please',
          )}
        </Typography>
        <StyledFormActions>
          <Button
            label={t('Generate New')}
            color="primary"
            startIcon={<RestartAltIcon />}
            onClick={() => setIsDialogOpen(true)}
          />
          <Button
            label={t('Back')}
            variant="text"
            color="secondary"
            onClick={onCancel}
          />
        </StyledFormActions>
      </StyledFormContainer>
      <ConfirmationDialog
        open={isDialogOpen}
        text={t('Are you sure you want to generate a new OBS URL?')}
        confirmText={t('Generate')}
        color="primary"
        onConfirm={onGenerateNewClick}
        onClose={() => setIsDialogOpen(false)}
      />
    </>
  );
}
