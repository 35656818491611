import { useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';

const socketInstances = new Map<string, Socket>();

export default function useSocket(cipherId?: string) {
  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    if (!cipherId) {
      return;
    }

    if (socketInstances.has(cipherId)) {
      setSocket(socketInstances.get(cipherId)!);
      return;
    }

    const socketIo = io(process.env.REACT_APP_API_URL, {
      transports: ['websocket', 'polling'],
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 2000,
      timeout: 10000,
    });

    socketInstances.set(cipherId, socketIo);
    setSocket(socketIo);

    socketIo.on('connect', () => {
      socketIo.emit('join', cipherId);
    });

    socketIo.on('disconnect', () => {});

    // const intervalId = setInterval(() => {
    //   if (!socket.connected) {
    //     socket.connect();
    //   }
    // }, 30000);

    return () => {
      // clearInterval(intervalId);
      socketIo.disconnect();
      socketInstances.delete(cipherId);
      setSocket(null);
    };
  }, [cipherId]);

  return { socket };
}

export type Callback = (value: boolean) => void;
