import React from 'react';
import { useForm } from 'react-hook-form';
import Input from 'view/components/Form/Input';
import { yupResolver } from '@hookform/resolvers/yup';
import { ChangeWayforpayValidationSchema } from 'constraints';
import { useTranslation } from 'react-i18next';
import useUser from 'hooks/user';
import Box from '@mui/material/Box';
import PasswordInput from 'view/components/Form/PasswordInput';
import Dialog from 'view/components/Dialog';
import Button from 'view/components/Button';
import { isWayforpay } from 'tools';

export type WayforpayFormData = {
  merchantAccount: string;
  merchantSecretKey: string;
};

type WayforpayDialogProps = {
  open: boolean;
  onSaved: (data: WayforpayFormData) => void;
  onClose: () => void;
};

export default function WayforpayDialog({
  open,
  onSaved,
  onClose,
}: WayforpayDialogProps) {
  const { user } = useUser();
  const { t } = useTranslation();

  const data = user.paymentMethods.find(isWayforpay);

  const initialFormData = {
    resolver: yupResolver(ChangeWayforpayValidationSchema(t)),
    defaultValues: {
      merchantAccount: data?.merchantAccount || '',
      merchantSecretKey: data?.merchantSecretKey || '',
    },
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<WayforpayFormData>(initialFormData);

  const onSubmit = async (data: WayforpayFormData) => {
    onSaved(data);
  };

  const Content = (
    <form>
      <Box mb={2}>
        <Input
          name="merchantAccount"
          label={t('Merchant account')}
          type="text"
          error={!!errors.merchantAccount?.message}
          helperText={errors.merchantAccount?.message}
          control={control}
        />
      </Box>
      <Box mb={2}>
        <PasswordInput
          name="merchantSecretKey"
          label={t('Merchant secret key')}
          error={!!errors.merchantSecretKey?.message}
          helperText={errors.merchantSecretKey?.message}
          control={control}
        />
      </Box>
      {/*<Tooltip*/}
      {/*  arrow*/}
      {/*  title={t(*/}
      {/*    `Monthly limit of withdrawals is ${formatUAHCurrency(*/}
      {/*      MAX_UAH_AMOUNT,*/}
      {/*    )}`,*/}
      {/*  )}*/}
      {/*>*/}
      {/*  <Box>*/}
      {/*    <Button*/}
      {/*      onClick={() => onSaved(true)}*/}
      {/*      fullWidth*/}
      {/*      color="error"*/}
      {/*      label="Continue without credentials"*/}
      {/*      variant="text"*/}
      {/*    />*/}
      {/*  </Box>*/}
      {/*</Tooltip>*/}
    </form>
  );

  const Actions = (
    <>
      <Button
        label={t('Cancel')}
        variant="text"
        color="secondary"
        disabled={isSubmitting}
        onClick={onClose}
      />
      <Button
        label={t('Save')}
        withLoader
        loading={isSubmitting}
        disabled={!isDirty || isSubmitting}
        onClick={() => handleSubmit(onSubmit)()}
      />
    </>
  );

  return (
    <Dialog
      open={open}
      title={t('Please enter your Wayforpay merchant credentials')}
      content={Content}
      onClose={onClose}
      actions={Actions}
    />
  );
}
