import React from 'react';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { StyledPaymentBox } from 'view/pages/Donate/components/PaymentBox/styled';
import { PaymentMethodTypes } from 'types/users';
import Typography from '@mui/material/Typography';
import { PAYMENT_METHOD_MEDIA_MAP } from 'view/pages/AccountSettings/components/ChangePaymentMethods';
import { useTranslation } from 'react-i18next';

type PaymentBoxProps = {
  payment: PaymentMethodTypes;
};

function PaymentBox({ payment }: PaymentBoxProps) {
  const { t } = useTranslation();

  if (!PAYMENT_METHOD_MEDIA_MAP[payment]) {
    return (
      <Typography variant="body2">{t('Unknown payment method')}</Typography>
    );
  }

  return (
    <FormControlLabel
      value={payment}
      sx={{
        '.MuiFormControlLabel-label': {
          width: '100%',
        },
      }}
      control={<Radio inputProps={{ 'aria-label': 'controlled' }} />}
      label={
        <StyledPaymentBox>
          <Typography mr={1.5}>
            {PAYMENT_METHOD_MEDIA_MAP[payment].label}
          </Typography>
          {PAYMENT_METHOD_MEDIA_MAP[payment].icon}
        </StyledPaymentBox>
      }
    />
  );
}

export default PaymentBox;
