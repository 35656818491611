export default function WayForPayLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="16"
      viewBox="0 0 2007.199 402.453"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            id="Контур_2245"
            data-name="Контур 2245"
            d="M508.677,533.769V511.642a23.684,23.684,0,1,1,19.79,0v22.126a9.928,9.928,0,1,1-19.79,0m76.89-87.337v.973H451.836v-.973H427.309v.973q0,58.852,24.138,92.334a77.864,77.864,0,0,0,66.9,33.417,79.357,79.357,0,0,0,68.131-32.833q23.943-32.9,23.943-92.593v-1.3ZM258.15,320.162V571.4h29.2V464.081H391.492V438.126H287.349V346.182H398.175V320.227Zm433.766,25.695h37.959q32.443,0,47.172,10.966t14.729,35.947a45.875,45.875,0,0,1-14.47,36.855q-14.4,12.264-45.421,12.264h-39.97Zm-29.2-25.63V571.468h29.2V467h52.428l60.8,104.467h34.52l-68.2-112.9q50.482-17.52,50.547-67.352c0-23.749-7.4-41.463-22.126-53.272s-37.5-17.714-68.326-17.714ZM451.123,352.67c-15.962,21.867-23.943,45.421-23.943,85.52v1.3h24.657v-.973c0-32.443,5.71-50.547,17.195-67.612a56.776,56.776,0,0,1,50.287-25.955,55.738,55.738,0,0,1,49.768,25.5c11.226,17,16.871,34.909,16.871,67.807v.973h24.527v-.973c0-38.932-8.046-63-24.073-85.261a77.863,77.863,0,0,0-67.028-33.481,79.291,79.291,0,0,0-68.066,32.9"
            transform="translate(-258.15 -319.427)"
            fill="none"
          />
        </clipPath>
        <linearGradient
          id="linear-gradient"
          x1="-5.439"
          y1="4.701"
          x2="-5.427"
          y2="4.701"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#4ec8ec" />
          <stop offset="0.01" stopColor="#4ec8ec" />
          <stop offset="1" stopColor="#4684c5" />
        </linearGradient>
      </defs>
      <g
        id="Сгруппировать_4002"
        data-name="Сгруппировать 4002"
        transform="translate(-365.85 -411.57)"
      >
        <g
          id="Сгруппировать_3999"
          data-name="Сгруппировать 3999"
          transform="translate(365.85 411.57)"
        >
          <path
            id="Контур_2231"
            data-name="Контур 2231"
            d="M155.372,411.047v19.466H147.65v-57.1h7.786v4.866a22.259,22.259,0,0,1,6.489-4.672,19.855,19.855,0,0,1,7.786-1.557,21.736,21.736,0,0,1,16.222,6.489,22.061,22.061,0,0,1,6.488,16.221,22.9,22.9,0,0,1-1.622,8.63,21.673,21.673,0,0,1-4.607,7.2,19.469,19.469,0,0,1-7.137,5.061,22,22,0,0,1-8.76,1.622,21.345,21.345,0,0,1-8.176-1.557,22,22,0,0,1-6.878-4.8m0-16.611a15.054,15.054,0,0,0,4.282,10.9,13.433,13.433,0,0,0,10.252,4.542,14.859,14.859,0,0,0,14.794-14.924,15.313,15.313,0,0,0-4.347-10.966,14.277,14.277,0,0,0-10.512-4.412,13.757,13.757,0,0,0-10.122,4.477,14.211,14.211,0,0,0-4.347,10.382"
            transform="translate(-76.989 -30.552)"
            fill="#3792d0"
          />
          <path
            id="Контур_2232"
            data-name="Контур 2232"
            d="M207.116,416.516v-5.58a20.183,20.183,0,0,1-6.489,4.672,18.432,18.432,0,0,1-7.786,1.622,21.93,21.93,0,0,1-16.157-6.488,22.32,22.32,0,0,1-6.489-16.286,22.713,22.713,0,0,1,1.622-8.63,20.962,20.962,0,0,1,4.542-7.138,21.865,21.865,0,0,1,7.137-5.061A22.322,22.322,0,0,1,192.322,372a21.351,21.351,0,0,1,8.176,1.557,20.306,20.306,0,0,1,6.489,4.736v-5.191h7.851v43.15Zm-29-21.867a14.924,14.924,0,0,0,4.347,10.966,14.015,14.015,0,0,0,10.577,4.477,13.625,13.625,0,0,0,10.058-4.477,14.4,14.4,0,0,0,4.412-10.447,14.924,14.924,0,0,0-4.347-10.836,13.626,13.626,0,0,0-10.317-4.542,14.729,14.729,0,0,0-14.729,14.859"
            transform="translate(46.72 -30.766)"
            fill="#3792d0"
          />
          <path
            id="Контур_2233"
            data-name="Контур 2233"
            d="M421.75,464.37h8.305l11.679,32.119,12.588-32.119h8.371l-23.229,57.1h-8.176l5.97-15.184Z"
            transform="translate(-59.034 -121.769)"
            fill="#3792d0"
          />
          <path
            id="Контур_2234"
            data-name="Контур 2234"
            d="M241,394.12a22.19,22.19,0,0,0-2.271-11.42,7.721,7.721,0,0,0-7.073-3.633,8.175,8.175,0,0,0-7.332,3.633,20.113,20.113,0,0,0-2.4,11.1v22.126h-7.4v-43.15h6.943V377.9a14.6,14.6,0,0,1,4.867-4.347,12.976,12.976,0,0,1,6.489-1.427,15.441,15.441,0,0,1,7.851,1.817,12.977,12.977,0,0,1,5.126,5.645,12.976,12.976,0,0,1,5.256-5.645,16.479,16.479,0,0,1,8.111-1.817,15.054,15.054,0,0,1,11.68,4.737,18.167,18.167,0,0,1,4.412,12.977v26.409h-7.786V390.876a13.3,13.3,0,0,0-2.466-8.565,7.917,7.917,0,0,0-6.489-3.115,8.111,8.111,0,0,0-7.267,3.309,20.116,20.116,0,0,0-2.206,10.966v22.58h-7.527Z"
            transform="translate(290.093 -30.172)"
            fill="#3792d0"
          />
          <path
            id="Контур_2235"
            data-name="Контур 2235"
            d="M247.971,397.737a14.4,14.4,0,0,0,4.866,8.76,14.434,14.434,0,0,0,16.286,1.557,14.079,14.079,0,0,0,5.061-5.256h8.695a24.655,24.655,0,0,1-8.305,11.031,20.956,20.956,0,0,1-12.458,3.763,22.642,22.642,0,0,1-8.76-1.687,20.825,20.825,0,0,1-7.2-5.061,22.389,22.389,0,0,1-4.672-7.4,21.994,21.994,0,0,1-1.752-8.565,23.365,23.365,0,0,1,1.558-8.565,23.1,23.1,0,0,1,4.607-7.2,22.583,22.583,0,0,1,7.2-5.321,22.058,22.058,0,0,1,8.824-1.752,21.8,21.8,0,0,1,10.577,2.6,23.357,23.357,0,0,1,8.111,7.4,25.961,25.961,0,0,1,2.79,6.489,34.261,34.261,0,0,1,.844,8.111v1.1Zm27.771-7.008a15.639,15.639,0,0,0-5-8.24,14.145,14.145,0,0,0-17.389,0,14.792,14.792,0,0,0-5.126,8.11Z"
            transform="translate(428.406 -30.544)"
            fill="#3792d0"
          />
          <path
            id="Контур_2236"
            data-name="Контур 2236"
            d="M270.221,415.773H262.5v-43.15h7.462v5.126a15.309,15.309,0,0,1,5.32-4.217,15.572,15.572,0,0,1,6.489-1.363,16.61,16.61,0,0,1,12.977,5,20.244,20.244,0,0,1,4.542,14.21v24.657H291.7v-22.9a16.674,16.674,0,0,0-2.6-10.187,9.214,9.214,0,0,0-7.851-3.309,9.473,9.473,0,0,0-8.435,3.893,24.528,24.528,0,0,0-2.6,12.977Z"
            transform="translate(553.383 -30.022)"
            fill="#3792d0"
          />
          <path
            id="Контур_2237"
            data-name="Контур 2237"
            d="M519.164,519.375V483.233H513v-7.008h6.164V462.21h7.721v14.015h6.1v7.008h-6.1v36.142Z"
            transform="translate(441.805 -133.625)"
            fill="#3792d0"
          />
          <path
            id="Контур_2238"
            data-name="Контур 2238"
            d="M328.861,397.739a14.341,14.341,0,0,0,21.153,10.317,13.629,13.629,0,0,0,5.061-5.256h8.76a25.239,25.239,0,0,1-8.37,11.031,20.959,20.959,0,0,1-12.458,3.763,22.135,22.135,0,0,1-8.695-1.687,20.825,20.825,0,0,1-7.2-5.061,23.683,23.683,0,0,1-4.737-7.4,23.425,23.425,0,0,1,0-17.13,22.91,22.91,0,0,1,4.542-7.2,22.191,22.191,0,0,1,26.668-4.477,21.673,21.673,0,0,1,10.836,13.821,34.065,34.065,0,0,1,.909,8.111v1.168Zm27.836-7.008a15.7,15.7,0,0,0-5.061-8.24,12.978,12.978,0,0,0-8.565-2.92,13.754,13.754,0,0,0-8.824,3.05,15.116,15.116,0,0,0-5.126,8.111Z"
            transform="translate(873.097 -30.546)"
            fill="#3792d0"
          />
          <path
            id="Контур_2239"
            data-name="Контур 2239"
            d="M351.266,415.77H343.48V372.62h7.462v5.126a14.665,14.665,0,0,1,12-5.58,16.74,16.74,0,0,1,12.978,5,20.506,20.506,0,0,1,4.542,14.21v24.657h-7.657v-22.9a16.673,16.673,0,0,0-2.531-10.187,9.28,9.28,0,0,0-7.851-3.309,9.409,9.409,0,0,0-8.435,3.893,23.879,23.879,0,0,0-2.6,12.977Z"
            transform="translate(997.854 -30.019)"
            fill="#3792d0"
          />
          <path
            id="Контур_2240"
            data-name="Контур 2240"
            d="M368.07,419.955h8.241a12.394,12.394,0,0,0,4.737,4.607,13.953,13.953,0,0,0,6.489,1.622,14.408,14.408,0,0,0,10.577-4.023,13.886,13.886,0,0,0,3.958-10.382v-.844a19.915,19.915,0,0,1-6.489,4.672,18.883,18.883,0,0,1-7.851,1.622,21.931,21.931,0,0,1-16.157-6.489,22.319,22.319,0,0,1-6.488-16.287,22.708,22.708,0,0,1,1.623-8.629,20.181,20.181,0,0,1,4.606-7.138,21.6,21.6,0,0,1,7.073-5.061A22.319,22.319,0,0,1,387.212,372a21.352,21.352,0,0,1,8.176,1.557A20.575,20.575,0,0,1,402.2,378.3v-5.191h7.786v36.012a25.046,25.046,0,0,1-5.84,17.584,20.633,20.633,0,0,1-16.092,6.489,22.258,22.258,0,0,1-12.653-3.5,18.883,18.883,0,0,1-7.462-9.863m5.191-25.306a15.181,15.181,0,0,0,4.347,10.966,14.21,14.21,0,0,0,10.577,4.477,13.756,13.756,0,0,0,10.122-4.477,14.34,14.34,0,0,0,4.348-10.447,15.119,15.119,0,0,0-4.283-10.836,13.82,13.82,0,0,0-10.317-4.542,14.729,14.729,0,0,0-14.794,14.859"
            transform="translate(1116.404 -30.766)"
            fill="#3792d0"
          />
          <path
            id="Контур_2241"
            data-name="Контур 2241"
            d="M395.982,379H388.26V370.05h7.722Zm-7.657,5.061h7.657V427.15h-7.657Z"
            transform="translate(1243.636 -41.465)"
            fill="#3792d0"
          />
          <path
            id="Контур_2242"
            data-name="Контур 2242"
            d="M413.516,415.773H405.73v-43.15h7.462v5.126a15.306,15.306,0,0,1,5.32-4.217A15.571,15.571,0,0,1,425,372.169a16.611,16.611,0,0,1,12.978,5,20.246,20.246,0,0,1,4.542,14.21v24.657h-7.786v-22.9A16.673,16.673,0,0,0,432.2,382.94a9.409,9.409,0,0,0-7.916-3.309,9.277,9.277,0,0,0-8.371,3.893,23.881,23.881,0,0,0-2.595,12.977Z"
            transform="translate(1339.523 -30.022)"
            fill="#3792d0"
          />
          <path
            id="Контур_2243"
            data-name="Контур 2243"
            d="M435.276,397.737a14.08,14.08,0,0,0,4.866,8.759,14.332,14.332,0,0,0,16.222,1.557,12.976,12.976,0,0,0,5.061-5.256h9.019a24.657,24.657,0,0,1-8.305,11.031,20.958,20.958,0,0,1-12.459,3.763,22.643,22.643,0,0,1-8.759-1.687,20.825,20.825,0,0,1-7.2-5.061,23.7,23.7,0,0,1-4.737-7.4,23.426,23.426,0,0,1,0-17.13,22.914,22.914,0,0,1,4.542-7.2,23.556,23.556,0,0,1,7.267-5.321,22.057,22.057,0,0,1,8.824-1.752,21.8,21.8,0,0,1,10.577,2.6,23.163,23.163,0,0,1,8.046,7.4,21.733,21.733,0,0,1,2.79,6.489,31.014,31.014,0,0,1,.909,8.111v1.1Zm27.771-7.008a16.028,16.028,0,0,0-5-8.24,14.145,14.145,0,0,0-17.389,0,14.859,14.859,0,0,0-5.191,8.111Z"
            transform="translate(1458.242 -30.544)"
            fill="#3792d0"
          />
          <path
            id="Контур_2244"
            data-name="Контур 2244"
            d="M1611.955,320.383h-64.887V571.689h29.2V472.866h29.588q48.47,0,74.49-20.569t25.955-58.4q0-73.322-94.15-73.257m46.524,114.849c-11.55,8.37-30.3,12.523-56.127,12.523H1576.2V345.689h32.443c23.035,0,40.035,4.088,50.871,12.2A43.28,43.28,0,0,1,1675.869,395q0,27.642-17.39,40.23M1823.485,319.41l-99.341,252.279h29.848l30.626-79.746h100.7l31.275,79.94h30.237L1848.077,319.41Zm-28.55,146.06,29.588-77.864a331.325,331.325,0,0,0,10.836-36.271,367.136,367.136,0,0,0,11.679,36.271l29.2,77.864ZM2112.36,320.383l-67.028,125.685-66.508-125.685h-31.989l83.7,155.209v96.1h29.588v-97.33l83.833-153.846ZM382.615,475.267A512.591,512.591,0,0,0,370.287,534.7a475.394,475.394,0,0,0-13.951-60.15L309.1,320.383H278.148L233.116,473.191A435.064,435.064,0,0,0,219.36,534.7a559.376,559.376,0,0,0-12-59.111L167.711,320.383H136.76l66.833,251.306h28.875l49.119-167.213a381.8,381.8,0,0,0,12-49.7,165.247,165.247,0,0,0,4.672,20.439c2.985,11.355,5.71,20.764,8.111,28.225L357.05,571.689h28.875l67.158-251.306H422.131ZM557.225,319.54,457.883,571.818h29.588l30.951-79.875H619.061l31.275,79.94h30.237L581.817,319.6ZM528.675,465.6l29.588-77.864A331.347,331.347,0,0,0,569.1,351.464c4.023,14.34,7.851,26.409,11.679,36.271l29.2,77.864Zm250.4-19.466-66.509-125.75H680.574l83.7,155.209v96.1h29.394v-97.33l84.352-153.976h-31.6Z"
            transform="translate(-136.76 -319.41)"
            fill="#26304b"
          />
        </g>
        <g
          id="Сгруппировать_3993"
          data-name="Сгруппировать 3993"
          transform="translate(1153.508 411.678)"
          clipPath="url(#clip-path)"
        >
          <rect
            id="Прямоугольник_1513"
            data-name="Прямоугольник 1513"
            width="581.318"
            height="253.447"
            transform="translate(0 0.346)"
            fill="url(#linear-gradient)"
          />
        </g>
      </g>
    </svg>
  );
}
