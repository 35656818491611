import React, { ReactElement, useState } from 'react';
import { StyledMenu } from './styled';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

export type MenuItemType = {
  icon?: ReactElement;
  label: string;
  className?: string;
  onClick: React.MouseEventHandler<HTMLLIElement>;
};

type ActionMenuProps = {
  buttonIcon: ReactElement;
  menuItems: MenuItemType[];
  small?: boolean;
  top?: boolean;
  handleClose?: () => void;
};

const ActionMenu = ({
  buttonIcon,
  menuItems,
  small,
  top,
  handleClose,
}: ActionMenuProps) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);

  const onMenuOpen = (e: React.MouseEvent<HTMLElement>) =>
    setMenuAnchorEl(e.currentTarget);

  const onMenuClose = () => {
    if (handleClose) {
      handleClose();
    }

    setMenuAnchorEl(null);
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-end">
      <IconButton
        color="primary"
        aria-label="more"
        id="actions"
        aria-controls={menuOpen ? 'actions' : undefined}
        aria-expanded={menuOpen ? 'true' : undefined}
        aria-haspopup="true"
        onClick={onMenuOpen}
      >
        {buttonIcon}
      </IconButton>
      <StyledMenu
        MenuListProps={{
          'aria-labelledby': 'contact-actions',
        }}
        anchorOrigin={
          top
            ? {
                vertical: 'top',
                horizontal: 'right',
              }
            : {
                vertical: 'bottom',
                horizontal: 'center',
              }
        }
        transformOrigin={
          top
            ? {
                vertical: 'bottom',
                horizontal: 'left',
              }
            : {
                vertical: 'top',
                horizontal: 'center',
              }
        }
        anchorEl={menuAnchorEl}
        open={menuOpen}
        onClose={onMenuClose}
        PaperProps={{
          style: {
            width: '12.375rem',
          },
        }}
      >
        {menuItems.map(
          ({ label, className = 'primary', icon, onClick }: MenuItemType) => (
            <MenuItem
              key={label}
              className={className}
              onClick={(args) => {
                onMenuClose();
                onClick(args);
              }}
            >
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body1" fontSize="0.875rem">
                    {label}
                  </Typography>
                }
              />
            </MenuItem>
          ),
        )}
      </StyledMenu>
    </Box>
  );
};

export default ActionMenu;
