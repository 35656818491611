import { Link, useParams } from 'react-router';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { StyledFormContainer, StyledPageContainer } from './styled';
import Button from 'view/components/Button';
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';
import { DONATE_PATH } from 'view/routes';
import { useTranslation } from 'react-i18next';

export default function DonateSuccessPage() {
  const { contentCreatorName } = useParams();
  const { t } = useTranslation();

  return (
    <StyledPageContainer>
      <StyledFormContainer>
        <Box mb={2}>
          <Typography variant="h2">
            {t('You have successfully donated to', {
              contentCreatorName: contentCreatorName?.toUpperCase(),
            })}
          </Typography>
          <Typography variant="body2">
            {t('Thank you for the contribution!')}
          </Typography>
        </Box>
        <Link to={`${DONATE_PATH}/${contentCreatorName}`}>
          <Button
            color="success"
            label={t('Donate Again')}
            startIcon={<ReplayRoundedIcon />}
          />
        </Link>
      </StyledFormContainer>
    </StyledPageContainer>
  );
}
