import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ActionMenu from 'view/components/ActionMenu';
import Avatar from 'view/components/Avatar';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import ConfirmationDialog from 'view/components/ConfirmationDialog';
import NavigationTabs from 'view/components/NavigationTabs';
import MenuIcon from '@mui/icons-material/Menu';
import LogoIcon from 'view/components/icons/Logo';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import GoogleIcon from '@mui/icons-material/Google';
import { ADMIN_USERS_PATH, APP_PATH } from 'view/routes';
import useAuth from 'hooks/auth';
import Button from 'view/components/Button';
import { Link, useNavigate } from 'react-router';
import MuiLink from '@mui/material/Link';
import useUser from 'hooks/user';
import useTheme from 'hooks/theme';
import CustomizedSwitches from 'view/components/Switch';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import PeopleIcon from '@mui/icons-material/People';
import { useTranslation } from 'react-i18next';
import CloseIcon from 'view/components/icons/Close';
import { UserRole } from 'types/users';
import DrawerDetails from 'view/components/AppBar/components/DrawerDetails';
import Drawer from 'view/components/Drawer';

export default function AdminAppBar() {
  const { isAuthenticated, signOut } = useAuth();
  const { user } = useUser();
  const isAdmin = user?.role === UserRole.ADMIN;
  const { toggleTheme, isDarkMode } = useTheme();
  const navigate = useNavigate();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [dialogToLogout, setDialogToLogout] = useState<boolean>(false);
  const { t } = useTranslation();

  const adminTabs = [
    {
      id: ADMIN_USERS_PATH,
      onClick: () => navigate(ADMIN_USERS_PATH),
      label: 'Users',
      icon: <PeopleIcon />,
    },
  ];

  const onLogoutConfirmation = () => signOut();

  const onConfirmationClose = () => {
    setDialogToLogout(false);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        background: 'transparent',
        backdropFilter: 'blur(20px)',
      }}
    >
      <Container maxWidth="xl">
        <Toolbar
          sx={{
            justifyContent: 'space-between',
            padding: { xs: 0, md: 'inherit' },
          }}
        >
          <Box mr={4} sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Link to={APP_PATH} style={{ textDecoration: 'none' }}>
              <LogoIcon />
            </Link>
          </Box>

          {isAuthenticated && (
            <Box
              sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}
              mr={2}
            >
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => setDrawerOpen(!drawerOpen)}
                color="inherit"
              >
                {drawerOpen ? <CloseIcon /> : <MenuIcon />}
              </IconButton>
            </Box>
          )}

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, mr: 1 }}>
            <Link to={APP_PATH} style={{ textDecoration: 'none' }}>
              <LogoIcon />
            </Link>
          </Box>

          {isAuthenticated && (
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <Box>
                <NavigationTabs tabs={adminTabs} />
              </Box>
            </Box>
          )}

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
              <CustomizedSwitches onChange={toggleTheme} checked={isDarkMode} />
            </Box>
            {isAuthenticated && (
              <>
                <Box mr={1}>
                  <Avatar
                    src={user.profilePictureUrl}
                    contentCreatorName={user.contentCreatorName}
                    size={3}
                  />
                </Box>
                <Box
                  minWidth="0"
                  mr={1}
                  sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                >
                  <Typography
                    variant="body1"
                    fontSize="0.875rem"
                    sx={{ lineHeight: '1.5rem' }}
                    noWrap
                  >
                    {user.contentCreatorName}
                  </Typography>
                  <Typography
                    variant="body2"
                    fontSize="0.75rem"
                    sx={{ lineHeight: '1rem' }}
                    noWrap
                  >
                    {isAdmin ? 'Administrator' : 'Content creator'}
                  </Typography>
                </Box>

                <ActionMenu
                  buttonIcon={<MoreHorizRoundedIcon />}
                  menuItems={[
                    {
                      label: t('Log out'),
                      icon: <LogoutRoundedIcon />,
                      onClick: () => setDialogToLogout(true),
                    },
                  ]}
                />
              </>
            )}
            {!isAuthenticated && (
              <Box sx={{ flexGrow: 1, justifyContent: 'flex-end' }}>
                <MuiLink href={`${process.env.REACT_APP_API_URL}/api/auth`}>
                  <Button
                    variant="contained"
                    color="primary"
                    label={t('Sign in with Google')}
                    startIcon={<GoogleIcon />}
                  />
                </MuiLink>
              </Box>
            )}
          </Box>
          {dialogToLogout && (
            <ConfirmationDialog
              open={dialogToLogout}
              text={t('Are you sure, you want to log out?')}
              confirmText={t('Log out')}
              color="primary"
              onConfirm={onLogoutConfirmation}
              onClose={onConfirmationClose}
            />
          )}
          <Drawer
            variant="temporary"
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
            action={
              <DrawerDetails
                onClose={() => setDrawerOpen(false)}
                adminTabs={[...adminTabs]}
              />
            }
          />
        </Toolbar>
      </Container>
      <Divider />
    </AppBar>
  );
}
