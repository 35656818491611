import React from 'react';
import { StyledPlan } from './styled';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import Button from 'view/components/Button';
import Chip from '@mui/material/Chip';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import { VOICES_CONFIG } from 'view/constants';
import Stack from '@mui/material/Stack';
import AudioButton, { StyledAudioButton } from 'view/components/AudioButton';
import VoiceMagicIcon from 'view/components/icons/VoiceMagic';

type PremiumPlanProps = {
  onActivateClick: () => void;
};

export default function PremiumPlan({ onActivateClick }: PremiumPlanProps) {
  const { t } = useTranslation();

  return (
    <StyledPlan>
      <Box display="flex" justifyContent="space-between" gap={1} mb={2}>
        <Box display="flex" alignItems="center" gap={1}>
          <VoiceMagicIcon />
          <Typography variant="body1">
            {t('Unique Ukrainian voiceover')}
          </Typography>
        </Box>

        <Chip icon={<TrendingUpIcon />} variant="filled" label="Pro" />
      </Box>

      <Typography variant="body2" mb={2}>
        {t(
          'Allow your viewers to choose the voice of the donation among a variety of voices. All of them support the Ukrainian language and create a special mood on the stream.',
        )}
      </Typography>

      <Stack direction="row" gap={1} flexWrap="wrap" mb={3}>
        {[...Object.values(VOICES_CONFIG)]
          .filter((item, index) => index !== 0 && index < 7)
          .map((value) => {
            return (
              <AudioButton
                key={value.label}
                config={value}
                title="Voice-over example"
              />
            );
          })}
        <StyledAudioButton>
          <Typography ml={4}>{t('And others...')}</Typography>
        </StyledAudioButton>
      </Stack>

      <Box alignSelf="end">
        <Button
          startIcon={<OpenInNewRoundedIcon />}
          label={t('Activate')}
          onClick={onActivateClick}
        />
      </Box>
    </StyledPlan>
  );
}
