import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button from 'view/components/Button';
import Input from 'view/components/Form/Input';
import FormHeader from 'view/components/Form/Header';
import { MinimumDonationAmount, User } from 'types/users';
import { StyledFormContainer, StyledFormActions } from '../../styled';
import useUser from 'hooks/user';
import { ChangeMinimumDonationAmountValidationSchema } from 'constraints';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/auth';
import { updateUserData } from 'services/api/user';
import { Currency, CurrencyLabelMap } from 'services/api/types';
import Typography from '@mui/material/Typography';

type ChangeDonationAmountFormData = {
  minimumDonationAmount: MinimumDonationAmount;
};

type ChangeDonationAmountProps = {
  onCancel: () => void;
  onSaved: ({
    minimumDonationAmount,
  }: Pick<User, 'minimumDonationAmount'>) => void;
};

export default function ChangeMinimumDonationAmount({
  onCancel,
  onSaved,
}: ChangeDonationAmountProps) {
  const { setErrorMessage, setSuccessMessage } = useAuth();
  const { user } = useUser();
  const { t } = useTranslation();

  const initialFormData = {
    resolver: yupResolver(ChangeMinimumDonationAmountValidationSchema(t)),
    defaultValues: {
      minimumDonationAmount: user.minimumDonationAmount,
    },
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<ChangeDonationAmountFormData>(initialFormData);

  const onSubmit = async (data: ChangeDonationAmountFormData) => {
    setErrorMessage('');

    try {
      await updateUserData(user._id, data);
      onSaved(data);
      reset(data);
      setSuccessMessage(t('Successfully updated minimum donation amount'));
    } catch (err) {
      setErrorMessage(t('Error while updating minimum donation amount'));
    }
  };

  const submitDisabled =
    Boolean(Object.keys(errors).length) || !isDirty || isSubmitting;

  return (
    <StyledFormContainer>
      <FormHeader
        title={t('Minimum donation amount')}
        description={t('Change your minimum donation amount')}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" flexDirection="column" mb={2}>
          {Object.keys(Currency).map((key) => {
            const currency = key as Currency;

            return (
              <Box
                key={currency}
                display="grid"
                gridTemplateColumns={{
                  xs: '2.5rem auto',
                  sm: '2.5rem auto',
                }}
                alignItems="center"
                gap={2}
                mb={2}
              >
                <Typography variant="body1">{currency}</Typography>
                <Input
                  name={`minimumDonationAmount.${currency}`}
                  type="number"
                  error={!!errors.minimumDonationAmount?.[currency]?.message}
                  helperText={errors.minimumDonationAmount?.[currency]?.message}
                  control={control}
                  startAdornment={<>{CurrencyLabelMap[currency]}</>}
                />
              </Box>
            );
          })}
        </Box>

        <StyledFormActions>
          <Button
            type="submit"
            label={t('Save')}
            loading={isSubmitting}
            disabled={submitDisabled}
            withLoader
          />
          <Button
            label={t('Back')}
            variant="text"
            color="secondary"
            disabled={isSubmitting}
            onClick={onCancel}
          />
        </StyledFormActions>
      </form>
    </StyledFormContainer>
  );
}
