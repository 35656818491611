import React from 'react';
import { Donation } from 'types/donations';
import { StyledMessage, StyledName } from './styled';
import { motion } from 'framer-motion';
import { DonationAnimationFormat } from 'types/users';
import {
  ENTRY_ANIMATION_PRESETS,
  MAIN_ANIMATION_PRESETS,
} from 'view/constants';
import { censorText, formatCurrency } from 'tools';
import Box from '@mui/material/Box';

type DonationProps = {
  donation: Donation;
  animationFormat: DonationAnimationFormat;
  imageSrc: string;
  censoring: boolean;
};

export default function DonationComponent({
  animationFormat,
  donation,
  imageSrc,
  censoring,
}: DonationProps) {
  const containerVariants = {
    initial: ENTRY_ANIMATION_PRESETS[animationFormat.start],
    animate: MAIN_ANIMATION_PRESETS,
    exit: ENTRY_ANIMATION_PRESETS[animationFormat.end],
  };
  const userName = donation.sponsorName ? donation.sponsorName : 'Анонім';
  const filteredUserName = censoring ? censorText(userName) : userName;

  return (
    <motion.div
      variants={containerVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '600px',
        height: '300px',
      }}
    >
      <Box mb={2}>
        <img height={100} src={imageSrc} alt="Preview" />
      </Box>
      <StyledName>
        {filteredUserName} -{' '}
        {formatCurrency(donation.amount, donation.currency)}
      </StyledName>
      <StyledMessage>
        {censoring ? censorText(donation.message) : donation.message}
      </StyledMessage>
    </motion.div>
  );
}
