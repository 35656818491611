import Box from '@mui/material/Box';

export default function DefaultPaymentCardIcon() {
  return (
    <Box height="32px" width="32px">
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 511.999 511.999"
      >
        <path
          fill="#524E4D"
          d="M392.099,140.802l-264.26,152.57c-11.31,6.53-15.223,21.127-8.692,32.439l41.386,71.682h23.746
	l-1.253,38.961l20.419,35.369c6.53,11.31,21.127,15.221,32.439,8.691l264.26-152.57c11.31-6.53,15.221-21.127,8.691-32.439
	l-20.419-35.369H464.67l1.253-38.961l-41.386-71.682C418.007,138.184,403.409,134.272,392.099,140.802z"
        />
        <rect
          x="301.981"
          y="152.512"
          transform="matrix(0.5 0.866 -0.866 0.5 446.9995 -116.5875)"
          fill="#181818"
          width="44.977"
          height="352.625"
        />
        <path
          fill="#A2A9AF"
          d="M328.886,28.323H23.746C10.686,28.323,0,39.009,0,52.069v168.599
	c0,13.06,10.686,23.746,23.746,23.746h305.14c13.06,0,23.746-10.686,23.746-23.746V52.069
	C352.633,39.009,341.947,28.323,328.886,28.323z"
        />
        <circle fill="#FFE1D6" cx="98.951" cy="110.254" r="23.746" />
        <path
          fill="#FFFFFF"
          d="M98.953,149.994c-22.693,0-41.789,15.357-47.493,36.242h94.985
	C140.742,165.351,121.646,149.994,98.953,149.994z"
        />
        <g>
          <path
            fill="#3D3D3B"
            d="M248.282,80.565h-50.375c-6.559-0.002-11.873,5.316-11.873,11.873
		c0,6.558,5.316,11.873,11.873,11.873h50.377c6.558,0,11.873-5.316,11.873-11.873C260.155,85.88,254.841,80.561,248.282,80.565z"
          />
          <path
            fill="#3D3D3B"
            d="M298.658,124.495H197.906c-6.559-0.002-11.873,5.316-11.873,11.873
		c0,6.558,5.316,11.873,11.873,11.873H298.66c6.558,0,11.873-5.316,11.873-11.873C310.532,129.811,305.217,124.492,298.658,124.495z
		"
          />
          <path
            fill="#3D3D3B"
            d="M298.658,168.426H197.906c-6.559-0.002-11.873,5.316-11.873,11.873
		c0,6.558,5.316,11.873,11.873,11.873H298.66c6.558,0,11.873-5.316,11.873-11.873C310.532,173.742,305.217,168.422,298.658,168.426z
		"
          />
        </g>
      </svg>
    </Box>
  );
}
