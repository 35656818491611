import Box from '@mui/material/Box';
import React, { Dispatch, SetStateAction } from 'react';
import useAuth from 'hooks/auth';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddInvoiceValidationSchema } from 'constraints';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Input from 'view/components/Form/Input';
import Button from 'view/components/Button';
import Dialog from 'view/components/Dialog';
import { format } from 'date-fns';
import { createUserInvoice } from 'services/api/admin/user';
import { Invoice } from 'types/invoices';
import { PaginatedResponse } from 'services/api/types';

export type AddInvoiceFormData = {
  startDate: string;
  endDate: string;
};

type AddInvoiceModalProps = {
  userId: string;
  open: boolean;
  setInvoicesData: Dispatch<SetStateAction<PaginatedResponse<Invoice>>>;
  onClose: () => void;
};

export default function AddInvoiceModal({
  userId,
  setInvoicesData,
  open,
  onClose,
}: AddInvoiceModalProps) {
  const { setErrorMessage, setSuccessMessage } = useAuth();
  const { t } = useTranslation();

  const [formatedDate] = new Date().toISOString().split('T');

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<AddInvoiceFormData>({
    resolver: yupResolver(AddInvoiceValidationSchema(t)),
    defaultValues: {
      startDate: formatedDate,
      endDate: formatedDate,
    },
  });
  const startDate = watch('startDate');
  const endDate = watch('endDate');

  const onSubmit = async (data: AddInvoiceFormData) => {
    setErrorMessage('');

    try {
      const invoice = await createUserInvoice(userId, data);

      setInvoicesData((prevState) => {
        const updatedMeta = {
          ...prevState.meta,
          totalItems: prevState.meta.totalItems + 1,
          itemCount: prevState.meta.itemCount + 1,
        };
        return {
          ...prevState,
          items: [...prevState.items, invoice],
          meta: updatedMeta,
        };
      });

      setSuccessMessage(t('Successfully created invoice'));
    } catch (err) {
      setErrorMessage(t('Error while creating invoice'));
    }
  };

  return (
    <Dialog
      open={open}
      title={t('Create invoice')}
      onSubmit={handleSubmit(onSubmit)}
      content={
        <>
          <Box display="flex" gap={2} mb={2}>
            <Box>
              {startDate ? format(new Date(startDate), 'dd MMMM Y') : null}
            </Box>
            <Box>{endDate ? format(new Date(endDate), 'dd MMMM Y') : null}</Box>
          </Box>

          <Box display="flex" gap={2} mb={2}>
            <Input
              name="startDate"
              label={t('Start date')}
              error={!!errors.startDate?.message}
              helperText={errors.startDate?.message}
              control={control}
              type="date"
            />

            <Input
              name="endDate"
              label={t('End date')}
              error={!!errors.endDate?.message}
              helperText={errors.endDate?.message}
              control={control}
              type="date"
            />
          </Box>
        </>
      }
      onClose={onClose}
      actions={
        <Box>
          <Button
            type="submit"
            label={t('Create')}
            withLoader
            loading={isSubmitting}
            startIcon={<AddRoundedIcon />}
            fullWidth
          />
        </Box>
      }
    />
  );
}
