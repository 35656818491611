import { request } from '../request';
import { Donation } from 'types/donations';
import {
  ExchangeRate,
  PaginatedResponse,
  PostLiqpayPaymentIntentDTO,
  PostMonobankPaymentIntentDTO,
  PostSponsorDTO,
  PostWayforpayPaymentIntentDTO,
  PostWhitePayPaymentIntentDTO,
  WayforpayPaymentIntentResponse,
} from 'services/api/types';

export const postLiqpayPaymentIntent = async (
  donate: PostLiqpayPaymentIntentDTO,
): Promise<{ data: string; signature: string }> => {
  const { data } = await request({
    method: 'post',
    url: '/donations/liqpay',
    data: { ...donate },
  });

  return data;
};

export const postWayforpayPaymentIntent = async (
  formData: PostWayforpayPaymentIntentDTO,
): Promise<WayforpayPaymentIntentResponse> => {
  const { data } = await request({
    method: 'post',
    url: '/donations/wayforpay',
    data: { ...formData },
  });

  return data;
};

export const postMonobankaPaymentIntent = async (
  formData: PostMonobankPaymentIntentDTO,
): Promise<{ paymentUrl: string }> => {
  const { data } = await request({
    method: 'post',
    url: '/donations/monobanka',
    data: { ...formData },
  });

  return data;
};

export const postWhitePayPaymentIntent = async (
  formData: PostWhitePayPaymentIntentDTO,
): Promise<{ paymentUrl: string }> => {
  const { data } = await request({
    method: 'post',
    url: '/donations/whitepay',
    data: { ...formData },
  });

  return data;
};

export const postCryptomusPaymentIntent = async (
  formData: PostWhitePayPaymentIntentDTO,
): Promise<{ paymentUrl: string }> => {
  const { data } = await request({
    method: 'post',
    url: '/donations/cryptomus',
    data: { ...formData },
  });

  return data;
};

export const listDonations = async (
  url = '/donations',
): Promise<PaginatedResponse<Donation>> => {
  const { data } = await request({
    url,
    withCredentials: true,
  });

  return data;
};

export const listSearchDonations = async ({
  search = '',
  filters = {},
  url = '/donations',
}: {
  url?: string;
  search?: string;
  filters?: Record<string, any>;
}): Promise<PaginatedResponse<Donation>> => {
  const params = {} as any;

  if (search) {
    params.search = search.trim();
  }

  Object.keys(filters).forEach((filter) => {
    if (filters[filter].length) {
      params[filter] = filters[filter].join(',');
    }
  });

  const { data } = await request({
    url,
    withCredentials: true,
    params,
  });
  return data;
};

export const getExchangeRates = async (): Promise<ExchangeRate> => {
  const { data } = await request({
    url: 'donations/exchange-rate',
    withCredentials: true,
  });

  return data;
};

export const addTransferredDonation = async (
  form: PostSponsorDTO,
): Promise<void> => {
  const { data } = await request({
    method: 'post',
    url: '/donations/transfers',
    data: form,
  });

  return data;
};

export const getTransferredDonations = async (): Promise<Donation[]> => {
  const { data } = await request({
    url: '/donations/transfers',
    withCredentials: true,
  });

  return data;
};

export const deleteTransferredDonation = async (id: string): Promise<void> => {
  await request({
    method: 'delete',
    url: `/donations/transfers/${id}`,
  });
};
