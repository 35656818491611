import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getGoal } from 'services/api/goals';
import { Goal } from 'types/goals';
import Alert from 'view/components/Alert';
import GoalBar from 'view/components/GoalBar';
import { Donation } from 'types/donations';
import { useTranslation } from 'react-i18next';
import { User } from 'types/users';
import { getExchangeRates } from 'services/api/donations';
import { Currency, ExchangeRate } from 'services/api/types';
import useSocket from 'hooks/useSocket';

export default function GoalPage() {
  const { goalId } = useParams();
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const [exchangeRates, setExchangeRates] = useState<ExchangeRate | null>(null);
  const [goalData, setGoalData] = useState<
    (Goal & Pick<User, 'cipherId'>) | null
  >(null);
  const { socket } = useSocket(goalData?.cipherId);

  useEffect(() => {
    const fetchGoalData = async (goalId: string) => {
      try {
        const data = await getGoal(goalId);

        if (!data.active) {
          return setError(t('Goal is not active'));
        }

        const rates = await getExchangeRates();

        setGoalData(data);
        setExchangeRates(rates);
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        }
      }
    };

    if (goalId) {
      fetchGoalData(goalId);
    }
  }, [goalId]);

  useEffect(() => {
    if (goalData?.cipherId && socket) {
      function onDonation(donation: Donation) {
        if (donation.goalId === goalId) {
          setGoalData((prevGoalData) => {
            if (!prevGoalData || !exchangeRates) {
              return null;
            }
            const uahAmount =
              donation.currency !== Currency.UAH
                ? exchangeRates[donation.currency] * donation.amount
                : donation.amount;
            return {
              ...prevGoalData,
              accumulated: prevGoalData.accumulated + uahAmount,
            };
          });
        }
      }

      socket.on('donation', onDonation);

      return () => {
        socket.off('donation', onDonation);
      };
    }
  }, [socket, goalData?.cipherId]);

  return (
    <>
      <Alert open={!!error} type="error" label={error} />
      {goalData && <GoalBar goal={goalData} />}
    </>
  );
}
