import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddSponsorValidationSchema } from 'constraints';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Dialog from 'view/components/Dialog';
import Button from 'view/components/Button';
import Input from 'view/components/Form/Input';
import Typography from '@mui/material/Typography';
import { Currency, CurrencyLabelMap } from 'services/api/types';
import { addTransferredDonation } from 'services/api/donations';
import useAuth from 'hooks/auth';

export type AddTransferredDonationFormData = {
  amount: number;
  sponsorName: string;
};

type AddTransferredDonationDialogProps = {
  open: boolean;
  onClose: () => void;
};

export default function AddTransferredDonationDialog({
  open,
  onClose,
}: AddTransferredDonationDialogProps) {
  const { setErrorMessage, setSuccessMessage } = useAuth();
  const { t } = useTranslation();

  const initialFormData = {
    resolver: yupResolver(AddSponsorValidationSchema(t)),
    defaultValues: {
      amount: 0,
      sponsorName: '',
    },
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<AddTransferredDonationFormData>(initialFormData);

  const onSubmit = async (data: AddTransferredDonationFormData) => {
    const form = {
      ...data,
      currency: Currency.UAH,
    };

    try {
      await addTransferredDonation(form);

      onClose();
      setSuccessMessage(t('Successfully updated sponsor data'));
    } catch (err) {
      setErrorMessage(t('Error while updating sponsor data'));
    }
  };

  const Content = (
    <form>
      <Box mb={2}>
        <Input
          name="sponsorName"
          label={t('Sponsor name')}
          type="text"
          error={!!errors.sponsorName?.message}
          helperText={errors.sponsorName?.message}
          control={control}
        />
      </Box>
      <Box mb={2}>
        <Input
          name="amount"
          type="number"
          label={t('Amount')}
          error={!!errors.amount?.message}
          helperText={errors.amount?.message}
          control={control}
          startAdornment={
            <Typography variant="body2">
              {CurrencyLabelMap[Currency.UAH]}
            </Typography>
          }
        />
      </Box>
    </form>
  );

  const Actions = (
    <>
      <Button
        label={t('Cancel')}
        variant="text"
        color="secondary"
        disabled={isSubmitting}
        onClick={onClose}
      />
      <Button
        label={t('Save')}
        withLoader
        loading={isSubmitting}
        disabled={!isDirty || isSubmitting}
        onClick={() => handleSubmit(onSubmit)()}
      />
    </>
  );

  return (
    <Dialog
      open={open}
      title={t('Add a sponsor')}
      content={Content}
      onClose={onClose}
      actions={Actions}
    />
  );
}
