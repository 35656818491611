import { formatUAHCurrency } from 'tools';
import Stack from '@mui/material/Stack';
import React from 'react';
import { Statistics } from 'types/statistics';
import SummaryItem from 'view/pages/Statistics/components/SummaryItem';
import { useTranslation } from 'react-i18next';

type StatisticSummaryProps = {
  data: Statistics;
};

export default function StatisticSummary({ data }: StatisticSummaryProps) {
  const { t } = useTranslation();

  return (
    <Stack direction="row" mb={6} gap={1.5} flexWrap="wrap">
      <SummaryItem
        value={formatUAHCurrency(data.sumOfAllDonates)}
        description={t('totalDonationsAmount')}
      />

      <SummaryItem
        value={formatUAHCurrency(data.theBiggestDonationAmount)}
        description={t('theBiggestDonation')}
      />

      <SummaryItem
        value={formatUAHCurrency(data.averageDonationAmount)}
        description={t('averageDonationAmount')}
      />

      <SummaryItem
        value={data.totalDonations}
        description={t('Total donations')}
      />

      <SummaryItem
        value={data.uniqSponsorsCount}
        description={t('Number of sponsors')}
      />

      <SummaryItem
        value={data.totalVoiceoverDonations}
        description={t('Donations with voiceover')}
      />
    </Stack>
  );
}
