import React, { ReactElement, useState } from 'react';
import { FieldArray, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { v4 as uuidv4 } from 'uuid';
import Box from '@mui/material/Box';
import Button from 'view/components/Button';
import Input from 'view/components/Form/Input';
import FormHeader from 'view/components/Form/Header';
import { SocialNetworkTypes, User } from 'types/users';
import { StyledFormContainer, StyledFormActions } from '../../styled';
import Grid from '@mui/material/Grid2';
import useUser from 'hooks/user';
import { ChangeSocialNetworksValidationSchema } from 'constraints';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/auth';
import { updateUserData } from 'services/api/user';
import IconButton from '@mui/material/IconButton';
import { red } from 'view/theme/colors';
import Tooltip from '@mui/material/Tooltip';
import TrashIcon from 'view/components/icons/Trash';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TelegramIcon from '@mui/icons-material/Telegram';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import TwitchIcon from 'view/components/icons/Twitch';
import TiktokIcon from 'view/components/icons/Tiktok';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

type ChangeSocialNetworksFormData = {
  socialNetworks: {
    label: string;
    value: SocialNetworkTypes;
    url: string;
    _id: string;
  }[];
};

type ChangeSocialNetworksProps = {
  onCancel: () => void;
  onSaved: ({ socialNetworks }: Pick<User, 'socialNetworks'>) => void;
};

const defaultValues = {
  label: '',
  value: '',
  url: '',
  _id: uuidv4(),
};

export const SOCIAL_NETWORKS_MEDIA_MAP: Record<
  SocialNetworkTypes,
  { icon: ReactElement; label: string }
> = {
  [SocialNetworkTypes.INSTAGRAM]: {
    icon: <InstagramIcon />,
    label: 'Instagram',
  },
  [SocialNetworkTypes.TELEGRAM]: {
    icon: <TelegramIcon />,
    label: 'Telegram',
  },
  [SocialNetworkTypes.TIKTOK]: {
    icon: <TiktokIcon />,
    label: 'Tiktok',
  },
  [SocialNetworkTypes.TWITCH]: {
    icon: <TwitchIcon />,
    label: 'Twitch',
  },
  [SocialNetworkTypes.YOUTUBE]: {
    icon: <YouTubeIcon />,
    label: 'YouTube',
  },
};

export default function ChangeSocialNetworks({
  onCancel,
  onSaved,
}: ChangeSocialNetworksProps) {
  const { setErrorMessage, setSuccessMessage } = useAuth();
  const { user } = useUser();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const initialFormData = {
    resolver: yupResolver(ChangeSocialNetworksValidationSchema(t)),
    defaultValues: {
      socialNetworks: user.socialNetworks,
    },
  };

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitting, isDirty, isValidating },
  } = useForm<ChangeSocialNetworksFormData>(initialFormData);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'socialNetworks',
    keyName: '_id',
  });

  const watchFields = watch('socialNetworks');

  const onSubmit = async (data: ChangeSocialNetworksFormData) => {
    setErrorMessage('');

    const filteredData = data.socialNetworks.map(({ url, value }) => ({
      url,
      value,
    }));

    try {
      await updateUserData(user._id, {
        socialNetworks: filteredData,
      });
      onSaved({ ...user, socialNetworks: filteredData });

      reset(data);
      setSuccessMessage(t('Successfully updated social network links'));
    } catch (err) {
      setErrorMessage(t('Error while updating social network links'));
    }
  };

  const submitDisabled =
    Boolean(Object.keys(errors).length) || !isDirty || isSubmitting;

  const onAppendClick = (value: SocialNetworkTypes) => {
    append({
      ...defaultValues,
      value,
      label: SOCIAL_NETWORKS_MEDIA_MAP[value].label,
    });
  };

  const getIndexById = (
    fields: FieldArray<ChangeSocialNetworksFormData>[],
    _id: string,
  ) => fields.findIndex((field) => field._id === _id);

  const options = Object.values(SocialNetworkTypes).filter(
    (key) => !watchFields.some((field) => field.value === key),
  );

  return (
    <StyledFormContainer>
      <FormHeader
        title={t('Social networks')}
        description={t(
          'Update your social media links. These links will be visible on the donation page',
        )}
      />
      <Grid container spacing={8}>
        <Grid size={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box display="flex" flexDirection="column" mb={2}>
              {!fields.length && (
                <Typography variant="body2">
                  {t('No links to your social networks. Add a new one below')}
                </Typography>
              )}
              {fields.map((field) => {
                const { _id, value } = field;
                const index = getIndexById(fields, _id);
                const dataItem = SOCIAL_NETWORKS_MEDIA_MAP[value];

                return (
                  <Box
                    key={field._id}
                    display="grid"
                    gridTemplateColumns={{
                      xs: 'min-content 5rem auto min-content',
                      sm: 'min-content 5rem auto min-content',
                    }}
                    alignItems="center"
                    gap={2}
                    mb={2}
                  >
                    {dataItem.icon}
                    {dataItem.label}
                    <Input
                      name={`socialNetworks.${index}.url`}
                      type="url"
                      label={t('socialNetworkLink', {
                        socialNetwork: dataItem.label,
                      })}
                      error={!!errors.socialNetworks?.[index]?.url}
                      helperText={errors.socialNetworks?.[index]?.url?.message}
                      control={control}
                    />

                    <Box>
                      <Tooltip arrow title={t('Remove row')}>
                        <IconButton onClick={() => remove(index)}>
                          <TrashIcon color={red} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                );
              })}
            </Box>

            <StyledFormActions>
              <Button
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                disabled={options.length === 0}
                variant="text"
                onClick={handleClick}
                endIcon={<KeyboardArrowDownRoundedIcon />}
                label={t('Add')}
              />
              <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {options.map((key) => {
                  return (
                    <MenuItem
                      key={key}
                      onClick={() => {
                        handleClose();
                        onAppendClick(key);
                      }}
                      disableRipple
                    >
                      <ListItemIcon sx={{ marginRight: 1 }}>
                        {SOCIAL_NETWORKS_MEDIA_MAP[key].icon}
                      </ListItemIcon>
                      <ListItemText>
                        {SOCIAL_NETWORKS_MEDIA_MAP[key].label}
                      </ListItemText>
                    </MenuItem>
                  );
                })}
              </Menu>

              <Button
                withLoader
                type="submit"
                label={t('Save')}
                loading={isSubmitting || isValidating}
                disabled={submitDisabled}
              />
              <Button
                label={t('Back')}
                variant="text"
                color="secondary"
                onClick={onCancel}
              />
            </StyledFormActions>
          </form>
        </Grid>
      </Grid>
    </StyledFormContainer>
  );
}
