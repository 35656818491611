import React from 'react';
import { StyledContainer } from 'view/pages/Home/styled';
import PageHeader from 'view/components/PageHeader';
import Link from 'view/components/Link';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { formatCurrency } from 'tools';
import { Currency } from 'services/api/types';
import Grid from '@mui/material/Grid2';
import { PAYMENT_METHOD_MEDIA_MAP } from 'view/pages/AccountSettings/components/ChangePaymentMethods';
import { PaymentMethodTypes } from 'types/users';

export default function PricingPage() {
  const { t } = useTranslation();
  const options = [
    {
      label: PAYMENT_METHOD_MEDIA_MAP[PaymentMethodTypes.WAYFORPAY].shortLabel,
      fee: '1.5%',
      description: t('wayforpayPricingDescription'),
    },
    {
      label: PAYMENT_METHOD_MEDIA_MAP[PaymentMethodTypes.MONOBANKA].shortLabel,
      fee: '1.5%',
      description: t('monobankaPricingDescription'),
    },
    {
      label: PAYMENT_METHOD_MEDIA_MAP[PaymentMethodTypes.WHITEPAY].shortLabel,
      fee: '1.5%',
      description: t(
        'A payment system fee of {{fee}} is possible. Withdrawals are made automatically.',
        { fee: formatCurrency(1, Currency.USDT) },
      ),
    },
  ];

  return (
    <StyledContainer component="main">
      <PageHeader title={t('pricing')} />
      <Typography variant="h2" mb={1}>
        {t('Fee')}
      </Typography>
      <Box mb={6}>
        <Typography variant="body2" mb={6}>
          {t('The size of the commission varies by payment system.')}
        </Typography>
        <Grid container flexWrap="wrap" spacing={4} mb={4}>
          {options.map((item) => {
            return (
              <Grid size={{ xs: 12, sm: 4 }} key={item.label}>
                <Card variant="outlined" sx={{ minHeight: '12rem' }}>
                  <CardContent>
                    <Typography
                      gutterBottom
                      sx={{ color: 'text.secondary', fontSize: 14 }}
                    >
                      {item.label}
                    </Typography>
                    <Typography variant="h5" sx={{ lineHeight: 2 }}>
                      {item.fee}
                    </Typography>
                    <Typography variant="body2">{item.description}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Box>

      <Typography variant="body2" mb={2}>
        {t('Pricing description')}
      </Typography>
      <Typography variant="body2" mb={4}>
        {t('If you have any additional questions, please contact us by e-mail')}{' '}
        <Link to="mailto:2donate.me@gmail.com" label="2donate.me@gmail.com" />.
      </Typography>
    </StyledContainer>
  );
}
