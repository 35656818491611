import { request } from 'services/api/request';
import { Invoice, InvoiceDetails } from 'types/invoices';
import { PaginatedResponse } from 'services/api/types';

export const getUnbilledInvoice = async (): Promise<InvoiceDetails> => {
  const { data } = await request({
    url: `/invoices/unbilled`,
  });
  return data;
};

export const listInvoices = async (
  url = '/invoices',
): Promise<PaginatedResponse<Invoice>> => {
  const { data } = await request({
    url,
  });

  return data;
};

export const getInvoice = async (
  invoiceId: string,
): Promise<InvoiceDetails> => {
  const { data } = await request({
    url: `/invoices/${invoiceId}`,
  });

  return data;
};

export const createInvoiceUrl = async (
  invoiceId: string,
): Promise<{ invoiceUrl: string }> => {
  const { data } = await request({
    method: 'post',
    url: `/invoices/${invoiceId}/url`,
  });

  return data;
};
