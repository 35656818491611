import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddMediaValidationSchema } from 'constraints';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Dialog from 'view/components/Dialog';
import Button from 'view/components/Button';
import Input from 'view/components/Form/Input';
import useAuth from 'hooks/auth';
import { extractYouTubeVideoId } from 'tools';

export type UpdateDonationThresholdDialogFormData = {
  url: string;
};

type AddMediaDialogProps = {
  open: boolean;
  onClose: () => void;
  youtubeUrl?: string;
  onSave: (value: string) => void;
};

export default function AddMediaDialog({
  open,
  onSave,
  youtubeUrl,
  onClose,
}: AddMediaDialogProps) {
  const { setSuccessMessage } = useAuth();
  const { t } = useTranslation();

  const initialFormData = {
    resolver: yupResolver(AddMediaValidationSchema(t)),
    defaultValues: {
      url: youtubeUrl || '',
    },
  };

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<UpdateDonationThresholdDialogFormData>(initialFormData);
  const url = watch('url');
  const videoId = extractYouTubeVideoId(url);

  const onSubmit = (data: UpdateDonationThresholdDialogFormData) => {
    onSave(data.url);
    onClose();
    setSuccessMessage(t('Successfully added YouTube video'));
  };

  const onRemove = () => {
    onSave('');
    onClose();
  };

  const Content = (
    <form>
      <Box gap={4} mb={2}>
        <Input
          fullWidth
          name="url"
          label={t('Link')}
          type="string"
          error={!!errors.url}
          helperText={errors.url?.message}
          control={control}
        />
      </Box>
      {videoId && (
        <iframe
          width="100%"
          style={{ border: 'none' }}
          height="240px"
          src={`https://www.youtube.com/embed/${videoId}?autoplay=1&controls=0`}
          allow="autoplay; encrypted-media"
          allowFullScreen
        />
      )}
    </form>
  );

  const Actions = (
    <>
      <Button
        label={t('Cancel')}
        variant="text"
        color="secondary"
        disabled={isSubmitting}
        onClick={onClose}
      />
      <Button
        label={t('Remove')}
        withLoader
        variant="text"
        loading={isSubmitting}
        disabled={!youtubeUrl || isSubmitting}
        onClick={onRemove}
      />
      <Button
        label={t('Add')}
        withLoader
        loading={isSubmitting}
        disabled={!isDirty || isSubmitting}
        onClick={() => handleSubmit(onSubmit)()}
      />
    </>
  );

  return (
    <Dialog
      open={open}
      title={t('Add YouTube video')}
      content={Content}
      onClose={onClose}
      actions={Actions}
    />
  );
}
