import React, { useState } from 'react';
import Avatar from 'view/components/Avatar';
import Button from 'view/components/Button';
import useUser from 'hooks/user';
import {
  StyledFormActions,
  StyledFormContainer,
} from 'view/pages/AccountSettings/styled';
import { useTranslation } from 'react-i18next';
import {
  deleteProfilePicture as deleteProfilePictureRequest,
  updatePictureProfile,
} from 'services/api/user';
import TrashIcon from 'view/components/icons/Trash';
import { black87 } from 'view/theme/colors';
import useAuth from 'hooks/auth';
import ConfirmationDialog from 'view/components/ConfirmationDialog';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import FormHeader from 'view/components/Form/Header';
import CircularProgress from '@mui/material/CircularProgress';
import { MAX_FILE_SIZE } from 'view/constants';

const StyledIconWrapper = styled(Box)`
  position: absolute;
  display: flex;
  z-index: 1;
  transition: 0.1s ease-out;
`;

const StyledUploadWrapper = styled(Box)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 6rem;
  border-radius: 100%;

  .MuiAvatar-root:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${black87};
    opacity: 0;
    transition: 0.1s ease-out;
  }

  svg {
    opacity: 0;
  }

  &:hover {
    .MuiAvatar-root:after {
      opacity: 0.4;
    }
    svg {
      opacity: 1;
    }
  }
`;

type ChangeProfilePictureProps = {
  onCancel: () => void;
};

export default function ChangeProfilePicture({
  onCancel,
}: ChangeProfilePictureProps) {
  const { setErrorMessage, setSuccessMessage } = useAuth();
  const { t } = useTranslation();
  const { user, setUser } = useUser();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        setErrorMessage(t('Maximum file size is 2 MB'));
        e.target.value = '';
        return;
      }

      const formData = new FormData();
      formData.append('profilePictureFile', file);

      try {
        setIsSubmitting(true);
        const { profilePictureUrl } = await updatePictureProfile(formData);
        setSuccessMessage(t('Successfully updated a profile picture'));

        setUser({ ...user, profilePictureUrl });
      } catch (err) {
        setErrorMessage(t('Error while updating profile picture'));
      } finally {
        setIsSubmitting(false);
        e.target.value = '';
      }
    }
  };

  const deleteProfilePicture = async () => {
    try {
      setIsDeleteDialogOpen(false);
      setIsSubmitting(true);
      await deleteProfilePictureRequest();
      setSuccessMessage(t('Successfully deleted a profile picture'));
      setUser({ ...user, profilePictureUrl: '' });
    } catch (err) {
      setErrorMessage(t('Error while deleting a profile picture'));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <StyledFormContainer>
      <FormHeader
        title={t('Profile picture')}
        description={t('This picture is used on the donation page')}
      />
      <Box sx={{ m: 1, position: 'relative' }}>
        <label htmlFor="avatar-upload">
          <StyledUploadWrapper>
            <StyledIconWrapper>
              <PhotoCameraIcon />
            </StyledIconWrapper>
            <Avatar
              contentCreatorName={user.contentCreatorName}
              src={user.profilePictureUrl}
              size={6}
            />
          </StyledUploadWrapper>
          {isSubmitting && (
            <CircularProgress
              size={108}
              thickness={1}
              sx={{
                position: 'absolute',
                top: -6,
                left: -6,
              }}
            />
          )}
        </label>
      </Box>

      <input
        type="file"
        accept="image/*"
        disabled={isSubmitting}
        onChange={handleFileChange}
        style={{ display: 'none' }}
        id="avatar-upload"
      />
      <StyledFormActions>
        <Button
          label={t('Delete')}
          variant="text"
          color="error"
          disabled={isSubmitting || !user.profilePictureUrl}
          startIcon={<TrashIcon />}
          onClick={() => setIsDeleteDialogOpen(true)}
        />
        <Button
          label={t('Back')}
          variant="text"
          disabled={isSubmitting}
          color="secondary"
          onClick={onCancel}
        />
      </StyledFormActions>
      <ConfirmationDialog
        open={isDeleteDialogOpen}
        text={t('Are you sure you want to delete your profile picture?')}
        confirmText={t('Delete')}
        color="error"
        onConfirm={deleteProfilePicture}
        onClose={() => setIsDeleteDialogOpen(false)}
      />
    </StyledFormContainer>
  );
}
