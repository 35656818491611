import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import InstagramIcon from '@mui/icons-material/Instagram';
// import FacebookIcon from '@mui/icons-material/Facebook';
import TelegramIcon from '@mui/icons-material/Telegram';
import LogoIcon from 'view/components/icons/Logo';
import Link from 'view/components/Link';
import {
  APP_PATH,
  CONTACTS_PATH,
  PRICING_PATH,
  PRIVACY_POLICY_PATH,
  TERMS_PATH,
} from 'view/routes';
import Divider from '@mui/material/Divider';
import VisaIcon from 'view/components/icons/Visa';
import MastercardIcon from 'view/components/icons/Mastercard';
import LanguageChange from 'view/components/LanguageChange';
import { useTranslation } from 'react-i18next';
import ApplePayIcon from 'view/components/icons/ApplePay';
import GooglePayIcon from 'view/components/icons/GooglePay';
import BitcoinPayIcon from 'view/components/icons/BitcoinPay';
import CustomizedSwitches from 'view/components/Switch';
import useTheme from 'hooks/theme';
import Tooltip from '@mui/material/Tooltip';
import MadeInUkraineLogo from 'view/components/icons/MadeInUkraine';

export default function Footer() {
  const { t } = useTranslation();
  const { toggleTheme, isDarkMode } = useTheme();

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 2, sm: 4 },
        py: { xs: 4, sm: 5 },
      }}
    >
      <Divider sx={{ width: '100%' }} />
      <Stack
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          justifyContent: 'space-between',
          gap: 2,
        }}
      >
        <Stack spacing={1}>
          <Box>
            <Box mb={1}>
              <Link to={APP_PATH} label={<LogoIcon />} />
            </Box>

            <Typography variant="body2">{t('siteSlogan')}</Typography>
            <Box mb={2}>
              <Link
                to="mailto:2donate.me@gmail.com"
                label="2donate.me@gmail.com"
              />
            </Box>
            <Box mb={1}>
              <MadeInUkraineLogo />
            </Box>

            <Typography variant="body2" mb={1}>
              © {new Date().getFullYear()}. {t('All rights reserved.')}
            </Typography>
          </Box>

          <Box display="flex" gap={1}>
            <MastercardIcon />
            <VisaIcon />
            <ApplePayIcon />
            <GooglePayIcon />
            <BitcoinPayIcon />
          </Box>
        </Stack>

        <Stack spacing={1}>
          <Typography variant="body2" fontWeight={600}>
            {t('information')}
          </Typography>
          <Link to={PRICING_PATH} label={t('pricing')} />
          <Link to={CONTACTS_PATH} label={t('Contacts')} />
          <Link to={PRIVACY_POLICY_PATH} label={t('privacyPolicy')} />
          <Link to={TERMS_PATH} label={t('termsOfUse')} />
        </Stack>

        <Stack direction="row" spacing={1}>
          {/*<Box>*/}
          {/*  <IconButton*/}
          {/*    color="inherit"*/}
          {/*    href="https://github.com/mui"*/}
          {/*    aria-label="GitHub"*/}
          {/*  >*/}
          {/*    <FacebookIcon />*/}
          {/*  </IconButton>*/}
          {/*</Box>*/}
          {/*<Box>*/}
          {/*  <IconButton*/}
          {/*    color="inherit"*/}
          {/*    href="https://x.com/MaterialUI"*/}
          {/*    aria-label="X"*/}
          {/*  >*/}
          {/*    <InstagramIcon />*/}
          {/*  </IconButton>*/}
          {/*</Box>*/}
          <Box>
            <Tooltip arrow title="Instagram">
              <IconButton
                color="inherit"
                href="https://www.instagram.com/2donate"
                aria-label="Instagram"
              >
                <InstagramIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Box>
            <Tooltip arrow title={t('Telegram discussion')}>
              <IconButton
                color="inherit"
                href="https://t.me/+WBmBrEmZrotiNjdi"
                aria-label="Telegram"
              >
                <TelegramIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Stack>
        <Box
          sx={{
            display: { xs: 'flex', sm: 'none' },
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: 4,
          }}
          mb={{ xs: 4, sm: 0 }}
        >
          <Box>
            <LanguageChange />
          </Box>
          <CustomizedSwitches onChange={toggleTheme} checked={isDarkMode} />
        </Box>
        <Box>
          <Box
            sx={{
              display: { xs: 'none', sm: 'flex' },
              alignItems: 'center',
              gap: 4,
            }}
          >
            <LanguageChange />
            <Box>
              <CustomizedSwitches onChange={toggleTheme} checked={isDarkMode} />
            </Box>
          </Box>
        </Box>
      </Stack>
    </Container>
  );
}
