import Accordion from '../Accordion';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';
import {
  getUserInfo,
  listInvoices,
  listWithdrawals,
  processUserWithdrawal,
  removeUserInvoice,
  removeUserWithdrawal,
  updateUser,
  updateUserWithdrawal,
} from 'services/api/admin/user';
import { User } from 'types/users';
import { Order } from 'view/components/Table/components/TableHeader';
import Search from 'view/components/Search';
import ConfirmationDialog from 'view/components/ConfirmationDialog';
import Avatar from 'view/components/Avatar';
import { DONATE_PATH } from 'view/routes';
import Stack from '@mui/material/Stack';
import MuiLink from '@mui/material/Link';
import CopyButton from 'view/components/CopyButton';
import { formatCurrency } from 'tools';
import { useTranslation } from 'react-i18next';
import WithdrawalsTable from 'view/pages/AdminUsers/components/WithdrawalsTable';
import { Withdrawal, WithdrawalStatus } from 'types/withdrawal';
import { Currency, PaginatedResponse } from 'services/api/types';
import { StylePageHeader } from 'view/components/PageHeader';
import Typography from '@mui/material/Typography';
import Button from 'view/components/Button';
import TrashIcon from 'view/components/icons/Trash';
import useAuth from 'hooks/auth';
import IconButton from '@mui/material/IconButton';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { SOCIAL_NETWORKS_MEDIA_MAP } from 'view/pages/AccountSettings/components/ChangeSocialNetworks';
import Chip from '@mui/material/Chip';
import Switch from '@mui/material/Switch';
import { Invoice } from 'types/invoices';
import InvoicesTable from 'view/pages/AdminUsers/components/InvoicesTable';
import AddInvoiceModal from 'view/pages/AdminUsers/components/AddInvoiceModal';
import StatisticsPage from 'view/pages/AdminUsers/components/UserDetails/components/Statistics';
import PortraitRoundedIcon from '@mui/icons-material/PortraitRounded';
import DriveFileRenameOutlineRoundedIcon from '@mui/icons-material/DriveFileRenameOutlineRounded';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import MoneyRoundedIcon from '@mui/icons-material/MoneyRounded';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import InsertLinkRoundedIcon from '@mui/icons-material/InsertLinkRounded';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import AdminUserInfoRow from 'view/pages/AdminUsers/components/AdminUserInfoRow';

type UserDetailsProps = {
  userId: string;
  onEditUser: (user: User) => void;
  setUserToDelete: (user: User) => void;
};

export default function UserDetails({
  userId,
  onEditUser,
  setUserToDelete,
}: UserDetailsProps) {
  const { setErrorMessage, setSuccessMessage } = useAuth();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [page, setPage] = useState(1);
  const [sorting, setSorting] = useState<[string, Order]>([
    'createdAt',
    'desc',
  ]);
  const [user, setUser] = useState<User | null>(null);

  const [isAddInvoiceModalOpen, setAddInvoiceModalOpen] = useState(false);
  const [fieldToEdit, setFieldToEdit] = useState<string | null>(null);

  const [withdrawalsData, setWithdrawalsData] = useState({
    items: [] as Array<Withdrawal>,
    meta: { totalItems: 0 },
  } as PaginatedResponse<Withdrawal>);

  const [invoicesData, setInvoicesData] = useState({
    items: [] as Array<Invoice>,
    meta: { totalItems: 0 },
  } as PaginatedResponse<Invoice>);

  const [withdrawalToProcess, setWithdrawalToProcess] =
    useState<Withdrawal | null>(null);
  const [withdrawalToRefuse, setWithdrawalToRefuse] =
    useState<Withdrawal | null>(null);
  const [withdrawalToRemove, setWithdrawalToRemove] =
    useState<Withdrawal | null>(null);

  const [invoiceToRemove, setInvoiceToRemove] = useState<Invoice | null>(null);

  const handleChangePage = async (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    const getUser = async () => {
      const userData = await getUserInfo(userId);
      setUser(userData);
    };
    getUser();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchWithdrawals = useCallback(
    debounce(
      async (
        searchQuery: string,
        sorting: [string, 'asc' | 'desc'],
        page: number,
      ) => {
        setLoading(true);

        try {
          const searchResult = await listWithdrawals(
            {
              searchQuery,
              orderBy: sorting[0],
              orderDirection: sorting[1],
              page,
            },
            userId,
          );
          setWithdrawalsData(searchResult);
        } catch (err) {
          setErrorMessage('Error loading withdrawals');
        } finally {
          setLoading(false);
        }
      },
      500,
    ),
    [userId],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchInvoices = useCallback(
    debounce(
      async (
        searchQuery: string,
        sorting: [string, 'asc' | 'desc'],
        page: number,
      ) => {
        setLoading(true);

        try {
          const searchResult = await listInvoices(
            {
              searchQuery,
              orderBy: sorting[0],
              orderDirection: sorting[1],
              page,
            },
            userId,
          );
          setInvoicesData(searchResult);
        } catch (err) {
          setErrorMessage('Error loading invoices');
        } finally {
          setLoading(false);
        }
      },
      500,
    ),
    [userId],
  );

  useEffect(() => {
    setLoading(true);
    searchInvoices(searchQuery, sorting, page);
    // searchWithdrawals(searchQuery, sorting, page);
  }, [searchQuery, sorting, page]);

  const onRemoveWithdrawalConfirmation = async (withdrawal: Withdrawal) => {
    try {
      await removeUserWithdrawal(user?._id || '', withdrawal._id);
      setWithdrawalsData((prevState) => {
        const updatedItems = prevState.items.filter(
          (item) => item._id !== withdrawal._id,
        );
        const updatedMeta = {
          ...prevState.meta,
          totalItems: prevState.meta.totalItems - 1,
          itemCount: prevState.meta.itemCount - 1,
        };
        return {
          ...prevState,
          items: updatedItems,
          meta: updatedMeta,
        };
      });
      setSuccessMessage('Successfully removed withdrawal');
      setWithdrawalToRemove(null);
    } catch (err) {
      setErrorMessage('Error while deleting withdrawal');
    } finally {
      setLoading(false);
    }
  };

  const onProcessWithdrawalConfirmation = async (withdrawal: Withdrawal) => {
    try {
      await processUserWithdrawal(user?._id || '', withdrawal._id);
      setWithdrawalsData((prevState) => {
        const updatedItems = prevState.items.map((item) => {
          if (item._id === withdrawal._id) {
            return { ...item, status: WithdrawalStatus.SUCCESS };
          }
          return item;
        });
        return {
          ...prevState,
          items: updatedItems,
        };
      });
      const newUserData = {
        ...user,
        balance: {
          ...(user as User).balance,
          [withdrawal.currency]:
            (user as User).balance[withdrawal.currency] - withdrawal.amount,
        },
      } as User;

      setUser(newUserData);
      onEditUser(newUserData);
      setSuccessMessage('Successfully processed withdrawal');
      setWithdrawalToProcess(null);
    } catch (err) {
      setErrorMessage('Error while processing withdrawal');
    } finally {
      setLoading(false);
    }
  };

  const onRefuseWithdrawalConfirmation = async (withdrawal: Withdrawal) => {
    try {
      await updateUserWithdrawal(user?._id || '', withdrawal._id, {
        status: WithdrawalStatus.FAILED,
      });
      setWithdrawalsData((prevState) => {
        const updatedItems = prevState.items.map((item) => {
          if (item._id === withdrawal._id) {
            return { ...item, status: WithdrawalStatus.FAILED };
          }
          return item;
        });
        return {
          ...prevState,
          items: updatedItems,
        };
      });
      setWithdrawalToRefuse(null);
    } catch (err) {
      setErrorMessage('Error while updating withdrawal');
    } finally {
      setLoading(false);
    }
  };

  const onRemoveInvoiceConfirmation = async (invoice: Invoice) => {
    try {
      await removeUserInvoice(user?._id || '', invoice._id);
      setInvoicesData((prevState) => {
        const updatedItems = prevState.items.filter(
          (item) => item._id !== invoice._id,
        );
        const updatedMeta = {
          ...prevState.meta,
          totalItems: prevState.meta.totalItems - 1,
          itemCount: prevState.meta.itemCount - 1,
        };
        return {
          ...prevState,
          items: updatedItems,
          meta: updatedMeta,
        };
      });
      setSuccessMessage('Successfully removed invoice');
      setInvoiceToRemove(null);
    } catch (err) {
      setErrorMessage('Error while deleting invoice');
    } finally {
      setLoading(false);
    }
  };

  const handleIsPremiumChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    try {
      const value = event.target.checked;
      await updateUser(userId, { isPremium: value });
      setUser({ ...user, isPremium: value } as User);
      setSuccessMessage('Successfully updated user isPremium');
    } catch (err) {
      setErrorMessage('Error while updating user isPremium');
    }
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    setSearchQuery(e.target.value);
  };

  const onClearSearch = () => setSearchQuery('');

  if (!user) {
    return null;
  }

  const rows = [
    {
      label: t('Profile picture'),
      type: 'profilePicture',
      value: (
        <Avatar
          src={user.profilePictureUrl}
          contentCreatorName={user.contentCreatorName}
        />
      ),
      notEditable: true,
    },
    {
      label: t('Username'),
      type: 'username',
      value: user.contentCreatorName,
      notEditable: true,
    },
    {
      label: t('Premium'),
      type: 'isPremium',
      value: (
        <Box>
          <Switch
            defaultChecked={user?.isPremium}
            checked={user?.isPremium}
            onChange={handleIsPremiumChange}
          />
        </Box>
      ),
      notEditable: true,
    },
    {
      label: t('My page'),
      type: 'donationLink',
      notEditable: true,
      value: (() => {
        const link = `${process.env.REACT_APP_UI_URL}${DONATE_PATH}/${user.contentCreatorName}`;
        return (
          <Stack direction="row" alignItems="center">
            <Box mr={2}>
              <MuiLink
                href={link}
                underline="hover"
                target="_blank"
                rel="noopener noreferrer"
              >
                {link}
              </MuiLink>
              {'  '}
            </Box>

            <CopyButton value={link} />
          </Stack>
        );
      })(),
    },
    {
      label: t('Social networks'),
      type: 'socialNetworks',
      notEditable: true,
      value: (
        <>
          {user?.socialNetworks.map(({ value, url }) => {
            return (
              <IconButton
                edge="start"
                sx={{ marginRight: 2 }}
                href={url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {SOCIAL_NETWORKS_MEDIA_MAP[value].icon}
              </IconButton>
            );
          })}
        </>
      ),
    },
    {
      label: t('Minimum donation amount'),
      type: 'minimumDonationAmount',
      notEditable: true,
      value: (
        <Box display="flex" flexWrap="wrap" alignItems="center" gap={1}>
          {Object.keys(user?.minimumDonationAmount || {}).map((key) => {
            const currency = key as Currency;

            return (
              <Chip
                key={currency}
                label={`${currency} ${formatCurrency(
                  user.minimumDonationAmount[currency] as number,
                  currency,
                )}`}
                variant="outlined"
              />
            );
          })}
        </Box>
      ),
    },
  ];

  const renderEditForm = (fieldToEdit: string) => {
    return null;
  };

  const onAddInvoiceClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAddInvoiceModalOpen(true);
  };

  return (
    <Box p="0 4rem 0 4rem" sx={{ overflowY: 'auto', height: '100%' }}>
      {isAddInvoiceModalOpen && (
        <AddInvoiceModal
          userId={userId}
          setInvoicesData={setInvoicesData}
          open={isAddInvoiceModalOpen}
          onClose={() => setAddInvoiceModalOpen(false)}
        />
      )}
      <Box>
        <StylePageHeader mt={12} mb={4}>
          <Typography variant="h1" mr={2}>
            {user.contentCreatorName}
          </Typography>
          <Button
            label="Delete user"
            variant="text"
            color="error"
            endIcon={<TrashIcon />}
            onClick={() => setUserToDelete(user)}
          />
        </StylePageHeader>
      </Box>
      {withdrawalToRemove && (
        <ConfirmationDialog
          open={!!withdrawalToRemove}
          text={`Are you sure you want to remove ${withdrawalToRemove._id}?`}
          confirmText="Remove"
          onConfirm={() => onRemoveWithdrawalConfirmation(withdrawalToRemove)}
          onClose={() => setWithdrawalToRemove(null)}
        />
      )}
      {withdrawalToProcess && (
        <ConfirmationDialog
          open={!!withdrawalToProcess}
          text={`Are you sure you want to process ${
            withdrawalToProcess._id
          } with amount ${formatCurrency(
            withdrawalToProcess.amount,
            withdrawalToProcess.currency,
          )}? It can not be undone.`}
          confirmText="Process"
          color="primary"
          onConfirm={() => onProcessWithdrawalConfirmation(withdrawalToProcess)}
          onClose={() => setWithdrawalToProcess(null)}
        />
      )}
      {withdrawalToRefuse && (
        <ConfirmationDialog
          open={!!withdrawalToRefuse}
          text={`Are you sure you want to refuse ${
            withdrawalToRefuse._id
          } with amount ${formatCurrency(
            withdrawalToRefuse.amount,
            withdrawalToRefuse.currency,
          )}?`}
          confirmText="Refuse"
          onConfirm={() => onRefuseWithdrawalConfirmation(withdrawalToRefuse)}
          onClose={() => setWithdrawalToRefuse(null)}
        />
      )}

      {invoiceToRemove && (
        <ConfirmationDialog
          open={!!invoiceToRemove}
          text={`Are you sure you want to remove invoice ${formatCurrency(
            invoiceToRemove.amount,
            invoiceToRemove.currency,
          )} with ID: ${invoiceToRemove._id}?`}
          confirmText="Remove"
          onConfirm={() => onRemoveInvoiceConfirmation(invoiceToRemove)}
          onClose={() => setInvoiceToRemove(null)}
        />
      )}

      {fieldToEdit ? (
        renderEditForm(fieldToEdit)
      ) : (
        <>
          <Accordion
            defaultExpanded={true}
            disableGutters={true}
            square={true}
            summary="Information"
            children={
              <List>
                {rows.map(({ type, label, value = '', notEditable }, index) => {
                  return (
                    <AdminUserInfoRow
                      key={type}
                      label={label}
                      value={value}
                      notEditable={notEditable}
                      divider={rows.length !== index + 1}
                      onClick={() => setFieldToEdit(type)}
                    />
                  );
                })}
              </List>
            }
          />

          <Accordion
            defaultExpanded={false}
            disableGutters={true}
            square={true}
            summary={
              <>
                <Box mr={1}>Invoices</Box>
                <IconButton onClick={onAddInvoiceClick}>
                  <AddRoundedIcon />
                </IconButton>
              </>
            }
            children={
              <Box mb={8}>
                <InvoicesTable
                  onRemoveInvoice={(invoice: Invoice) =>
                    setInvoiceToRemove(invoice)
                  }
                  loading={loading}
                  data={invoicesData}
                  page={page}
                  sorting={sorting}
                  onPageChange={handleChangePage}
                  setSorting={setSorting}
                />
              </Box>
            }
          />

          <Accordion
            defaultExpanded={false}
            disableGutters={true}
            square={true}
            summary="Statistics"
            children={
              <Box mb={8}>
                <StatisticsPage userId={user._id} />
              </Box>
            }
          />

          {/*<Accordion*/}
          {/*  defaultExpanded={true}*/}
          {/*  disableGutters={true}*/}
          {/*  square={true}*/}
          {/*  summary="Withdrawals"*/}
          {/*  children={*/}
          {/*    <Box mb={8}>*/}
          {/*      <Box sx={{ position: 'absolute', top: 0, right: 0 }}>*/}
          {/*        <Search*/}
          {/*          size="small"*/}
          {/*          name="withdrawals"*/}
          {/*          value={searchQuery}*/}
          {/*          label="Search"*/}
          {/*          onChange={onSearchChange}*/}
          {/*          onClear={onClearSearch}*/}
          {/*        />*/}
          {/*      </Box>*/}
          {/*      <WithdrawalsTable*/}
          {/*        setWithdrawalToProcess={setWithdrawalToProcess}*/}
          {/*        setWithdrawalToRefuse={setWithdrawalToRefuse}*/}
          {/*        onRemoveWithdrawal={(withdrawal: Withdrawal) =>*/}
          {/*          setWithdrawalToRemove(withdrawal)*/}
          {/*        }*/}
          {/*        loading={loading}*/}
          {/*        data={withdrawalsData}*/}
          {/*        page={page}*/}
          {/*        sorting={sorting}*/}
          {/*        onPageChange={handleChangePage}*/}
          {/*        setSorting={setSorting}*/}
          {/*      />*/}
          {/*    </Box>*/}
          {/*  }*/}
          {/*/>*/}
        </>
      )}
    </Box>
  );
}
