import { PaymentMethodTypes } from 'types/users';
import { Goal } from 'types/goals';

export type PaginatedResponse<T> = {
  items: T[];
  meta: {
    totalItems: number;
    itemCount: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
  };
  links: {
    first: string;
    previous: string;
    next: string;
    last: string;
  };
};

export type PostLiqpayPaymentIntentDTO = {
  message: string;
  sponsorName: string;
  contentCreatorName: string;
  amount: number;
  voice: string;
};

export type PostWhitePayPaymentIntentDTO = {
  message: string;
  sponsorName: string;
  contentCreatorName: string;
  paymentMethod: PaymentMethodTypes;
  amount: number;
  voice: string;
  currency: Currency;
};

export type PostWayforpayPaymentIntentDTO = {
  message: string;
  sponsorName: string;
  paymentMethod: PaymentMethodTypes;
  amount: number;
  voice: string;
  currency: Currency;
  productName: string[];
  productCount: number[];
  productPrice: number[];
};

export type PostMonobankPaymentIntentDTO = {
  message: string;
  sponsorName: string;
  paymentMethod: PaymentMethodTypes;
  amount: number;
  voice: string;
  currency: Currency;
};

export type WayforpayPaymentIntentResponse = PostWayforpayPaymentIntentDTO & {
  merchantAccount: string;
  merchantAuthType: string;
  merchantDomainName: string;
  currency: string;
  serviceUrl: string;
  returnUrl: string;
  merchantSignature: string;
  orderReference: string;
  orderDate: string;
};

export type CardWithdrawDTO = {
  currency: Currency;
  card: string;
  amount: number;
};

export type BulkCreateGoals = {
  goals: Array<Omit<Goal, '_id'>>;
};

export type IBANWithdrawDTO = {
  currency: Currency;
  amount: number;
  iban: string;
  okpo: string;
  accountName: string;
};

export type PostSponsorDTO = {
  amount: number;
  sponsorName: string;
  currency: Currency;
};

export type WhitepayWithdrawDTO = {
  amount: string;
  currency: Currency;
  wallet: string;
};

export enum Currency {
  UAH = 'UAH',
  EUR = 'EUR',
  USD = 'USD',
  USDT = 'USDT',
}

export const CurrencyLabelMap: {
  [key in Currency]: string;
} = {
  [Currency.UAH]: '₴',
  [Currency.EUR]: '€',
  [Currency.USD]: '$',
  [Currency.USDT]: 'USDT',
};

export const CurrencyToPaymentMethodMap: {
  [key in PaymentMethodTypes]: Array<Currency>;
} = {
  [PaymentMethodTypes.WAYFORPAY]: [Currency.UAH, Currency.USD, Currency.EUR],
  [PaymentMethodTypes.WHITEPAY]: [Currency.USDT],
  [PaymentMethodTypes.CRYPTOMUS]: [Currency.USDT],
  [PaymentMethodTypes.MONOBANKA]: [Currency.UAH],
  [PaymentMethodTypes.LIQPAY]: [Currency.UAH],
};

export type ExchangeRate = {
  [key in Currency]: number;
};
