import React, { Fragment, ReactElement, ReactNode, useState } from 'react';
import Box from '@mui/material/Box';
import Button from 'view/components/Button';
import FormHeader from 'view/components/Form/Header';
import { PaymentMethodEntry, PaymentMethodTypes } from 'types/users';
import { StyledFormActions, StyledFormContainer } from '../../styled';
import Grid from '@mui/material/Grid2';
import useUser from 'hooks/user';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/auth';
import { updateUserData } from 'services/api/user';
import IconButton from '@mui/material/IconButton';
import { red } from 'view/theme/colors';
import Tooltip from '@mui/material/Tooltip';
import TrashIcon from 'view/components/icons/Trash';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import DragHandleRoundedIcon from '@mui/icons-material/DragHandleRounded';
import Typography from '@mui/material/Typography';
import ConfirmationDialog from 'view/components/ConfirmationDialog';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BitcoinIcon from 'view/components/icons/Bitcoin';
import DefaultPaymentCardIcon from 'view/components/icons/DefaultPaymentCard';
import { WhitepayFormData } from 'view/pages/AccountSettings/components/ChangePaymentMethods/components/Whitepay/components/WhitepayDialog';
import WayforpayDialog, {
  WayforpayFormData,
} from 'view/pages/AccountSettings/components/ChangePaymentMethods/components/Wayforpay/components/WayforpayDialog';
import WayforpayDataRow from 'view/pages/AccountSettings/components/ChangePaymentMethods/components/Wayforpay/components/WayforpayDataRow';
import MonobankaDialog, {
  MonobankaFormData,
} from 'view/pages/AccountSettings/components/ChangePaymentMethods/components/Monobanka/components/MonobankaDialog';
import MonobankaDataRow from 'view/pages/AccountSettings/components/ChangePaymentMethods/components/Monobanka/components/MonobankaDataRow';
import EditIcon from 'view/components/icons/Edit';
import PlataByMonoIcon from 'view/components/icons/PlataByMono';
import WayForPayLogo from 'view/components/icons/WayForPay';
import { DndContext, closestCenter, DragEndEvent } from '@dnd-kit/core';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
// import WhitepayDataRow from 'view/pages/AccountSettings/components/ChangePaymentMethods/components/Whitepay/components/WhitepayDataRow';

type ChangePaymentMethodsProps = {
  onCancel: () => void;
};

export const PAYMENT_METHOD_MEDIA_MAP: Record<
  PaymentMethodTypes,
  { label: string; shortLabel: string; disabled?: boolean; icon: ReactElement }
> = {
  [PaymentMethodTypes.WAYFORPAY]: {
    label: 'Онлайн-оплата карткою (Wayforpay)',
    shortLabel: 'Wayforpay',
    icon: <WayForPayLogo />,
  },
  [PaymentMethodTypes.WHITEPAY]: {
    label: 'Оплата криптовалютою (Whitepay)',
    shortLabel: 'Whitepay',
    disabled: true,
    icon: <BitcoinIcon />,
  },
  [PaymentMethodTypes.CRYPTOMUS]: {
    label: 'Оплата криптовалютою (Cryptomus)',
    shortLabel: 'Cryptomus',
    disabled: true,
    icon: <BitcoinIcon />,
  },
  [PaymentMethodTypes.MONOBANKA]: {
    label: 'Поповнення монобанки (Monobank)',
    shortLabel: 'Monobank',
    icon: <PlataByMonoIcon />,
  },
  [PaymentMethodTypes.LIQPAY]: {
    label: 'Онлайн-оплата карткою (LiqPay)',
    shortLabel: 'LiqPay',
    disabled: true,
    icon: <DefaultPaymentCardIcon />,
  },
};

const DraggableItem = ({
  id,
  content,
  disabled,
  actions,
}: {
  id: string;
  disabled: boolean;
  content: ReactNode;
  actions: ReactNode;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  return (
    <Card
      ref={setNodeRef}
      sx={{
        mb: 2,
        px: 2,
        display: 'flex',
        alignItems: 'center',
        transition,
        transform: `translate(${transform?.x}px, ${transform?.y}px)`,
      }}
    >
      <IconButton
        {...listeners}
        {...attributes}
        sx={{ cursor: 'grab', mr: 1 }}
        disabled={disabled}
      >
        <DragHandleRoundedIcon />
      </IconButton>

      <CardContent sx={{ flexGrow: 1, p: 1 }}>{content}</CardContent>
      <CardActions>{actions}</CardActions>
    </Card>
  );
};

export default function ChangePaymentMethods({
  onCancel,
}: ChangePaymentMethodsProps) {
  const { setErrorMessage, setSuccessMessage } = useAuth();
  const { user, setUser } = useUser();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  const [paymentMethodToAdd, setPaymentMethodToAdd] =
    useState<PaymentMethodTypes | null>(null);
  const [paymentMethodToUpdate, setPaymentMethodToUpdate] =
    useState<PaymentMethodTypes | null>(null);
  const [paymentToDelete, setPaymentToDelete] =
    useState<PaymentMethodTypes | null>(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onAddPaymentMethodSubmit = async (
    paymentMethod: PaymentMethodTypes,
    data?: WhitepayFormData | WayforpayFormData | MonobankaFormData,
  ) => {
    setErrorMessage('');

    try {
      const updatedUserData = {
        paymentMethods: [
          ...user.paymentMethods,
          { name: paymentMethod, ...(data || {}) } as PaymentMethodEntry,
        ],
      };

      await updateUserData(user._id, updatedUserData);
      setUser({
        ...user,
        ...updatedUserData,
      });
      setPaymentMethodToAdd(null);
      setSuccessMessage(
        t('successfullyAddedPaymentMethod', {
          paymentMethod: PAYMENT_METHOD_MEDIA_MAP[paymentMethod].label,
        }),
      );
    } catch (err) {
      setErrorMessage(
        t('errorWhileAddingPaymentMethod', {
          paymentMethod: PAYMENT_METHOD_MEDIA_MAP[paymentMethod].label,
        }),
      );
    }
  };

  const onUpdatePaymentMethodSubmit = async (
    paymentMethod: PaymentMethodTypes,
    data?: WhitepayFormData | WayforpayFormData | MonobankaFormData,
  ) => {
    setErrorMessage('');

    try {
      const paymentMethods = [...user.paymentMethods];
      const index = paymentMethods.findIndex(
        (pm) => pm.name === paymentMethodToUpdate,
      );

      if (index !== -1) {
        paymentMethods[index] = {
          name: paymentMethod,
          ...(data || {}),
        } as PaymentMethodEntry;
      }

      const updatedUserData = { paymentMethods };

      await updateUserData(user._id, updatedUserData);
      setUser({
        ...user,
        ...updatedUserData,
      });
      setPaymentMethodToUpdate(null);
      setSuccessMessage(
        t('successfullyUpdatedPaymentMethod', {
          paymentMethod: PAYMENT_METHOD_MEDIA_MAP[paymentMethod].label,
        }),
      );
    } catch (err) {
      setErrorMessage(
        t('errorWhileUpdatingPaymentMethod', {
          paymentMethod: PAYMENT_METHOD_MEDIA_MAP[paymentMethod].label,
        }),
      );
    }
  };

  const deletePaymentMethod = async (paymentToDelete: PaymentMethodTypes) => {
    try {
      setLoading(true);
      const restPaymentMethods = user.paymentMethods.filter(
        (paymentMethod) => paymentMethod.name !== paymentToDelete,
      );

      await updateUserData(user._id, { paymentMethods: restPaymentMethods });
      setPaymentToDelete(null);
      setUser({
        ...user,
        paymentMethods: restPaymentMethods,
      });
    } catch (err) {
      setErrorMessage(
        t('errorWhileDeletingPaymentMethod', {
          paymentMethod: PAYMENT_METHOD_MEDIA_MAP[paymentToDelete].label,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const options = Object.values(PaymentMethodTypes)
    .filter(
      (key) => !user?.paymentMethods.some((method) => method.name === key),
    )
    .filter((item) =>
      [PaymentMethodTypes.WAYFORPAY, PaymentMethodTypes.MONOBANKA].includes(
        item,
      ),
    );

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over || active.id === over.id) {
      return;
    }

    const oldIndex = user.paymentMethods.findIndex(
      (item) => item.name === active.id,
    );
    const newIndex = user.paymentMethods.findIndex(
      (item) => item.name === over.id,
    );
    const newItems = arrayMove(user.paymentMethods, oldIndex, newIndex);

    const updatedUserData = { paymentMethods: newItems };
    setUser({
      ...user,
      ...updatedUserData,
    });

    try {
      setLoading(true);
      await updateUserData(user._id, updatedUserData);

      setSuccessMessage(t('Successfully updated payment methods order'));
    } catch (err) {
      setErrorMessage(t('Error updating payment methods order'));
      setUser({
        ...user,
        paymentMethods: user.paymentMethods,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledFormContainer>
      <FormHeader
        title={t('Payment methods')}
        description={t('Change your payment methods')}
      />
      <Grid container>
        <Grid size={12}>
          <Box display="flex" flexDirection="column">
            <Box gap={2} mb={2}>
              {((): ReactNode => {
                if (!user.paymentMethods?.length) {
                  return (
                    <Typography variant="body2">
                      {t('There are no payment methods. Add a new one below')}
                    </Typography>
                  );
                }
                return (
                  <DndContext
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                  >
                    <SortableContext
                      items={user.paymentMethods.map((item) => item.name)}
                      strategy={verticalListSortingStrategy}
                    >
                      {user.paymentMethods.map((paymentMethod) => {
                        const name = paymentMethod.name;

                        return (
                          <DraggableItem
                            key={name}
                            id={name}
                            disabled={
                              loading || user.paymentMethods.length === 1
                            }
                            content={
                              <Box display="flex" alignItems="center" my={1}>
                                {name === PaymentMethodTypes.WAYFORPAY && (
                                  <WayforpayDataRow />
                                )}
                                {name === PaymentMethodTypes.MONOBANKA && (
                                  <MonobankaDataRow />
                                )}
                                {/*{isWhitepay && (*/}
                                {/*  <WhitepayDataRow*/}
                                {/*    setPaymentMethodToAdd={setPaymentMethodToAdd}*/}
                                {/*  />*/}
                                {/*)}*/}
                              </Box>
                            }
                            actions={
                              <>
                                <Tooltip
                                  arrow
                                  title={t('Update the payment method')}
                                >
                                  <IconButton
                                    onClick={() =>
                                      setPaymentMethodToUpdate(name)
                                    }
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip arrow title={t('Remove')}>
                                  <IconButton
                                    color="error"
                                    onClick={() => setPaymentToDelete(name)}
                                  >
                                    <TrashIcon color={red} />
                                  </IconButton>
                                </Tooltip>
                              </>
                            }
                          />
                        );
                      })}
                    </SortableContext>
                  </DndContext>
                );
              })()}
            </Box>
          </Box>

          <StyledFormActions>
            <Button
              aria-controls={open ? 'demo-customized-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              variant="contained"
              onClick={handleClick}
              endIcon={<KeyboardArrowDownRoundedIcon />}
              label={t('Add')}
              disabled={options.length === 0}
            />
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
              {options.map((key) => (
                <MenuItem
                  key={key}
                  disabled={PAYMENT_METHOD_MEDIA_MAP[key].disabled}
                  onClick={() => {
                    handleClose();
                    if (
                      [
                        PaymentMethodTypes.WAYFORPAY,
                        PaymentMethodTypes.MONOBANKA,
                      ].includes(key)
                    ) {
                      return setPaymentMethodToAdd(key);
                    }

                    return onAddPaymentMethodSubmit(key);
                  }}
                  disableRipple
                >
                  <ListItemIcon sx={{ marginRight: 1 }}>
                    {PAYMENT_METHOD_MEDIA_MAP[key].icon}
                  </ListItemIcon>
                  <ListItemText>
                    {PAYMENT_METHOD_MEDIA_MAP[key].label}
                  </ListItemText>
                </MenuItem>
              ))}
            </Menu>

            <Button
              label={t('Back')}
              variant="text"
              color="secondary"
              onClick={onCancel}
            />
          </StyledFormActions>
        </Grid>
      </Grid>
      {paymentMethodToAdd === PaymentMethodTypes.WAYFORPAY && (
        <WayforpayDialog
          open={paymentMethodToAdd === PaymentMethodTypes.WAYFORPAY}
          onSaved={async (data) => {
            await onAddPaymentMethodSubmit(PaymentMethodTypes.WAYFORPAY, data);
            setPaymentMethodToAdd(null);
          }}
          onClose={() => setPaymentMethodToAdd(null)}
        />
      )}
      {paymentMethodToUpdate === PaymentMethodTypes.WAYFORPAY && (
        <WayforpayDialog
          open={paymentMethodToUpdate === PaymentMethodTypes.WAYFORPAY}
          onSaved={async (data) => {
            await onUpdatePaymentMethodSubmit(
              PaymentMethodTypes.WAYFORPAY,
              data,
            );
            setPaymentMethodToUpdate(null);
          }}
          onClose={() => setPaymentMethodToUpdate(null)}
        />
      )}
      {paymentMethodToAdd === PaymentMethodTypes.MONOBANKA && (
        <MonobankaDialog
          open={paymentMethodToAdd === PaymentMethodTypes.MONOBANKA}
          onSaved={async (data) => {
            await onAddPaymentMethodSubmit(PaymentMethodTypes.MONOBANKA, data);
            setPaymentMethodToAdd(null);
          }}
          onClose={() => setPaymentMethodToAdd(null)}
        />
      )}
      {paymentMethodToUpdate === PaymentMethodTypes.MONOBANKA && (
        <MonobankaDialog
          open={paymentMethodToUpdate === PaymentMethodTypes.MONOBANKA}
          onSaved={async (data) => {
            await onUpdatePaymentMethodSubmit(
              PaymentMethodTypes.MONOBANKA,
              data,
            );
            setPaymentMethodToUpdate(null);
          }}
          onClose={() => setPaymentMethodToUpdate(null)}
        />
      )}
      {/*{paymentMethodToAdd === PaymentMethodTypes.WHITEPAY && (*/}
      {/*  <WhitepayDialog*/}
      {/*    open={paymentMethodToUpdate === PaymentMethodTypes.WHITEPAY}*/}
      {/*    onSaved={async (data) => {*/}
      {/*      setPaymentMethodToAdd(null);*/}
      {/*      await onAddPaymentMethodSubmit(PaymentMethodTypes.WHITEPAY, data);*/}
      {/*    }}*/}
      {/*    onClose={() => setPaymentMethodToAdd(null)}*/}
      {/*  />*/}
      {/*)}*/}
      <ConfirmationDialog
        open={!!paymentToDelete}
        text={t('deletePaymentMethod', {
          paymentMethod:
            PAYMENT_METHOD_MEDIA_MAP[paymentToDelete as PaymentMethodTypes]
              ?.label,
        })}
        confirmText={t('Delete')}
        disabled={loading}
        loading={loading}
        withLoader
        color="error"
        onConfirm={() =>
          deletePaymentMethod(paymentToDelete as PaymentMethodTypes)
        }
        onClose={() => setPaymentToDelete(null)}
      />
    </StyledFormContainer>
  );
}
