import React, { useState } from 'react';
import Box from '@mui/material/Box';
import AddDonationThresholdDialog from 'view/pages/AccountSettings/components/ChangeDonationThresholdConfig/components/AddDonationThresholdDialog';
import Button from 'view/components/Button';
import Input from 'view/components/Input';
import FormHeader from 'view/components/Form/Header';
import EditIcon from 'view/components/icons/Edit';
import { ThresholdRecord } from 'types/users';
import { StyledFormActions, StyledFormContainer } from '../../styled';
import Grid from '@mui/material/Grid2';
import useUser from 'hooks/user';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/auth';
import { removeDonationThreshold } from 'services/api/user';
import IconButton from '@mui/material/IconButton';
import { red } from 'view/theme/colors';
import Tooltip from '@mui/material/Tooltip';
import TrashIcon from 'view/components/icons/Trash';
import AudioPreview from 'view/components/AudioPreview';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import PicturePreview from 'view/components/PicturePreview';
import ConfirmationDialog from 'view/components/ConfirmationDialog';
import { Currency, CurrencyLabelMap } from 'services/api/types';
import { formatCurrency } from 'tools';
import UpdateDonationThresholdDialog from 'view/pages/AccountSettings/components/ChangeDonationThresholdConfig/components/UpdateDonationThresholdDialog';

type ChangeDonationThresholdConfigProps = {
  onCancel: () => void;
};

export default function ChangeDonationThresholdConfig({
  onCancel,
}: ChangeDonationThresholdConfigProps) {
  const { setErrorMessage, setSuccessMessage } = useAuth();
  const { user, setUser } = useUser();
  const { t } = useTranslation();

  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const [thresholdToUpdate, setThresholdToUpdate] =
    useState<ThresholdRecord | null>(null);
  const [thresholdToRemove, setThresholdToRemove] =
    useState<null | ThresholdRecord>(null);
  const [loading, setLoading] = useState(false);

  const onRemoveThresholdConfirmation = async (
    thresholdToRemove: ThresholdRecord,
  ) => {
    try {
      setLoading(true);
      await removeDonationThreshold(thresholdToRemove._id);

      const filteredDonationThresholdConfig =
        user.donationThresholdConfig.filter(
          (thresholdItem: ThresholdRecord) =>
            thresholdItem._id !== thresholdToRemove._id,
        );

      setUser({
        ...user,
        donationThresholdConfig: filteredDonationThresholdConfig,
      });
      setSuccessMessage(t('Successfully removed threshold'));
      setThresholdToRemove(null);
    } catch (err) {
      setErrorMessage(t('Error while deleting threshold'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledFormContainer>
      {thresholdToRemove && (
        <ConfirmationDialog
          open={!!thresholdToRemove}
          text={t('deleteThresholdRecord', {
            threshold: formatCurrency(
              thresholdToRemove.threshold,
              Currency.UAH,
            ),
          })}
          confirmText={t('Remove')}
          onConfirm={() => onRemoveThresholdConfirmation(thresholdToRemove)}
          onClose={() => setThresholdToRemove(null)}
        />
      )}
      {thresholdToUpdate && (
        <UpdateDonationThresholdDialog
          open={!!thresholdToUpdate}
          thresholdToUpdate={thresholdToUpdate}
          onClose={() => setThresholdToUpdate(null)}
        />
      )}
      <FormHeader
        title={t('Donation threshold config')}
        description={t('Change your donation threshold config')}
      />
      <Grid container spacing={8}>
        <Grid size={12}>
          <Box display="flex" flexDirection="column" mb={2}>
            {[...user.donationThresholdConfig]
              .sort((a, b) => a.threshold - b.threshold)
              .map((field) => {
                const { pictureUrl, soundUrl } = field;

                return (
                  <Box
                    key={field._id}
                    display="flex"
                    alignItems="center"
                    gap={2}
                    mb={2}
                  >
                    <Box width="12rem">
                      <Input
                        label={t('Threshold')}
                        startAdornment={<>{CurrencyLabelMap[Currency.UAH]}</>}
                        type="number"
                        value={field.threshold}
                        disabled
                        endAdornment={
                          <Tooltip arrow title={t('Edit')}>
                            <IconButton
                              edge="end"
                              onClick={() => setThresholdToUpdate(field)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        }
                      />
                    </Box>

                    {pictureUrl && <PicturePreview pictureUrl={pictureUrl} />}

                    {soundUrl && (
                      <AudioPreview
                        soundUrl={soundUrl}
                        title="Notification sound"
                      />
                    )}

                    <Tooltip arrow title={t('Remove')}>
                      <IconButton
                        onClick={() => setThresholdToRemove(field)}
                        disabled={user.donationThresholdConfig.length === 1}
                        color="error"
                      >
                        <TrashIcon
                          color={
                            user.donationThresholdConfig.length !== 1
                              ? red
                              : 'currentColor'
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  </Box>
                );
              })}

            {isUploadDialogOpen && (
              <AddDonationThresholdDialog
                open={isUploadDialogOpen}
                onClose={() => setIsUploadDialogOpen(false)}
              />
            )}
          </Box>

          <StyledFormActions>
            <Button
              withLoader
              loading={loading}
              disabled={loading}
              startIcon={<AddRoundedIcon />}
              onClick={() => setIsUploadDialogOpen(true)}
              label={t('Add')}
            />
            <Button
              label={t('Back')}
              variant="text"
              color="secondary"
              disabled={loading}
              onClick={onCancel}
            />
          </StyledFormActions>
        </Grid>
      </Grid>
    </StyledFormContainer>
  );
}
