import { alpha } from '@mui/material/styles';

export const primary = '#FA6417';
export const lightPrimary = alpha(primary, 0.08);
export const darkPrimary = '#C65300';

export const purple = '#4853E7';
export const lightPurple = 'rgba(72, 83, 231, 0.08)';

export const red = '#EB5555';
export const lightRed = alpha(red, 0.08);
export const darkRed = '#B52D2D';

export const green = '#51A873';
export const lightGreen = alpha(green, 0.08);
export const darkGreen = '#2A764E';

export const blue = '#4853E7';
export const lightBlue = alpha(blue, 0.08);
export const darkBlue = '#1C29B3';

export const yellow = '#E79E48';
export const lightYellow = alpha(yellow, 0.08);
export const darkYellow = '#B36D1F';

export const orange = '#F37B0D';
export const white = '#FFFFFF';
export const gray = '#CACACA';
export const lightGray = '#F6F6F6';
export const grey = '#9F9FA1';
export const unknownStatus = '#636474';
export const codeGreen = '#A8E3BF';
export const youtube = '#CD201F';
export const gold = '#FFD700';
export const bronze = '#cd7f32';

export const black = '#000000';
export const black2 = '#F8F8F8';
export const black4 = '#EBEBEB';
export const black8 = 'rgba(26, 26, 26, 0.08)';
export const black16 = '#DADADA';
export const black20 = '#CCCCCC';
export const black29 = '#B5B5B5';
export const black32 = 'rgba(26, 26, 26, 0.32)';
export const black38 = '#9D9D9D';
export const black40 = '#999999';
export const black54 = '#757575';
export const black71 = '#4B4B4B';
export const black87 = '#212121';
export const black100 = '#1A1A1A';
