import React from 'react';
import Input from 'view/components/Input';
import { useTranslation } from 'react-i18next';
import useUser from 'hooks/user';
import { PaymentMethodTypes } from 'types/users';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { PAYMENT_METHOD_MEDIA_MAP } from 'view/pages/AccountSettings/components/ChangePaymentMethods/index';

export default function MonobankaDataRow() {
  const { user } = useUser();
  const { t } = useTranslation();

  const data = user.paymentMethods.find(
    (paymentMethod) => paymentMethod.name === PaymentMethodTypes.MONOBANKA,
  );

  if (!data || data.name !== PaymentMethodTypes.MONOBANKA) {
    return null;
  }

  return (
    <Box>
      <Box display="flex" alignItems="center" gap={2} mb={3}>
        <Typography>
          {PAYMENT_METHOD_MEDIA_MAP[PaymentMethodTypes.MONOBANKA].shortLabel}
        </Typography>
        {PAYMENT_METHOD_MEDIA_MAP[PaymentMethodTypes.MONOBANKA].icon}
      </Box>
      <Box display="flex" alignItems="center" gap={2} flexWrap="wrap">
        <Input
          id="jar-title"
          label={t('Jar title')}
          fullWidth
          disabled
          type="text"
          value={data?.jarTitle}
        />
      </Box>
    </Box>
  );
}
