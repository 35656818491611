import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import ActionMenu from 'view/components/ActionMenu';
import Avatar from 'view/components/Avatar';
import Drawer from 'view/components/Drawer';
import ConfirmationDialog from 'view/components/ConfirmationDialog';
import NavigationTabs from 'view/components/NavigationTabs';
import MenuIcon from '@mui/icons-material/Menu';
import ListRoundedIcon from '@mui/icons-material/ListRounded';
import LogoIcon from 'view/components/icons/Logo';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import GoogleIcon from '@mui/icons-material/Google';
import WidgetsRoundedIcon from '@mui/icons-material/WidgetsRounded';
import ReceiptLongRoundedIcon from '@mui/icons-material/ReceiptLongRounded';
// import WalletIcon from '@mui/icons-material/Wallet';
import DrawerDetails from './components/DrawerDetails';
import {
  SETTINGS_PATH,
  APP_PATH,
  // BALANCE_PATH,
  MY_DONATES_PATH,
  STATISTICS_PATH,
  WIDGETS_PATH,
  BILLING_PATH,
  LIVE_DONATE_PATH,
  DONATE_PATH,
  QR_CODE_DONATE_PATH,
} from 'view/routes';
import useAuth from 'hooks/auth';
import Button from 'view/components/Button';
import { Link, NavLink, useLocation, useNavigate } from 'react-router';
import MuiLink from '@mui/material/Link';
import useUser from 'hooks/user';
import useTheme from 'hooks/theme';
import CustomizedSwitches from 'view/components/Switch';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import StackedLineChartRoundedIcon from '@mui/icons-material/StackedLineChartRounded';
import { useTranslation } from 'react-i18next';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import CloseIcon from 'view/components/icons/Close';
import Badge from '@mui/material/Badge';
import VoiceMagicIcon from 'view/components/icons/VoiceMagic';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import BottomNavigationBar from '@mui/material/BottomNavigation';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import DataObjectRoundedIcon from '@mui/icons-material/DataObjectRounded';
import QrCode2RoundedIcon from '@mui/icons-material/QrCode2Rounded';

export default function ResponsiveAppBar() {
  const { isAuthenticated, signOut } = useAuth();
  const { user, OBSUrl, invoicesData } = useUser();
  const { toggleTheme, isDarkMode } = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [dialogToLogout, setDialogToLogout] = useState<boolean>(false);

  const { t } = useTranslation();

  const unbilledInvoicesLength = invoicesData?.items?.filter(
    (invoice) => !invoice.isPaid && invoice.invoiceUrl,
  ).length;
  const isEmptyPaymentMethods =
    Object.keys(user?.paymentMethods || {}).length === 0 ? 1 : 0;

  const additionalTabs = [
    // {
    //   id: BALANCE_PATH,
    //   label: t('Balance'),
    //   icon: <WalletIcon />,
    //   onClick: () => navigate(BALANCE_PATH),
    // },
    {
      id: SETTINGS_PATH,
      label: t('Settings'),
      icon: (
        <Badge
          badgeContent={isEmptyPaymentMethods}
          variant="dot"
          color="primary"
        >
          <SettingsRoundedIcon />
        </Badge>
      ),
      onClick: () => navigate(SETTINGS_PATH),
    },
  ];

  const widgetTab = {
    id: WIDGETS_PATH,
    label: t('Widgets'),
    icon: <WidgetsRoundedIcon />,
    onClick: () => navigate(WIDGETS_PATH),
  };

  const tabs = [
    {
      id: MY_DONATES_PATH,
      onClick: () => navigate(MY_DONATES_PATH),
      label: t('Donations'),
      icon: <ListRoundedIcon />,
    },
    widgetTab,
    {
      id: BILLING_PATH,
      label: t('Billing'),
      icon: (
        <Badge badgeContent={unbilledInvoicesLength} color="primary">
          <ReceiptLongRoundedIcon />
        </Badge>
      ),
      onClick: () => navigate(BILLING_PATH),
    },
    {
      id: STATISTICS_PATH,
      onClick: () => navigate(STATISTICS_PATH),
      label: t('statistics'),
      icon: <StackedLineChartRoundedIcon />,
    },
    ...additionalTabs,
  ];

  const onLogoutConfirmation = () => signOut();

  const onConfirmationClose = () => {
    setDialogToLogout(false);
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          background: 'transparent',
          backdropFilter: 'blur(20px)',
        }}
      >
        <Container maxWidth="xl">
          <Toolbar
            sx={{
              justifyContent: 'space-between',
              padding: { xs: 0, md: 'inherit' },
            }}
          >
            {isAuthenticated && (
              <Box
                sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}
                mr={2}
              >
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={() => setDrawerOpen(!drawerOpen)}
                  color="inherit"
                >
                  {drawerOpen ? (
                    <CloseIcon />
                  ) : (
                    <Badge
                      badgeContent={
                        isEmptyPaymentMethods || unbilledInvoicesLength
                      }
                      variant="dot"
                      color="primary"
                    >
                      <MenuIcon />
                    </Badge>
                  )}
                </IconButton>
              </Box>
            )}

            <Box
              sx={{ flexGrow: 1, mr: 1, display: 'flex', alignItems: 'center' }}
            >
              <Box mr={2}>
                <Link to={APP_PATH} style={{ textDecoration: 'none' }}>
                  <LogoIcon />
                </Link>
              </Box>

              {isAuthenticated && (
                <Box sx={{ display: { xs: 'none', md: 'grid' } }}>
                  <NavigationTabs tabs={tabs} withIcons />
                </Box>
              )}
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {isAuthenticated && !user.isPremium && (
                <Box mr={2} sx={{ display: { xs: 'none', sm: 'flex' } }}>
                  <Tooltip
                    defaultChecked={true}
                    title={t(
                      'To use additional voice-overs, you need to activate your account',
                    )}
                  >
                    <Box>
                      <Button
                        startIcon={<VoiceMagicIcon />}
                        size="small"
                        variant="contained"
                        onClick={() => navigate(BILLING_PATH)}
                        label={t('Premium')}
                      />
                    </Box>
                  </Tooltip>
                </Box>
              )}
              {isAuthenticated && (
                <Box mr={2} display="flex" gap={1} flexWrap="nowrap">
                  <Tooltip title={t('OBS widget')}>
                    <MuiLink
                      href={`${process.env.REACT_APP_UI_URL}/${OBSUrl}${LIVE_DONATE_PATH}`}
                      underline="hover"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IconButton>
                        <DataObjectRoundedIcon />
                      </IconButton>
                    </MuiLink>
                  </Tooltip>
                  <Tooltip title={t('QR code widget for OBS')}>
                    <MuiLink
                      href={`${process.env.REACT_APP_UI_URL}${QR_CODE_DONATE_PATH}/${user.contentCreatorName}`}
                      underline="hover"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IconButton>
                        <QrCode2RoundedIcon />
                      </IconButton>
                    </MuiLink>
                  </Tooltip>
                </Box>
              )}
              <Box
                sx={{
                  display: { xs: 'none', sm: 'none', md: 'flex' },
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <CustomizedSwitches
                  onChange={toggleTheme}
                  checked={isDarkMode}
                />
              </Box>
              {isAuthenticated && (
                <>
                  <Box mr={1}>
                    <Avatar
                      src={user.profilePictureUrl}
                      contentCreatorName={user.contentCreatorName}
                      size={3}
                    />
                  </Box>
                  <Box
                    minWidth="0"
                    mr={1}
                    sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                  >
                    <Tooltip arrow title={user.contentCreatorName}>
                      <MuiLink
                        href={`${process.env.REACT_APP_UI_URL}${DONATE_PATH}/${user.contentCreatorName}`}
                        underline="hover"
                        target="_blank"
                        rel="noopener noreferrer"
                        noWrap
                        maxWidth="6rem"
                        minWidth="1rem"
                        fontSize="0.875rem"
                        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                      >
                        {user.contentCreatorName}
                        <OpenInNewRoundedIcon fontSize="small" />
                      </MuiLink>
                    </Tooltip>
                  </Box>

                  <ActionMenu
                    buttonIcon={<MoreHorizRoundedIcon />}
                    menuItems={[
                      widgetTab,
                      ...additionalTabs,
                      {
                        label: t('Log out'),
                        icon: <LogoutRoundedIcon />,
                        onClick: () => setDialogToLogout(true),
                      },
                    ]}
                  />
                </>
              )}
              {!isAuthenticated && (
                <Box sx={{ flexGrow: 1, justifyContent: 'flex-end' }}>
                  <MuiLink href={`${process.env.REACT_APP_API_URL}/api/auth`}>
                    <Button
                      variant="contained"
                      color="primary"
                      label={t('Sign in with Google')}
                      startIcon={<GoogleIcon />}
                    />
                  </MuiLink>
                </Box>
              )}
            </Box>
            {dialogToLogout && (
              <ConfirmationDialog
                open={dialogToLogout}
                text={t('Are you sure, you want to log out?')}
                confirmText={t('Log out')}
                color="primary"
                onConfirm={onLogoutConfirmation}
                onClose={onConfirmationClose}
              />
            )}
            <Drawer
              variant="temporary"
              width="auto"
              open={drawerOpen}
              onClose={() => setDrawerOpen(false)}
              action={
                <DrawerDetails
                  onClose={() => setDrawerOpen(false)}
                  adminTabs={tabs}
                />
              }
            />
          </Toolbar>
        </Container>
        <Divider />
      </AppBar>
      {isAuthenticated && (
        <Box sx={{ display: { md: 'none' } }}>
          <BottomNavigationBar
            sx={{
              width: '100%',
              position: 'fixed',
              bottom: 0,
              zIndex: 1100,
              display: { lg: 'none' },
            }}
            showLabels
            value={pathname}
          >
            {tabs.map(({ icon, onClick, id, label }) => (
              <BottomNavigationAction
                key={id}
                icon={icon}
                aria-label={id}
                value={id}
                label={label}
                onClick={onClick}
                LinkComponent={NavLink}
              />
            ))}
          </BottomNavigationBar>
        </Box>
      )}
    </>
  );
}
