import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from 'view/components/Button';
import Input from 'view/components/Input';
import FormHeader from 'view/components/Form/Header';
import { StyledFormContainer, StyledFormActions } from '../../styled';
import { MEDIA_PATH } from 'view/routes';
import useUser from 'hooks/user';
import { useTranslation } from 'react-i18next';
import CopyButton from 'view/components/CopyButton';
import IconButton from '@mui/material/IconButton';
import EyeOffIcon from 'view/components/icons/EyeOff';
import EyeIcon from 'view/components/icons/Eye';
import Switch from '@mui/material/Switch';
import { FormControl, FormGroup } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import useAuth from 'hooks/auth';
import { updateUserData } from 'services/api/user';
import Typography from '@mui/material/Typography';

type ViewOBSURLProps = {
  onCancel: () => void;
};

export default function ChangeMediaSettings({ onCancel }: ViewOBSURLProps) {
  const { setSuccessMessage, setErrorMessage } = useAuth();
  const { OBSUrl, user, setUser } = useUser();
  const { t } = useTranslation();
  const url = `${process.env.REACT_APP_UI_URL}/${OBSUrl}${MEDIA_PATH}`;

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const value = event.target.checked;

      setLoading(true);
      await updateUserData(user._id, { allowMedia: value });
      setUser({ ...user, allowMedia: value });
      setSuccessMessage(t('Successfully updated media settings'));
    } catch (err) {
      setErrorMessage(t('Error while updating media settings'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <StyledFormContainer>
        <FormHeader
          title={t('YouTube video')}
          description={t('Allow or disallow users to add videos from YouTube')}
        />
        <Box mb={1}>
          <FormControl component="fieldset" variant="standard">
            <FormGroup>
              <FormControlLabel
                sx={{ justifyContent: 'start', margin: 0, gap: 1 }}
                control={
                  <Switch
                    checked={!!user?.allowMedia}
                    onChange={handleChange}
                    name="gilad"
                    disabled={loading}
                  />
                }
                labelPlacement="start"
                label={t('Activate')}
              />
            </FormGroup>
          </FormControl>
        </Box>
        <Typography variant="body2" mb={2}>
          {t('Add this link to your OBS browser source')}
        </Typography>
        <Box display="flex">
          <Input
            sx={{ mr: 2 }}
            label={t('Keep it secret and never share with anyone')}
            helperText={t(
              'OBS settings for the browser source: width 1920px, height 1080px',
            )}
            endAdornment={
              <IconButton
                disabled={!user?.allowMedia}
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <EyeOffIcon /> : <EyeIcon />}
              </IconButton>
            }
            id="OBSurl"
            type={showPassword ? 'text' : 'password'}
            disabled
            value={url}
          />
          <CopyButton value={url} disabled={!user?.allowMedia} edge="end" />
        </Box>

        <StyledFormActions>
          <Button
            label={t('Back')}
            variant="text"
            color="secondary"
            disabled={loading}
            onClick={onCancel}
          />
        </StyledFormActions>
      </StyledFormContainer>
    </>
  );
}
