import React from 'react';
import FormHeader from 'view/components/Form/Header';
import { StyledFormActions, StyledFormContainer } from '../../styled';
import Button from 'view/components/Button';
import { EXAMPLE_DONATION, VOICES_CONFIG } from 'view/constants';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import TextArea from 'view/components/TextArea';
import Input from 'view/components/Form/Input';
import { useForm } from 'react-hook-form';
import { PaymentMethodTypes } from 'types/users';
import { Currency, CurrencyLabelMap } from 'services/api/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { ExampleDonateValidationSchema } from 'constraints';
import AudioPreview from 'view/components/AudioPreview';
import useUser from 'hooks/user';
import { primary } from 'view/theme/colors';
import useSocket from 'hooks/useSocket';

type ViewTestDonationProps = {
  onCancel: () => void;
};

type DonateFormData = {
  message: string;
  sponsorName: string;
  amount: number;
  paymentMethod: PaymentMethodTypes;
  voice: string;
  currency: Currency;
};

export default function ViewTestDonation({ onCancel }: ViewTestDonationProps) {
  const { user } = useUser();
  const { socket } = useSocket(user.cipherId);
  const { t } = useTranslation();

  const initialFormData = {
    resolver: yupResolver(ExampleDonateValidationSchema(t)),
    defaultValues: EXAMPLE_DONATION,
  };

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<DonateFormData>(initialFormData);

  const voice = watch('voice');

  const onSubmit = async (formData: DonateFormData) => {
    socket?.emit('show-test-donation', { donation: formData });
  };

  return (
    <>
      <StyledFormContainer>
        <FormHeader
          title={t('Test donation')}
          description={t(
            'Click «Show» to send a test donate, then open the OBS and move your Browser source to adjust the donate position.',
          )}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={3}>
            <TextArea
              name="message"
              label={t('Your message')}
              type="text"
              control={control}
              rows={3}
              disabled={!user.isPremium}
              error={!!errors.message?.message}
              helperText={errors.message?.message}
            />
          </Box>
          <Input
            name="sponsorName"
            label={t('Your name')}
            type="text"
            disabled={isSubmitting || !user.isPremium}
            error={!!errors.sponsorName?.message}
            helperText={errors.sponsorName?.message}
            control={control}
            mb={2}
          />
          <Input
            name="amount"
            label={t('How much do you wish to donate?')}
            error={!!errors.amount?.message}
            type="number"
            helperText={errors.amount?.message}
            disabled={isSubmitting}
            control={control}
            mb={2}
            sx={{
              paddingRight: 0,
              '.MuiInputBase-root': {
                paddingRight: 0,
              },
              '.MuiNativeSelect-select': {
                textAlign: 'right',
                width: '2.5rem',
              },
            }}
            endAdornment={
              <Input
                select
                name="currency"
                type="text"
                fullWidth
                control={control}
                SelectProps={{
                  native: true,
                }}
                sx={{
                  '.MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                    color: primary,
                  },
                }}
              >
                {Object.keys(Currency).map((option) => {
                  return (
                    <option key={option} value={option}>
                      {CurrencyLabelMap[option as Currency]}
                    </option>
                  );
                })}
              </Input>
            }
          />
          {user.isPremium && (
            <Input
              select
              name="voice"
              label={t('Voice')}
              type="text"
              disabled={!user.isPremium}
              fullWidth
              startAdornment={
                <AudioPreview
                  soundUrl={VOICES_CONFIG[voice].exampleUrl}
                  title="Voice-over example"
                />
              }
              control={control}
              SelectProps={{
                native: true,
              }}
            >
              {Object.keys(VOICES_CONFIG).map((option) => {
                return (
                  <option key={option} value={option}>
                    {t(VOICES_CONFIG[option].label)}
                    {VOICES_CONFIG[option].description
                      ? ` - ${t(VOICES_CONFIG[option].description)}`
                      : ''}
                  </option>
                );
              })}
            </Input>
          )}
          <StyledFormActions>
            <Button
              type="submit"
              withLoader
              loading={isSubmitting}
              label={t('Show')}
            />
            <Button
              label={t('Back')}
              variant="text"
              color="secondary"
              onClick={onCancel}
            />
          </StyledFormActions>
        </form>
      </StyledFormContainer>
    </>
  );
}
