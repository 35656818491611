import { Goal } from 'types/goals';
import { Currency } from 'services/api/types';

export type User = {
  _id: string;
  contentCreatorName: string;
  profilePictureUrl: string;
  description: string;
  role?: UserRole;
  createdAt: Date;
  isPremium: boolean;
  allowMedia: boolean;
  balance: { [key in Currency]: number };
  cipherId: string;
  messageCensorship: boolean;
  donationAnimationFormat: DonationAnimationFormat;
  minimumDonationAmount: MinimumDonationAmount;
  minimumVoiceOverThreshold: number;
  donationThresholdConfig: Array<ThresholdRecord>;
  paymentMethods: PaymentMethodEntry[];
  socialNetworks: SocialNetwork[];
  goals: Goal[];
};

export type MinimumDonationAmount = {
  [key in Currency]?: number;
};

export enum SocialNetworkTypes {
  INSTAGRAM = 'instagram',
  TELEGRAM = 'telegram',
  TIKTOK = 'tiktok',
  TWITCH = 'twitch',
  YOUTUBE = 'youtube',
}

export type SocialNetwork = {
  value: SocialNetworkTypes;
  url: string;
};

export type DonationAnimationFormat = {
  start: string;
  end: string;
  letters: string;
};

export type ThresholdRecord = {
  _id: string;
  threshold: number;
  soundUrl: string;
  pictureUrl: string;
};

export enum PaymentMethodTypes {
  WAYFORPAY = 'wayforpay',
  WHITEPAY = 'whitepay',
  CRYPTOMUS = 'cryptomus',
  MONOBANKA = 'monobanka',
  LIQPAY = 'liqpay',
}

export type PaymentMethodEntry =
  | {
      name: PaymentMethodTypes.WAYFORPAY;
      merchantAccount: string;
      merchantSecretKey: string;
    }
  | { name: PaymentMethodTypes.WHITEPAY; wallet: string }
  | { name: PaymentMethodTypes.CRYPTOMUS; wallet: string }
  | {
      name: PaymentMethodTypes.MONOBANKA;
      token: string;
      jarId: string;
      jarTitle: string;
      sendId: string;
    }
  | { name: PaymentMethodTypes.LIQPAY; liqPayApiKey: string };

export enum UserRole {
  USER = 'USER',
  ADMIN = 'ADMIN',
}
