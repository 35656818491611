import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'view/components/Button';
import Input from 'view/components/Form/Input';
import FormHeader from 'view/components/Form/Header';
import { User } from 'types/users';
import { StyledFormContainer, StyledFormActions } from '../../styled';
import useUser from 'hooks/user';
import { ChangeMinimumDonationVoiceOverValidationSchema } from 'constraints';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/auth';
import { updateUserData } from 'services/api/user';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Currency, CurrencyLabelMap } from 'services/api/types';

type ChangeMinimumDonationVoiceOverFormData = {
  minimumVoiceOverThreshold: number;
};

type ChangeMinimumDonationVoiceOverProps = {
  onCancel: () => void;
  onSaved: ({
    minimumVoiceOverThreshold,
  }: Pick<User, 'minimumVoiceOverThreshold'>) => void;
};

export default function ChangeMinimumDonationVoiceOver({
  onCancel,
  onSaved,
}: ChangeMinimumDonationVoiceOverProps) {
  const { setErrorMessage, setSuccessMessage } = useAuth();
  const { user } = useUser();
  const { t } = useTranslation();

  const [isVoiceOverDisabled, setIsVoiceOverDisabled] = useState<boolean>(
    user.minimumVoiceOverThreshold === -1,
  );

  const initialFormData = {
    resolver: yupResolver(ChangeMinimumDonationVoiceOverValidationSchema(t)),
    defaultValues: {
      minimumVoiceOverThreshold: user.minimumVoiceOverThreshold,
    },
  };

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<ChangeMinimumDonationVoiceOverFormData>(initialFormData);

  const handleVoiceOverChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { checked } = event.target;
    setIsVoiceOverDisabled(checked);

    if (checked) {
      return setValue('minimumVoiceOverThreshold', -1, { shouldDirty: true });
    }

    return setValue('minimumVoiceOverThreshold', 0, { shouldDirty: true });
  };

  const onSubmit = async (data: ChangeMinimumDonationVoiceOverFormData) => {
    setErrorMessage('');

    try {
      await updateUserData(user._id, data);
      onSaved(data);
      reset(data);
      setSuccessMessage(t('Successfully updated donation voice-over settings'));
    } catch (err) {
      setErrorMessage(t('Error while updating donation voice-over settings'));
    }
  };

  const submitDisabled = !isDirty || isSubmitting;

  return (
    <StyledFormContainer>
      <FormHeader
        title={t('Donation voice-over')}
        description={t(
          'Set the minimum size of the donation that will be announced. A value of 0 means that all donations will be announced',
        )}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          name="minimumVoiceOverThreshold"
          label={t('Minimum donation threshold for voice-over')}
          type="number"
          error={!!errors.minimumVoiceOverThreshold?.message}
          helperText={errors.minimumVoiceOverThreshold?.message}
          control={control}
          disabled={isVoiceOverDisabled}
          startAdornment={<>{CurrencyLabelMap[Currency.UAH]}</>}
          mb={2}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isVoiceOverDisabled}
              onChange={handleVoiceOverChange}
            />
          }
          label={
            <Typography variant="body2">
              {t('Disable donation voice-over')}
            </Typography>
          }
        />
        <StyledFormActions>
          <Button
            type="submit"
            label={t('Save')}
            loading={isSubmitting}
            disabled={submitDisabled}
            withLoader
          />
          <Button
            label={t('Back')}
            variant="text"
            color="secondary"
            onClick={onCancel}
          />
        </StyledFormActions>
      </form>
    </StyledFormContainer>
  );
}
