import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UpdateDonationThresholdValidationSchema } from 'constraints';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Dialog from 'view/components/Dialog';
import Button from 'view/components/Button';
import Input from 'view/components/Form/Input';
import useAuth from 'hooks/auth';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import IconButton from '@mui/material/IconButton';
import { updateDonationThreshold } from 'services/api/user';
import useUser from 'hooks/user';
import { Currency, CurrencyLabelMap } from 'services/api/types';
import { ThresholdRecord } from 'types/users';

export type UpdateDonationThresholdDialogFormData = {
  threshold: number;
};

type UpdateTransferredDonationDialogProps = {
  open: boolean;
  thresholdToUpdate: ThresholdRecord;
  onClose: () => void;
};

export default function UpdateDonationThresholdDialog({
  open,
  thresholdToUpdate,
  onClose,
}: UpdateTransferredDonationDialogProps) {
  const { setErrorMessage, setSuccessMessage } = useAuth();
  const { user, setUser } = useUser();
  const { t } = useTranslation();

  const initialFormData = {
    resolver: yupResolver(
      UpdateDonationThresholdValidationSchema(t, user.donationThresholdConfig),
    ),
    defaultValues: {
      threshold: thresholdToUpdate.threshold,
    },
  };

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<UpdateDonationThresholdDialogFormData>(initialFormData);
  const threshold = watch('threshold');

  const onSubmit = async (data: UpdateDonationThresholdDialogFormData) => {
    try {
      await updateDonationThreshold(thresholdToUpdate._id, {
        _id: thresholdToUpdate._id,
        ...data,
      });

      const updatedDonationThresholdConfig = user.donationThresholdConfig.map(
        (item) => {
          if (item._id === thresholdToUpdate._id) {
            return { ...item, threshold: data.threshold };
          }
          return item;
        },
      );
      setUser({
        ...user,
        donationThresholdConfig: updatedDonationThresholdConfig,
      });
      onClose();
      setSuccessMessage(t('Successfully updated threshold'));
    } catch (err) {
      setErrorMessage(t('Error while updating threshold'));
    }
  };

  const Content = (
    <form>
      <Box gap={4} mb={2}>
        <Input
          fullWidth
          name="threshold"
          label={t('Threshold')}
          type="number"
          error={!!errors.threshold}
          helperText={errors.threshold?.message}
          control={control}
          startAdornment={<>{CurrencyLabelMap[Currency.UAH]}</>}
          endAdornment={
            <IconButton
              onClick={() => setValue('threshold', threshold + 5)}
              edge="end"
            >
              <AddRoundedIcon />
            </IconButton>
          }
        />
      </Box>
    </form>
  );

  const Actions = (
    <>
      <Button
        label={t('Cancel')}
        variant="text"
        color="secondary"
        disabled={isSubmitting}
        onClick={onClose}
      />
      <Button
        label={t('Save')}
        withLoader
        loading={isSubmitting}
        disabled={!isDirty || isSubmitting}
        onClick={() => handleSubmit(onSubmit)()}
      />
    </>
  );

  return (
    <Dialog
      open={open}
      title={t('Update threshold')}
      content={Content}
      onClose={onClose}
      actions={Actions}
    />
  );
}
