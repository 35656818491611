import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import Box from '@mui/material/Box';
import { StyledContainer } from './styled';
import { User } from 'types/users';
import { PaginatedResponse } from 'services/api/types';
import Search from 'view/components/Search';
import PageHeader from 'view/components/PageHeader';
import { Order } from 'view/components/Table/components/TableHeader';
import UsersTable from './components/UsersTable';
import { deleteUser, listUsers } from 'services/api/admin/user';
import ConfirmationDialog from 'view/components/ConfirmationDialog';
import UserDetails from 'view/pages/AdminUsers/components/UserDetails';
import Drawer from 'view/components/Drawer';
import useAuth from 'hooks/auth';

function AdminUsers() {
  const { setErrorMessage } = useAuth();

  const [loading, setLoading] = useState(true);
  const [userToDelete, setUserToDelete] = useState<User | null>(null);
  const [users, setUsers] = useState({
    items: [] as Array<User>,
    meta: { totalItems: 0 },
  } as PaginatedResponse<User>);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [sorting, setSorting] = useState<[string, Order]>(['id', 'asc']);
  const [selectedUserId, setSelectedUserId] = useState('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchUsers = useCallback(
    debounce(
      async (
        searchQuery: string,
        sorting: [string, 'asc' | 'desc'],
        page: number,
      ) => {
        setLoading(true);

        try {
          const searchResult = await listUsers({
            searchQuery,
            orderBy: sorting[0],
            orderDirection: sorting[1],
            page,
            filters: {},
          });
          setUsers(searchResult);
          setLoading(false);
        } catch (err) {
          setErrorMessage('Error while loading users');
          setLoading(false);
        }
      },
      500,
    ),
    [],
  );

  useEffect(() => {
    setLoading(true);
    searchUsers(searchQuery, sorting, page);
  }, [searchUsers, searchQuery, sorting, page]);

  const handleChangePage = async (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    setSearchQuery(e.target.value);
  };

  const onClearSearch = () => setSearchQuery('');

  const onDeleteConfirmation = async (user: User) => {
    try {
      await deleteUser(user._id);
      searchUsers(searchQuery, sorting, page);
      setSelectedUserId('');
      setUserToDelete(null);
    } catch (err) {
      setErrorMessage('Error while deleting user');
    } finally {
      setLoading(false);
    }
  };

  const onEditUser = async (user: User) => {
    try {
      setUsers((prevState) => {
        const updatedItems = prevState.items.map((item) => {
          if (item._id === user._id) {
            return user;
          }
          return item;
        });
        return {
          ...prevState,
          items: updatedItems,
        };
      });
    } catch (err) {
      setErrorMessage('Error while updating user');
      setLoading(false);
    }
  };

  return (
    <>
      {userToDelete && (
        <ConfirmationDialog
          open={!!userToDelete}
          text={`Are you sure you want to delete user ${userToDelete.contentCreatorName}?`}
          confirmText="Delete"
          onConfirm={() => onDeleteConfirmation(userToDelete)}
          onClose={() => setUserToDelete(null)}
        />
      )}
      <StyledContainer component="main">
        <PageHeader
          title="Users"
          action={
            <Box sx={{ width: '20rem' }}>
              <Search
                size="small"
                name="users-search"
                value={searchQuery}
                label="Search"
                onChange={onSearchChange}
                onClear={onClearSearch}
              />
            </Box>
          }
        />
        <UsersTable
          loading={loading}
          data={users}
          page={page}
          sorting={sorting}
          setSelectedUserId={setSelectedUserId}
          onPageChange={handleChangePage}
          setSorting={setSorting}
          onClickDeleteUser={(user: User) => setUserToDelete(user)}
        />
      </StyledContainer>
      <Drawer
        variant="temporary"
        anchor="right"
        width="80vw"
        onClose={() => setSelectedUserId('')}
        open={!!selectedUserId}
        action={
          <UserDetails
            userId={selectedUserId}
            onEditUser={onEditUser}
            setUserToDelete={setUserToDelete}
          />
        }
      />
    </>
  );
}

export default AdminUsers;
